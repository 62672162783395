@import 'src/less/colors';

.funnelSummary {
  display: flex;
  align-items: center;
  padding: 24px;
  gap: 24px;
  overflow-x: auto;
  justify-content: space-between;
}

.stage {
  flex-shrink: 0;
  text-align: center;
}

.name {
  margin-bottom: 8px;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  color: @PrimaryCobalt60;
}

.count {
  font-family: Gilroy;
  font-size: 32px;
  line-height: 32px;
  font-weight: 600;
  color: @PrimaryCobalt60;

  &:not(:disabled) {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
