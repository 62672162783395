/*
 * colors.less
 */
.funnelSummary {
  display: flex;
  align-items: center;
  padding: 24px;
  gap: 24px;
  overflow-x: auto;
  justify-content: space-between;
}
.stage {
  flex-shrink: 0;
  text-align: center;
}
.name {
  margin-bottom: 8px;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  color: #7982ad;
}
.count {
  font-family: Gilroy;
  font-size: 32px;
  line-height: 32px;
  font-weight: 600;
  color: #7982ad;
}
.count:not(:disabled) {
  cursor: pointer;
}
.count:not(:disabled):hover {
  text-decoration: underline;
}
