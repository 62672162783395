/*
 * colors.less
 */
.in-progress .count {
  color: #4864c9;
}
.replied .count {
  color: #709dff;
}
.interested .count {
  color: #b26fd2;
}
.hired .count {
  color: #14b62e;
}
.archived {
  border-left: 1px solid #dae0f4;
  padding-left: 24px;
}
