:root {
  --primary-cobalt-120: #0b1a63;
  --primary-cobalt-100: #1f2e77;
  --primary-cobalt-80: #4c5892;
  --primary-cobalt-60: #7982ad;
  --primary-cobalt-40: #a5abc9;
  --primary-cobalt-20: #d2d5e4;
  --primary-cobalt-10: #e9ebf2;
  --primary-cobalt-05: #f3f4f8;

  --primary-cobalt-op-06: #1f2e770f;

  --secondary-blue-120: #3450b5;
  --secondary-blue-100: #4864c9;
  --secondary-blue-80: #6d83d4;
  --secondary-blue-60: #91a2df;
  --secondary-blue-40: #b6c1e9;
  --secondary-blue-20: #dae0f4;
  --secondary-blue-10: #edf0fa;
  --secondary-blue-05: #f6f7fc;

  --secondary-sky-blue-120: #5c89eb;
  --secondary-sky-blue-100: #709dff;
  --secondary-sky-blue-80: #8db1ff;
  --secondary-sky-blue-60: #a9c4ff;
  --secondary-sky-blue-40: #c6d8ff;
  --secondary-sky-blue-20: #e2ebff;
  --secondary-sky-blue-10: #f1f6ff;
  --secondary-sky-blue-05: #f8faff;

  --secondary-dark-120: #000329;
  --secondary-dark-100: #10173d;
  --secondary-dark-80: #404564;
  --secondary-dark-60: #70748b;
  --secondary-dark-40: #9fa2b1;
  --secondary-dark-20: #cfd1d8;
  --secondary-dark-10: #e8e8ec;
  --secondary-dark-05: #f3f3f5;

  --primary-cobalt: var(--primary-cobalt-100);
  --secondary-blue: var(--secondary-blue-100);
  --secondary-sky-blue: var(--secondary-sky-blue-100);
  --secondary-dark: var(--secondary-dark-100);

  --slash-yellow: #f2cd1f;
  --slash-teal: #1a666e;
  --slash-chalk: #f5f5f5;
  --slash-light-grey: #e8e8ec;
  --slash-grey: #c4c4c4;
  --slash-dark-grey: #757575;
  --slash-black: #212121;
  --slash-shadow: #0000001f;
  --slash-orange: #ee734a;

  --secondary-red: #f66f81;
  --secondary-red-10: #fff1f3;
  --secondary-red-40: #fcc5cc;
  --secondary-red-60: #fba8b2;
  --secondary-red-80: #f98b99;
  --secondary-red-120: #e45a6b;
}
:root {
  --hiresweet-main: Graphik, sans-serif;
  --hiresweet-accent: Gilroy, sans-serif;
  --slash-main: 'Lexend Exa', sans-serif;
}
.InfoBlock-module__items___s6CbH1 {
  display: grid;
  grid-template-columns: fit-content(200px) 1fr;
  align-items: center;
  gap: 16px;
  margin: 16px 0;
}
.InfoBlock-module__items___s6CbH1.InfoBlock-module__hiresweet___5DQ9G3 {
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  color: var(--primary-cobalt);
}
.InfoBlock-module__title___v4c-6a.InfoBlock-module__slash___99-YWT {
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  text-align: left;
  text-transform: uppercase;
  font-family: var(--slash-main);
}
.InfoBlock-module__title___v4c-6a.InfoBlock-module__hiresweet___5DQ9G3 {
  font-size: 16px;
  font-weight: 700;
  color: var(--primary-cobalt);
  font-family: Gilroy;
}
.InfoBlock-module__underline___o-3G43 {
  width: 51px;
  height: 5px;
  background: var(--slash-yellow);
  margin-top: 5px;
}
.InfoBlock-module__field___WkDuz- {
  display: grid;
  grid-column: 1 / span 2;
  grid-template-columns: subgrid;
}
.InfoBlock-module__label___V2XdYo {
  grid-column: 1;
}
.InfoBlock-module__label___V2XdYo.InfoBlock-module__slash___99-YWT {
  font-size: 14px;
  font-weight: 400;
  color: var(--slash-dark-grey);
  font-family: var(--slash-main);
}
.InfoBlock-module__label___V2XdYo.InfoBlock-module__hiresweet___5DQ9G3 {
  font-size: 12px;
  font-weight: 500;
  color: var(--primary-cobalt);
  font-family: Graphik;
}
.InfoBlock-module__value___Yh2PMh {
  grid-column: 2;
}
.InfoBlock-module__value___Yh2PMh.InfoBlock-module__slash___99-YWT {
  font-size: 14px;
  font-weight: 500;
  color: black;
  font-family: var(--slash-main);
}
.InfoBlock-module__value___Yh2PMh.InfoBlock-module__hiresweet___5DQ9G3 {
  font-size: 12px;
  font-weight: 500;
  color: var(--primary-cobalt);
  font-family: Graphik;
}
.Toaster-module__toaster___RGz10v {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  opacity: 0;
  transition:
    transform 250ms,
    opacity 250ms;
  border-radius: 8px;
  box-shadow: 0 2px 16px var(--slash-shadow);
  gap: 16px;

  &.Toaster-module__bottom_left___1TFpvT,
  &.Toaster-module__top_left___3vgoYJ {
    transform: translateX(calc(-100% - 20px));
  }

  &.Toaster-module__bottom_right___Cue-v2,
  &.Toaster-module__top_right___D8w7Ow {
    transform: translateX(calc(100% + 20px));
  }

  &.Toaster-module__slash___gUEdy1 {
    font-family: var(--slash-main);
    font-size: 12px;
  }

  &.Toaster-module__hiresweet___FmlOI5 {
    font-family: var(--hiresweet-main);
    font-size: 14px;
  }

  &.Toaster-module__active___9Pa3ir {
    transform: translateX(0);
    opacity: 1;
  }

  &.Toaster-module__success___WdMSkN {
    &.Toaster-module__slash___gUEdy1 {
      background-color: var(--slash-teal);
    }
    &.Toaster-module__hiresweet___FmlOI5 {
      background-color: var(--secondary-blue);
    }
    color: white;

    .Toaster-module__closeBtn___fHG6-v {
      color: white;
    }
  }

  &.Toaster-module__error___h0jhUi {
    background-color: var(--secondary-red);
    color: white;

    .Toaster-module__closeBtn___fHG6-v {
      color: white;
    }
  }
}

.Toaster-module__title___iB2Leh {
  margin-top: 0;
}

.Toaster-module__message___NiwdfS {
  margin: 0;
}

.Toaster-module__closeBtn___fHG6-v {
  cursor: pointer;
  background: none;
  border: none;
  font-size: 1rem;
  padding: 0;
  height: 16px;
}
.svg-module__svgIcon___WQEVUr {
  width: 1em;
  height: 1em;
  vertical-align: text-top;
}
.NotificationsContextProvider-module__notificationsContainer___u-Exab {
  display: flex;
  gap: 12px;
  position: fixed;
  z-index: 2;

  &.NotificationsContextProvider-module__bottom_left___TPpK7N {
    flex-direction: column-reverse;
    left: 20px;
    bottom: 20px;
  }

  &.NotificationsContextProvider-module__bottom_right___Kcb8rY {
    flex-direction: column-reverse;
    right: 20px;
    bottom: 20px;
  }

  &.NotificationsContextProvider-module__top_left___qbfc9s {
    flex-direction: column;
    left: 20px;
    top: 20px;
  }

  &.NotificationsContextProvider-module__top_right___uUqbqu {
    flex-direction: column;
    right: 20px;
    top: 20px;
  }
}
.Avatar-module__avatar___iHwNBf {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  vertical-align: text-top;
}
:root {
  --primary-cobalt-120: #0b1a63;
  --primary-cobalt-100: #1f2e77;
  --primary-cobalt-80: #4c5892;
  --primary-cobalt-60: #7982ad;
  --primary-cobalt-40: #a5abc9;
  --primary-cobalt-20: #d2d5e4;
  --primary-cobalt-10: #e9ebf2;
  --primary-cobalt-05: #f3f4f8;

  --primary-cobalt-op-06: #1f2e770f;

  --secondary-blue-120: #3450b5;
  --secondary-blue-100: #4864c9;
  --secondary-blue-80: #6d83d4;
  --secondary-blue-60: #91a2df;
  --secondary-blue-40: #b6c1e9;
  --secondary-blue-20: #dae0f4;
  --secondary-blue-10: #edf0fa;
  --secondary-blue-05: #f6f7fc;

  --secondary-sky-blue-120: #5c89eb;
  --secondary-sky-blue-100: #709dff;
  --secondary-sky-blue-80: #8db1ff;
  --secondary-sky-blue-60: #a9c4ff;
  --secondary-sky-blue-40: #c6d8ff;
  --secondary-sky-blue-20: #e2ebff;
  --secondary-sky-blue-10: #f1f6ff;
  --secondary-sky-blue-05: #f8faff;

  --secondary-dark-120: #000329;
  --secondary-dark-100: #10173d;
  --secondary-dark-80: #404564;
  --secondary-dark-60: #70748b;
  --secondary-dark-40: #9fa2b1;
  --secondary-dark-20: #cfd1d8;
  --secondary-dark-10: #e8e8ec;
  --secondary-dark-05: #f3f3f5;

  --primary-cobalt: var(--primary-cobalt-100);
  --secondary-blue: var(--secondary-blue-100);
  --secondary-sky-blue: var(--secondary-sky-blue-100);
  --secondary-dark: var(--secondary-dark-100);

  --slash-yellow: #f2cd1f;
  --slash-teal: #1a666e;
  --slash-chalk: #f5f5f5;
  --slash-light-grey: #e8e8ec;
  --slash-grey: #c4c4c4;
  --slash-dark-grey: #757575;
  --slash-black: #212121;
  --slash-shadow: #0000001f;
  --slash-orange: #ee734a;

  --secondary-red: #f66f81;
  --secondary-red-10: #fff1f3;
  --secondary-red-40: #fcc5cc;
  --secondary-red-60: #fba8b2;
  --secondary-red-80: #f98b99;
  --secondary-red-120: #e45a6b;
}
:root {
  --hiresweet-main: Graphik, sans-serif;
  --hiresweet-accent: Gilroy, sans-serif;
  --slash-main: 'Lexend Exa', sans-serif;
}
.Tabs-module__tabs___e-EBR5 {
  display: flex;
  align-items: center;

  &.Tabs-module__slash___S9Hfc6 {
    gap: 40px;
  }

  &.Tabs-module__hiresweet___smZgwU {
    gap: 16px;
    padding: 10px 20px;
    border-bottom: 1px solid var(--secondary-blue-20);
  }
}
.Tabs-module__tab___zLflqG {
  cursor: pointer;

  &.Tabs-module__slash___S9Hfc6 {
    color: var(--slash-dark-grey);
    font-family: var(--slash-main);
    font-size: 18px;
    font-weight: 400;
    &.Tabs-module__active___mg4Xhs {
      color: var(--slash-black);
      font-weight: 800;
    }
    &:hover {
      color: var(--slash-black);
    }
  }

  &.Tabs-module__hiresweet___smZgwU {
    padding: 6px 12px;
    color: var(--primary-cobalt-60);
    font-family: var(--hiresweet-accent);
    font-size: 16px;
    font-weight: 700;
    border-radius: 4px;
    &.Tabs-module__active___mg4Xhs {
      background-color: var(--secondary-sky-blue-20);
      color: var(--secondary-blue-100);
    }
  }
}
.Tabs-module__sliderContainer___GYIUem {
  position: relative;
  height: 24px;
  padding-top: 10px;
  z-index: 0;
}
.Tabs-module__line___smux0l {
  width: 100%;
  border: 1px solid var(--slash-light-grey);
}
.Tabs-module__thumb___47c1YF {
  position: absolute;
  top: 0;
  transition: left 250ms;
}
:root {
  --hiresweet-main: Graphik, sans-serif;
  --hiresweet-accent: Gilroy, sans-serif;
  --slash-main: 'Lexend Exa', sans-serif;
}

.ProfileHeader-module__container___SQ5hOv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ProfileHeader-module__nameAndAvatar___NkXtdf {
  display: flex;
  align-items: center;

  &.ProfileHeader-module__slash___wf-QXl {
    gap: 12px;
  }

  &.ProfileHeader-module__hiresweet___Xr7l-Z {
    gap: 16px;
  }
}

.ProfileHeader-module__avatar___Sgk3-d {
  &.ProfileHeader-module__slash___wf-QXl {
    width: 58px;
    height: 58px;
  }

  &.ProfileHeader-module__hiresweet___Xr7l-Z {
    width: 72px;
    height: 72px;
  }
}

.ProfileHeader-module__nameAndHeadline___9rgQ9B {
  &.ProfileHeader-module__slash___wf-QXl {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  &.ProfileHeader-module__hiresweet___Xr7l-Z {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .ProfileHeader-module__name___F5rse0 {
    &.ProfileHeader-module__slash___wf-QXl {
      font-family: var(--slash-main);
      font-size: 25px;
      font-weight: 700;
      letter-spacing: 0.25px;
      color: var(--slash-black);
    }

    &.ProfileHeader-module__hiresweet___Xr7l-Z {
      font-family: var(--hiresweet-accent);
      font-size: 22px;
      font-weight: 700;
      color: var(--secondary-blue);
    }
  }

  .ProfileHeader-module__headline___P-9a-O {
    &.ProfileHeader-module__slash___wf-QXl {
      font-family: var(--slash-main);
      font-size: 13px;
      font-weight: 400;
      color: var(--slash-black);
      background: var(--slash-light-grey);
      border-radius: 16px;
      padding: 7px 10px;
      letter-spacing: 0.16px;
      max-width: 350px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 18px;
    }

    &.ProfileHeader-module__hiresweet___Xr7l-Z {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 12px;
      font-family: var(--hiresweet-main);
      font-weight: 500;
      font-style: normal;
      margin-bottom: 0px;
      color: var(--primary-cobalt);
    }
  }
}

.ProfileHeader-module__additionalInfo___eeh5Ay {
  margin-top: 18px;
  display: flex;
  gap: 26px;
  align-items: center;

  .ProfileHeader-module__additionalInfoItem___VWYbkm {
    display: flex;
    gap: 8px;
    align-items: center;
    color: black;
    font-family: var(--slash-main);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    .ProfileHeader-module__copy___3Bc7dF {
      display: flex;
      align-items: center;
      gap: 6px;
      cursor: pointer;

      .ProfileHeader-module__copyText___GVFMFX {
        text-decoration: underline;
      }
    }
  }
}
.ComposableProfileActionsBar-module__container___riAudr {
  position: relative;
}

.ComposableProfileActionsBar-module__actionsContainer___7GSgGk {
  display: flex;
  align-items: center;
  gap: 10px;

  .ComposableProfileActionsBar-module__actionButton___r-jtZI {
    height: 32px;
    background: transparent;
    border-radius: 4px;
    border: 1px solid #757575;
    box-shadow: 0px 1px 6px 0px #00000024;
    color: #757575;
    font-size: 13px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    display: flex;
    gap: 4px;
    align-items: center;
    outline: none;
    cursor: pointer;
  }
}
.NoteForm-module__header___fI1Tkj {
  h4 {
    margin: 0px;
  }

  margin-bottom: 24px;
}

.NoteForm-module__carret___WFunrY {
  background: #F2CD1F;
  width: 51px;
  height: 5px;
}

.NoteForm-module__form___h1CsDd {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 12px;
  font-size: 14px;

  .NoteForm-module__selectors___25y6mp {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  textarea {
    border-radius: 8px;
    padding: 12px;
    border: 1px solid #DAE0F4;
  }
}

.NoteForm-module__save___isP0d4 {
  align-self: right;
}:root {
  --primary-cobalt-120: #0b1a63;
  --primary-cobalt-100: #1f2e77;
  --primary-cobalt-80: #4c5892;
  --primary-cobalt-60: #7982ad;
  --primary-cobalt-40: #a5abc9;
  --primary-cobalt-20: #d2d5e4;
  --primary-cobalt-10: #e9ebf2;
  --primary-cobalt-05: #f3f4f8;

  --primary-cobalt-op-06: #1f2e770f;

  --secondary-blue-120: #3450b5;
  --secondary-blue-100: #4864c9;
  --secondary-blue-80: #6d83d4;
  --secondary-blue-60: #91a2df;
  --secondary-blue-40: #b6c1e9;
  --secondary-blue-20: #dae0f4;
  --secondary-blue-10: #edf0fa;
  --secondary-blue-05: #f6f7fc;

  --secondary-sky-blue-120: #5c89eb;
  --secondary-sky-blue-100: #709dff;
  --secondary-sky-blue-80: #8db1ff;
  --secondary-sky-blue-60: #a9c4ff;
  --secondary-sky-blue-40: #c6d8ff;
  --secondary-sky-blue-20: #e2ebff;
  --secondary-sky-blue-10: #f1f6ff;
  --secondary-sky-blue-05: #f8faff;

  --secondary-dark-120: #000329;
  --secondary-dark-100: #10173d;
  --secondary-dark-80: #404564;
  --secondary-dark-60: #70748b;
  --secondary-dark-40: #9fa2b1;
  --secondary-dark-20: #cfd1d8;
  --secondary-dark-10: #e8e8ec;
  --secondary-dark-05: #f3f3f5;

  --primary-cobalt: var(--primary-cobalt-100);
  --secondary-blue: var(--secondary-blue-100);
  --secondary-sky-blue: var(--secondary-sky-blue-100);
  --secondary-dark: var(--secondary-dark-100);

  --slash-yellow: #f2cd1f;
  --slash-teal: #1a666e;
  --slash-chalk: #f5f5f5;
  --slash-light-grey: #e8e8ec;
  --slash-grey: #c4c4c4;
  --slash-dark-grey: #757575;
  --slash-black: #212121;
  --slash-shadow: #0000001f;
  --slash-orange: #ee734a;

  --secondary-red: #f66f81;
  --secondary-red-10: #fff1f3;
  --secondary-red-40: #fcc5cc;
  --secondary-red-60: #fba8b2;
  --secondary-red-80: #f98b99;
  --secondary-red-120: #e45a6b;
}
:root {
  --hiresweet-main: Graphik, sans-serif;
  --hiresweet-accent: Gilroy, sans-serif;
  --slash-main: 'Lexend Exa', sans-serif;
}
.Button-module__button___hvJpbQ {
  display: inline-flex;
  padding: 0 16px;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: nowrap;
  gap: 8px;
  align-items: center;
  justify-content: center;
  text-align: center;
  outline: none;

  border-radius: 8px;
  font-family: Gilroy;
  font-weight: 700;

  transition: color ease 150ms, background-color ease 150ms,
    border-color ease 150ms;

  &.Button-module__slash___yZ3VNb {
    border: none;
    border-radius: 4px;
    font-family: var(--slash-main);
  }

  &.Button-module__icon___pfoz5v {
    padding: 0;
  }

  &:is(button):not(:disabled),
  &:is(a):not(.Button-module__disabled___7IixBz) {
    cursor: pointer;
  }

  &.Button-module__primary___WGT--w {
    color: white;
    border: none;

    &.Button-module__slash___yZ3VNb {
      color: black;
      box-shadow: 0px 2px 6px 0px #00000024;
    }

    &:is(button):disabled,
    &:is(a).Button-module__disabled___7IixBz {
      &.Button-module__hiresweet___rK-T2q {
        background-color: var(--secondary-blue-40);
      }

      &.Button-module__slash___yZ3VNb {
        box-shadow: none;
        &:not(.Button-module__red___lumAYs, .Button-module__grey___ZlFFsd) {
          color: #00000042;
        }
      }
    }

    &:is(button):not(:disabled),
    &:is(a):not(.Button-module__disabled___7IixBz) {
      &.Button-module__blue___3kwc1F {
        background-color: var(--secondary-blue);
        &.Button-module__slash___yZ3VNb {
          background-color: var(--slash-yellow);
        }
        &:hover {
          &.Button-module__slash___yZ3VNb {
            background-color: var(--slash-yellow);
          }
          background-color: var(--secondary-blue-120);
        }
      }

      &.Button-module__red___lumAYs {
        background-color: var(--secondary-red);
        &:hover {
          background-color: var(--secondary-red-120);
        }
      }
    }
  }

  &.Button-module__secondary___Fx0LJY {
    background-color: white;
    border-width: 1px;
    border-style: solid;
    border-color: currentColor;

    &:is(button):disabled,
    &:is(a).Button-module__disabled___7IixBz {
      &.Button-module__hiresweet___rK-T2q {
        color: var(--secondary-blue-40);
      }

      &.Button-module__slash___yZ3VNb {
        box-shadow: none;
        &:not(.Button-module__red___lumAYs, .Button-module__grey___ZlFFsd) {
          color: #00000042;
        }
      }
    }

    &.Button-module__slash___yZ3VNb {
      box-shadow: none !important;
    }

    &:is(button):not(:disabled),
    &:is(a):not(.Button-module__disabled___7IixBz) {
      box-shadow: 0 2px 4px 0 var(--primary-cobalt-op-06);

      &.Button-module__slash___yZ3VNb:not(.Button-module__red___lumAYs, .Button-module__grey___ZlFFsd) {
        border: 1px solid var(--slash-yellow);
        color: var(--slash-yellow);
      }

      &.Button-module__blue___3kwc1F:not(.Button-module__slash___yZ3VNb) {
        color: var(--secondary-blue);
        &:hover {
          color: var(--secondary-blue-120);
          background-color: var(--secondary-blue-10);
        }
      }

      &.Button-module__red___lumAYs {
        color: var(--secondary-red);
        &:hover {
          color: var(--secondary-red-120);
          background-color: var(--secondary-red-10);
        }
      }

      &.Button-module__grey___ZlFFsd {
        color: var(--primary-cobalt-60);
        border-color: var(--secondary-blue-40);
        &:hover {
          color: var(--primary-cobalt-80);
          background-color: var(--secondary-blue-05);
        }

        &.Button-module__slash___yZ3VNb {
          color: var(--slash-dark-grey);
          border: 1px solid var(--slash-light-grey);

          &:hover {
            border: 1px solid var(--slash-dark-grey);
          }
        }
      }
    }
  }

  &.Button-module__tertiary___6H-eC8 {
    background-color: transparent;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    color: var(--primary-cobalt-60);

    &:is(button):disabled,
    &:is(a).Button-module__disabled___7IixBz {
      color: var(--secondary-blue-40);
    }

    &:is(button):not(:disabled),
    &:is(a):not(.Button-module__disabled___7IixBz) {
      &:hover {
        color: var(--primary-cobalt-80);
        background-color: var(--secondary-blue-05);
        border-color: var(--secondary-blue-20);
        box-shadow: 0px 2px 4px 0px var(--primary-cobalt-op-06);
        transition: all ease 150ms;
      }
    }
  }
}
.Button-module__small___J4hASN {
  height: 24px;
  font-size: 12px;
  padding: 0 8px;

  &.Button-module__icon___pfoz5v {
    width: 24px;
  }
}
.Button-module__normal___EGcvgE {
  height: 32px;
  font-size: 14px;

  &.Button-module__icon___pfoz5v {
    width: 32px;
    font-size: 16px;
  }
}
.Button-module__big___hRWEnU {
  height: 40px;
  font-size: 16px;

  &.Button-module__icon___pfoz5v {
    width: 40px;
    font-size: 20px;
  }
}
:root {
  --primary-cobalt-120: #0b1a63;
  --primary-cobalt-100: #1f2e77;
  --primary-cobalt-80: #4c5892;
  --primary-cobalt-60: #7982ad;
  --primary-cobalt-40: #a5abc9;
  --primary-cobalt-20: #d2d5e4;
  --primary-cobalt-10: #e9ebf2;
  --primary-cobalt-05: #f3f4f8;

  --primary-cobalt-op-06: #1f2e770f;

  --secondary-blue-120: #3450b5;
  --secondary-blue-100: #4864c9;
  --secondary-blue-80: #6d83d4;
  --secondary-blue-60: #91a2df;
  --secondary-blue-40: #b6c1e9;
  --secondary-blue-20: #dae0f4;
  --secondary-blue-10: #edf0fa;
  --secondary-blue-05: #f6f7fc;

  --secondary-sky-blue-120: #5c89eb;
  --secondary-sky-blue-100: #709dff;
  --secondary-sky-blue-80: #8db1ff;
  --secondary-sky-blue-60: #a9c4ff;
  --secondary-sky-blue-40: #c6d8ff;
  --secondary-sky-blue-20: #e2ebff;
  --secondary-sky-blue-10: #f1f6ff;
  --secondary-sky-blue-05: #f8faff;

  --secondary-dark-120: #000329;
  --secondary-dark-100: #10173d;
  --secondary-dark-80: #404564;
  --secondary-dark-60: #70748b;
  --secondary-dark-40: #9fa2b1;
  --secondary-dark-20: #cfd1d8;
  --secondary-dark-10: #e8e8ec;
  --secondary-dark-05: #f3f3f5;

  --primary-cobalt: var(--primary-cobalt-100);
  --secondary-blue: var(--secondary-blue-100);
  --secondary-sky-blue: var(--secondary-sky-blue-100);
  --secondary-dark: var(--secondary-dark-100);

  --slash-yellow: #f2cd1f;
  --slash-teal: #1a666e;
  --slash-chalk: #f5f5f5;
  --slash-light-grey: #e8e8ec;
  --slash-grey: #c4c4c4;
  --slash-dark-grey: #757575;
  --slash-black: #212121;
  --slash-shadow: #0000001f;
  --slash-orange: #ee734a;

  --secondary-red: #f66f81;
  --secondary-red-10: #fff1f3;
  --secondary-red-40: #fcc5cc;
  --secondary-red-60: #fba8b2;
  --secondary-red-80: #f98b99;
  --secondary-red-120: #e45a6b;
}

.Select-module__container___XNzwIh {
  display: inline-block;
  position: relative;
  width: 100%;
}

.Select-module__label___XfLKOd {
  &.Select-module__hiresweet___gRZ1m8 {
    margin-bottom: 2px;
    font-family: var(--hiresweet-main);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--primary-cobalt);
  }
  &.Select-module__slash___BMLCCe {
    position: absolute;
    z-index: 1;
    padding: 0px 4px;
    width: fit-content;
    top: -4px;
    left: 20px;
    background-color: white;
    font-family: var(--slash-main);
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.15px;
    color: var(--slash-grey);
    &.Select-module__focused___xLQoK5 {
      color: var(--slash-yellow);
    }
    &.Select-module__hovered___5nzjYY:not(.Select-module__focused___xLQoK5) {
      color: var(--slash-teal);
    }
  }
}

.Select-module__select___4n0a-3 {
  &.Select-module__hiresweet___gRZ1m8 {
    font-family: var(--hiresweet-main);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--primary-cobalt);

    > div > div {
      padding-left: 0px;
    }
  }

  &.Select-module__slash___BMLCCe {
    font-family: var(--slash-main);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--slash-grey);
  }
}
.FormInputSelect-module__select___jBUsqx {
  width: 100%;
}

.FormInputSelect-module__required___-Wop5q {
  color: red;
  font-weight: 400;
}
.InnerDatePicker-module__datepicker___yVi5do {
  width: fit-content;
  font-family: var(--slash-main);
  padding: 12px;
  /* box-shadow: 0px 2px 5px 0px #00000026; */
}

.InnerDatePicker-module__monthpicker___fP36Hi {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  align-items: center;
}

.InnerDatePicker-module__month___oVMUpD {
  text-transform: capitalize;
}

.InnerDatePicker-module__monthArrow___i3fyod {
  padding: 6px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  &:hover {
    background-color: var(--slash-light-grey);
    color: black;
  }
}

.InnerDatePicker-module__daylist___cRMI6d {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  color: var(--slash-dark-grey);
  margin-bottom: 6px;
}

.InnerDatePicker-module__dayName___CZuSRb {
  text-align: center;
  text-transform: capitalize;
}

.InnerDatePicker-module__daypicker___bKTLYT {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 4px;
}

.InnerDatePicker-module__day___Ucay4d {
  font-family: var(--slash-main);
  text-align: center;
  padding: 6px;
  border-radius: 4px;
  line-height: 1.15em;
  font-size: 14px;
  border: none;
  outline: none;
  background-color: transparent;

  &:hover:not(.InnerDatePicker-module__disabled___A-SN6V):not(.InnerDatePicker-module__selected___6UMWXB) {
    cursor: pointer;
    background-color: var(--slash-light-grey);
    color: black;
  }

  &.InnerDatePicker-module__selected___6UMWXB {
    background-color: var(--slash-yellow);
    color: white;
  }

  &.InnerDatePicker-module__disabled___A-SN6V {
    cursor: not-allowed;
    color: var(--secondary-dark-20);
  }
}
:root {
  --primary-cobalt-120: #0b1a63;
  --primary-cobalt-100: #1f2e77;
  --primary-cobalt-80: #4c5892;
  --primary-cobalt-60: #7982ad;
  --primary-cobalt-40: #a5abc9;
  --primary-cobalt-20: #d2d5e4;
  --primary-cobalt-10: #e9ebf2;
  --primary-cobalt-05: #f3f4f8;

  --primary-cobalt-op-06: #1f2e770f;

  --secondary-blue-120: #3450b5;
  --secondary-blue-100: #4864c9;
  --secondary-blue-80: #6d83d4;
  --secondary-blue-60: #91a2df;
  --secondary-blue-40: #b6c1e9;
  --secondary-blue-20: #dae0f4;
  --secondary-blue-10: #edf0fa;
  --secondary-blue-05: #f6f7fc;

  --secondary-sky-blue-120: #5c89eb;
  --secondary-sky-blue-100: #709dff;
  --secondary-sky-blue-80: #8db1ff;
  --secondary-sky-blue-60: #a9c4ff;
  --secondary-sky-blue-40: #c6d8ff;
  --secondary-sky-blue-20: #e2ebff;
  --secondary-sky-blue-10: #f1f6ff;
  --secondary-sky-blue-05: #f8faff;

  --secondary-dark-120: #000329;
  --secondary-dark-100: #10173d;
  --secondary-dark-80: #404564;
  --secondary-dark-60: #70748b;
  --secondary-dark-40: #9fa2b1;
  --secondary-dark-20: #cfd1d8;
  --secondary-dark-10: #e8e8ec;
  --secondary-dark-05: #f3f3f5;

  --primary-cobalt: var(--primary-cobalt-100);
  --secondary-blue: var(--secondary-blue-100);
  --secondary-sky-blue: var(--secondary-sky-blue-100);
  --secondary-dark: var(--secondary-dark-100);

  --slash-yellow: #f2cd1f;
  --slash-teal: #1a666e;
  --slash-chalk: #f5f5f5;
  --slash-light-grey: #e8e8ec;
  --slash-grey: #c4c4c4;
  --slash-dark-grey: #757575;
  --slash-black: #212121;
  --slash-shadow: #0000001f;
  --slash-orange: #ee734a;

  --secondary-red: #f66f81;
  --secondary-red-10: #fff1f3;
  --secondary-red-40: #fcc5cc;
  --secondary-red-60: #fba8b2;
  --secondary-red-80: #f98b99;
  --secondary-red-120: #e45a6b;
}
:root {
  --hiresweet-main: Graphik, sans-serif;
  --hiresweet-accent: Gilroy, sans-serif;
  --slash-main: 'Lexend Exa', sans-serif;
}
.Dropdown-module__dropdownContainer___lBg4VF {
  position: relative;
  display: inline-block;
}
.Dropdown-module__dropdownContent___oOy6wy {
  display: none;
  position: absolute;
  z-index: 1;

  &.Dropdown-module__left___kOVANw {
    left: 0;
  }

  &.Dropdown-module__right___-H5Ugg {
    right: 0;
  }

  &.Dropdown-module__left___kOVANw.Dropdown-module__center___xqTNyH {
    top: 50%;
    transform: translate(50%, -50%);
  }

  &.Dropdown-module__right___-H5Ugg.Dropdown-module__center___xqTNyH {
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.Dropdown-module__open___-fP-Ad {
    display: block;
  }

  &.Dropdown-module__bottom___JD6O7w {
    top: calc(100% + 8px);
  }

  &.Dropdown-module__top___3DL0YW {
    bottom: calc(100% + 8px);
  }

  &.Dropdown-module__bottom___JD6O7w.Dropdown-module__center___xqTNyH:not(.Dropdown-module__left___kOVANw):not(.Dropdown-module__right___-H5Ugg),
  &.Dropdown-module__top___3DL0YW.Dropdown-module__center___xqTNyH:not(.Dropdown-module__left___kOVANw):not(.Dropdown-module__right___-H5Ugg) {
    left: 50%;
    transform: translateX(-50%);
  }
}
:root {
  --primary-cobalt-120: #0b1a63;
  --primary-cobalt-100: #1f2e77;
  --primary-cobalt-80: #4c5892;
  --primary-cobalt-60: #7982ad;
  --primary-cobalt-40: #a5abc9;
  --primary-cobalt-20: #d2d5e4;
  --primary-cobalt-10: #e9ebf2;
  --primary-cobalt-05: #f3f4f8;

  --primary-cobalt-op-06: #1f2e770f;

  --secondary-blue-120: #3450b5;
  --secondary-blue-100: #4864c9;
  --secondary-blue-80: #6d83d4;
  --secondary-blue-60: #91a2df;
  --secondary-blue-40: #b6c1e9;
  --secondary-blue-20: #dae0f4;
  --secondary-blue-10: #edf0fa;
  --secondary-blue-05: #f6f7fc;

  --secondary-sky-blue-120: #5c89eb;
  --secondary-sky-blue-100: #709dff;
  --secondary-sky-blue-80: #8db1ff;
  --secondary-sky-blue-60: #a9c4ff;
  --secondary-sky-blue-40: #c6d8ff;
  --secondary-sky-blue-20: #e2ebff;
  --secondary-sky-blue-10: #f1f6ff;
  --secondary-sky-blue-05: #f8faff;

  --secondary-dark-120: #000329;
  --secondary-dark-100: #10173d;
  --secondary-dark-80: #404564;
  --secondary-dark-60: #70748b;
  --secondary-dark-40: #9fa2b1;
  --secondary-dark-20: #cfd1d8;
  --secondary-dark-10: #e8e8ec;
  --secondary-dark-05: #f3f3f5;

  --primary-cobalt: var(--primary-cobalt-100);
  --secondary-blue: var(--secondary-blue-100);
  --secondary-sky-blue: var(--secondary-sky-blue-100);
  --secondary-dark: var(--secondary-dark-100);

  --slash-yellow: #f2cd1f;
  --slash-teal: #1a666e;
  --slash-chalk: #f5f5f5;
  --slash-light-grey: #e8e8ec;
  --slash-grey: #c4c4c4;
  --slash-dark-grey: #757575;
  --slash-black: #212121;
  --slash-shadow: #0000001f;
  --slash-orange: #ee734a;

  --secondary-red: #f66f81;
  --secondary-red-10: #fff1f3;
  --secondary-red-40: #fcc5cc;
  --secondary-red-60: #fba8b2;
  --secondary-red-80: #f98b99;
  --secondary-red-120: #e45a6b;
}

.DropdownPanel-module__panel___xV79gL {
  width: 272px;
  background-color: white;
  &.DropdownPanel-module__hiresweet___e-qhtV {
    border: 2px solid var(--secondary-sky-blue-40);
    border-radius: 8px;
    box-shadow: 0px 2px 16px 0px var(--primary-cobalt-op-15);
  }
  &.DropdownPanel-module__slash___ZXx28q {
    border-radius: 4px;
    box-shadow: 0px 2px 16px 0px var(--slash-shadow);
  }
}
.DatePicker-module__dropdownContainer___pWk1fC {
  width: 100%;
}

.DatePicker-module__container___4-tRzb {
  position: relative;
  background-color: white;
  display: flex;
  align-items: center;
  gap: 16px;

  &.DatePicker-module__hiresweet___6ru00S {
    height: 40px;
    padding: 0 12px;
    border: 1px solid var(--secondary-blue-20);
    background-color: white;
    border-radius: 8px;
    &.DatePicker-module__active___kSXo17 {
      border-color: var(--secondary-sky-blue-60);
      box-shadow: inset 0px 0px 1px 1px var(--secondary-sky-blue-60);
    }
  }

  &.DatePicker-module__slash___p39M4w {
    height: 56px;
    border-radius: 30px;
    padding: 0px 20px;
    border: 1px solid var(--slash-grey);

    &:hover:not(.DatePicker-module__active___kSXo17) {
      border-color: var(--slash-teal);
      box-shadow: inset 0 0 1px 1px var(--slash-teal);
      .DatePicker-module__inputLabel___4UKny1 {
        color: var(--slash-teal);
      }
    }

    &.DatePicker-module__active___kSXo17 {
      border-color: var(--slash-yellow);
      box-shadow: inset 0 0 1px 1px var(--slash-yellow);
      .DatePicker-module__inputLabel___4UKny1 {
        color: var(--slash-yellow);
      }
    }

    &.DatePicker-module__empty___SuMSxp {
      .DatePicker-module__inputLabel___4UKny1 {
        top: 50%;
        left: 12px;
        font-size: 14px;
        font-weight: 400;
        transform: translateY(-50%);
        line-height: 16px;
        pointer-events: none;
        color: var(--slash-grey);
        box-shadow: none;
      }
    }
  }
}

.DatePicker-module__input___VgIsL- {
  width: 100%;
  display: flex;
  align-items: center;
}

.DatePicker-module__left___96-XK3 {
  flex: 1;
  font-weight: 400;

  &.DatePicker-module__hiresweet___6ru00S {
    font-size: 14px;
    line-height: 20px;
    font-family: var(--hiresweet-main);
    color: var(--primary-cobalt);
  }

  &.DatePicker-module__slash___p39M4w {
    font-family: var(--slash-main);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
}

.DatePicker-module__right___5tC7xP {
  flex: 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

.DatePicker-module__inputLabel___4UKny1 {
  background-color: white;
  width: fit-content;
  padding: 0px 4px;
  text-align: center;
  position: absolute;
  top: -8px;
  left: 20px;
  font-family: var(--slash-main);
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.15px;
  color: #00000099;
  transition: font-size 250ms, top 250ms, left 250ms, box-shadow 250ms;

  span {
    color: red;
    font-weight: 400;
  }
}

.DatePicker-module__button___FWC-HW {
  cursor: pointer;
  line-height: 1em;
  height: 1em;
  padding: 0;
  margin: 0;
  background: none;
  border: none;

  &.DatePicker-module__hiresweet___6ru00S {
    font-size: 16px;
    color: var(--primary-cobalt-40);
    &:hover {
      color: var(--primary-cobalt-60);
    }
  }

  &.DatePicker-module__slash___p39M4w {
    color: var(--slash-dark-grey);
    font-size: 24px;
    &:hover {
      color: var(--slash-teal);
    }
  }
}

.DatePicker-module__icon___BuZsec {
  &.DatePicker-module__slash___p39M4w {
    font-size: 24px;
    color: var(--slash-dark-grey);
  }
  &.DatePicker-module__hiresweet___6ru00S {
    font-size: 16px;
    color: var(--primary-cobalt-60);
  }
}
.TaskForm-module__footer___0JaWHH {
  margin-top: 24px;
}

.TaskForm-module__dualField___4h0-Lo {
  display: flex;
  justify-content: space-between;
  gap: 26px;
  margin-bottom: 24px;

  > div {
    flex: 1 1;
  }
}

.TaskForm-module__textarea___jk0pvm {
  width: 100%;
  padding: 12px;
  resize: vertical;
  border: 1px solid #0000003b;
  border-radius: 8px;
}
.Pill-module__container___Hm8V5V {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 3px;
  border-radius: 50vmax;
  font-family: var(--slash-main);
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.15px;
  text-align: left;

  &.Pill-module__blue___aIlvWK {
    background: #0288d11f;
    border: 1px solid #0288d180;
    color: #0288d1;

    &.Pill-module__full___U0nYYi {
      background: #2b7d86;
      color: white;
      font-family: Lexend Exa;
      font-size: 13px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0.16px;
      text-align: left;
      border: 1px solid #2b7d86;
    }
  }

  &.Pill-module__grey___opObC5 {
    background: #00000014;
    color: #000000de;
    border: 1px solid #00000000;
  }

  &.Pill-module__green___RCw66j {
    background: #2e7d321f;
    border: 1px solid #2e7d3280;
    color: #169222;
  }

  &.Pill-module__red___zqDeVi {
    background: #d32f2f1f;
    border: 1px solid #d32f2f80;
    color: #d32f2f;
  }
}
.Modal-module__backdrop___0Bd6as {
  width: 100vw;
  height: 100svh;
  animation: Modal-module__fade-in___NfUjSB 200ms ease;
  opacity: 0.7;
  position: fixed;
  inset: 0;
  z-index: 1;
  top: 0;
  left: 0;

  &.Modal-module__slash___wFi3pr {
    background: var(--slash-black);
  }
  &.Modal-module__hiresweet___F-Cpig {
    background: var(--primary-cobalt);
  }
}

.Modal-module__modal___MUsZAv {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  z-index: 2;
  background: white;
  border-radius: 8px;
  max-height: 90svh;

  &.Modal-module__hiresweet___F-Cpig {
    border: 1px solid var(--secondary-blue-20);
  }

  &.Modal-module__slash___wFi3pr {
    border: 1px solid var(--slash-grey);
    gap: 20px;
  }

  &.Modal-module__mini___sJbfij {
    width: 300px;
  }

  &.Modal-module__small___tMzhZk {
    width: 500px;
  }

  &.Modal-module__medium___ww1Z7h {
    width: 700px;
  }

  &.Modal-module__large___J-zZno {
    width: 900px;
  }

  &.Modal-module__full___ERGkqE {
    width: 90%;
  }

  &:not(.Modal-module__mini___sJbfij):not(.Modal-module__small___tMzhZk):not(.Modal-module__medium___ww1Z7h):not(.Modal-module__large___J-zZno):not(.Modal-module__full___ERGkqE) {
    width: fit-content;
  }

  .Modal-module__header___1k5W20 {
    position: relative;

    &.Modal-module__hiresweet___F-Cpig {
      display: block;
      font-family: var(--hiresweet-accent);
      background: #f3f4f8;
      margin: 0em;
      padding: 1.25rem 1.5rem;
      box-shadow: none;
      color: var(--primary-cobalt);
      border-bottom: 1px solid var(--secondary-blue-20);
      text-align: center;
      font-size: 22px;
      line-height: 1.28571429em;
      font-weight: 700;
    }

    &.Modal-module__slash___wFi3pr {
      color: var(--slash-black);
      font-family: var(--slash-main);
      font-size: 16px;
      font-weight: 800;
      line-height: 24px;
      letter-spacing: 0.15px;
      text-align: left;
      text-transform: uppercase;
      padding: 24px 36px 0px;
    }

    .Modal-module__underline___oER-4a {
      width: 51px;
      height: 5px;
      background: var(--slash-yellow);
      margin-top: 5px;
    }

    .Modal-module__close___s246O- {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);

      .Modal-module__icon___8hMbDA {
        width: 28px;
        height: 28px;
        color: var(--slash-dark-grey);
        cursor: pointer;
      }
    }
  }

  .Modal-module__content___1bCpvf {
    font-size: 14px;
    font-weight: 400;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 0px 36px;

    &.Modal-module__hiresweet___F-Cpig {
      padding: 24px;
      color: var(--primary-cobalt);
      font-family: var(--hiresweet-main);
    }

    &.Modal-module__slash___wFi3pr {
      color: var(--slash-black);
      font-family: var(--slash-main);
    }
  }

  .Modal-module__footer___5BxTlQ {
    display: flex;
    justify-content: space-between;

    &.Modal-module__hiresweet___F-Cpig {
      padding: 10px 24px;
      color: var(--primary-cobalt);
      font-family: var(--hiresweet-main);
      border-top: 1px solid var(--secondary-blue-20);
    }

    &.Modal-module__slash___wFi3pr {
      color: var(--slash-black);
      font-family: var(--slash-main);
      padding: 0px 36px 24px;
    }
  }
}

.Modal-module__content___1bCpvf {
  font-size: 14px;
  font-weight: 400;
  overflow-y: auto;
  box-sizing: border-box;

  &.Modal-module__hiresweet___F-Cpig {
    padding: 24px;
    color: var(--primary-cobalt);
    font-family: var(--hiresweet-main);
  }

  &.Modal-module__slash___wFi3pr {
    color: var(--slash-black);
    font-family: var(--slash-main);
  }
}
:root {
  --primary-cobalt-120: #0b1a63;
  --primary-cobalt-100: #1f2e77;
  --primary-cobalt-80: #4c5892;
  --primary-cobalt-60: #7982ad;
  --primary-cobalt-40: #a5abc9;
  --primary-cobalt-20: #d2d5e4;
  --primary-cobalt-10: #e9ebf2;
  --primary-cobalt-05: #f3f4f8;

  --primary-cobalt-op-06: #1f2e770f;

  --secondary-blue-120: #3450b5;
  --secondary-blue-100: #4864c9;
  --secondary-blue-80: #6d83d4;
  --secondary-blue-60: #91a2df;
  --secondary-blue-40: #b6c1e9;
  --secondary-blue-20: #dae0f4;
  --secondary-blue-10: #edf0fa;
  --secondary-blue-05: #f6f7fc;

  --secondary-sky-blue-120: #5c89eb;
  --secondary-sky-blue-100: #709dff;
  --secondary-sky-blue-80: #8db1ff;
  --secondary-sky-blue-60: #a9c4ff;
  --secondary-sky-blue-40: #c6d8ff;
  --secondary-sky-blue-20: #e2ebff;
  --secondary-sky-blue-10: #f1f6ff;
  --secondary-sky-blue-05: #f8faff;

  --secondary-dark-120: #000329;
  --secondary-dark-100: #10173d;
  --secondary-dark-80: #404564;
  --secondary-dark-60: #70748b;
  --secondary-dark-40: #9fa2b1;
  --secondary-dark-20: #cfd1d8;
  --secondary-dark-10: #e8e8ec;
  --secondary-dark-05: #f3f3f5;

  --primary-cobalt: var(--primary-cobalt-100);
  --secondary-blue: var(--secondary-blue-100);
  --secondary-sky-blue: var(--secondary-sky-blue-100);
  --secondary-dark: var(--secondary-dark-100);

  --slash-yellow: #f2cd1f;
  --slash-teal: #1a666e;
  --slash-chalk: #f5f5f5;
  --slash-light-grey: #e8e8ec;
  --slash-grey: #c4c4c4;
  --slash-dark-grey: #757575;
  --slash-black: #212121;
  --slash-shadow: #0000001f;
  --slash-orange: #ee734a;

  --secondary-red: #f66f81;
  --secondary-red-10: #fff1f3;
  --secondary-red-40: #fcc5cc;
  --secondary-red-60: #fba8b2;
  --secondary-red-80: #f98b99;
  --secondary-red-120: #e45a6b;
}
:root {
  --hiresweet-main: Graphik, sans-serif;
  --hiresweet-accent: Gilroy, sans-serif;
  --slash-main: 'Lexend Exa', sans-serif;
}
.FormInputText-module__textInput___KK02ZB {
  width: 100%;
  padding: 0px;
  border: none;
  outline: none;
  border-radius: 0.25rem;
  font-size: 1rem;

  &.FormInputText-module__slash___2B9jIC {
    font-family: var(--slash-main);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-align: left;
    color: black;
    padding: 15px 0px;
    background: transparent;

    &.FormInputText-module__disabled___ML3oxg {
      color: var(--slash-dark-grey);
      cursor: not-allowed;
      pointer-events: none;
      user-select: none;
    }
  }

  &.FormInputText-module__hiresweet___R5hTsx {
    color: var(--primary-cobalt);
  }
}
.FormInputText-module__iconEnd___DhjmGE {
  font-size: 24px;
  height: 24px;
}
.FormInputText-module__iconStart___g9gkpp {
  font-size: 24px;
  height: 24px;
}
.FormInputText-module__clear___Nan4Pc {
  color: var(--slash-dark-grey);
  cursor: pointer;
  font-size: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  background: none;
  border: none;

  &:hover {
    color: var(--slash-teal);
  }
}
:root {
  --primary-cobalt-120: #0b1a63;
  --primary-cobalt-100: #1f2e77;
  --primary-cobalt-80: #4c5892;
  --primary-cobalt-60: #7982ad;
  --primary-cobalt-40: #a5abc9;
  --primary-cobalt-20: #d2d5e4;
  --primary-cobalt-10: #e9ebf2;
  --primary-cobalt-05: #f3f4f8;

  --primary-cobalt-op-06: #1f2e770f;

  --secondary-blue-120: #3450b5;
  --secondary-blue-100: #4864c9;
  --secondary-blue-80: #6d83d4;
  --secondary-blue-60: #91a2df;
  --secondary-blue-40: #b6c1e9;
  --secondary-blue-20: #dae0f4;
  --secondary-blue-10: #edf0fa;
  --secondary-blue-05: #f6f7fc;

  --secondary-sky-blue-120: #5c89eb;
  --secondary-sky-blue-100: #709dff;
  --secondary-sky-blue-80: #8db1ff;
  --secondary-sky-blue-60: #a9c4ff;
  --secondary-sky-blue-40: #c6d8ff;
  --secondary-sky-blue-20: #e2ebff;
  --secondary-sky-blue-10: #f1f6ff;
  --secondary-sky-blue-05: #f8faff;

  --secondary-dark-120: #000329;
  --secondary-dark-100: #10173d;
  --secondary-dark-80: #404564;
  --secondary-dark-60: #70748b;
  --secondary-dark-40: #9fa2b1;
  --secondary-dark-20: #cfd1d8;
  --secondary-dark-10: #e8e8ec;
  --secondary-dark-05: #f3f3f5;

  --primary-cobalt: var(--primary-cobalt-100);
  --secondary-blue: var(--secondary-blue-100);
  --secondary-sky-blue: var(--secondary-sky-blue-100);
  --secondary-dark: var(--secondary-dark-100);

  --slash-yellow: #f2cd1f;
  --slash-teal: #1a666e;
  --slash-chalk: #f5f5f5;
  --slash-light-grey: #e8e8ec;
  --slash-grey: #c4c4c4;
  --slash-dark-grey: #757575;
  --slash-black: #212121;
  --slash-shadow: #0000001f;
  --slash-orange: #ee734a;

  --secondary-red: #f66f81;
  --secondary-red-10: #fff1f3;
  --secondary-red-40: #fcc5cc;
  --secondary-red-60: #fba8b2;
  --secondary-red-80: #f98b99;
  --secondary-red-120: #e45a6b;
}
:root {
  --hiresweet-main: Graphik, sans-serif;
  --hiresweet-accent: Gilroy, sans-serif;
  --slash-main: 'Lexend Exa', sans-serif;
}
.FormInputContainer-module__inputAndLabelContainer___mz7e1D {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  position: relative;
  margin-bottom: 24px;
}
.FormInputContainer-module__inputContainer___P3XMDt {
  position: relative;
  background-color: white;
  display: flex;
  align-items: center;

  &.FormInputContainer-module__hiresweet___-SV8dT {
    border-radius: 8px;
  }

  .FormInputContainer-module__input___SkDaVL {
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding: 0 8px;
    gap: 12px;
  }

  &.FormInputContainer-module__slash___e9dV7S {
    height: 56px;
    border-radius: 30px;
    padding: 0px 12px;
    border: 1px solid #0000003b;
    display: flex;
    align-items: center;

    &:has(.empty):not(
        :has(input:active, input:focus, input:focus-visible, input:focus-within)
      ) {
      .FormInputContainer-module__inputLabel___JWEK-r {
        top: 50%;
        left: 12px;
        font-size: 14px;
        font-weight: 400;
        transform: translateY(-50%);
        line-height: 16px;
        pointer-events: none;
        color: var(--slash-grey);
        box-shadow: none;
      }
    }

    &:has(input:active, input:focus, input:focus-visible, input:focus-within) {
      border: 2px solid var(--slash-yellow);

      .FormInputContainer-module__inputLabel___JWEK-r {
        color: var(--slash-yellow);
      }
    }

    &:has(input:disabled) {
      background-color: var(--slash-light-grey);
      .FormInputContainer-module__inputLabel___JWEK-r {
        padding: 2px 4px;
        top: -10px;
        border-radius: 6px;
        box-shadow: 0px 1px 6px -4px black;
      }
    }
  }

  .FormInputContainer-module__inputLabel___JWEK-r {
    background-color: white;
    width: fit-content;
    padding: 0px 4px;
    text-align: center;
    position: absolute;
    top: -8px;
    left: 20px;
    font-family: var(--slash-main);
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.15px;
    color: #00000099;
    transition: font-size 250ms, top 250ms, left 250ms, box-shadow 250ms;

    span {
      color: red;
      font-weight: 400;
    }
  }

  .FormInputContainer-module__innerLabelRight___7fLOJO {
    font-family: var(--slash-main);
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0.15px;
    color: var(--slash-black);
    border-left: 1px solid #0000003b;
    padding: 8px 12px;
  }
}
.FormLabel-module__label___cGsmcH {
  margin-bottom: 2px;
  font-family: var(--hiresweet-main);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--primary-cobalt);

  &.FormLabel-module__secondary___qpDLoi {
    color: var(--primary-cobalt-60);
    font-size: 12px;
  }

  &.FormLabel-module__slash___hUczk0 {
    font-family: var(--slash-main);
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.15px;
    text-align: left;
    color: #00000099;

  }
}
:root {
  --primary-cobalt-120: #0b1a63;
  --primary-cobalt-100: #1f2e77;
  --primary-cobalt-80: #4c5892;
  --primary-cobalt-60: #7982ad;
  --primary-cobalt-40: #a5abc9;
  --primary-cobalt-20: #d2d5e4;
  --primary-cobalt-10: #e9ebf2;
  --primary-cobalt-05: #f3f4f8;

  --primary-cobalt-op-06: #1f2e770f;

  --secondary-blue-120: #3450b5;
  --secondary-blue-100: #4864c9;
  --secondary-blue-80: #6d83d4;
  --secondary-blue-60: #91a2df;
  --secondary-blue-40: #b6c1e9;
  --secondary-blue-20: #dae0f4;
  --secondary-blue-10: #edf0fa;
  --secondary-blue-05: #f6f7fc;

  --secondary-sky-blue-120: #5c89eb;
  --secondary-sky-blue-100: #709dff;
  --secondary-sky-blue-80: #8db1ff;
  --secondary-sky-blue-60: #a9c4ff;
  --secondary-sky-blue-40: #c6d8ff;
  --secondary-sky-blue-20: #e2ebff;
  --secondary-sky-blue-10: #f1f6ff;
  --secondary-sky-blue-05: #f8faff;

  --secondary-dark-120: #000329;
  --secondary-dark-100: #10173d;
  --secondary-dark-80: #404564;
  --secondary-dark-60: #70748b;
  --secondary-dark-40: #9fa2b1;
  --secondary-dark-20: #cfd1d8;
  --secondary-dark-10: #e8e8ec;
  --secondary-dark-05: #f3f3f5;

  --primary-cobalt: var(--primary-cobalt-100);
  --secondary-blue: var(--secondary-blue-100);
  --secondary-sky-blue: var(--secondary-sky-blue-100);
  --secondary-dark: var(--secondary-dark-100);

  --slash-yellow: #f2cd1f;
  --slash-teal: #1a666e;
  --slash-chalk: #f5f5f5;
  --slash-light-grey: #e8e8ec;
  --slash-grey: #c4c4c4;
  --slash-dark-grey: #757575;
  --slash-black: #212121;
  --slash-shadow: #0000001f;
  --slash-orange: #ee734a;

  --secondary-red: #f66f81;
  --secondary-red-10: #fff1f3;
  --secondary-red-40: #fcc5cc;
  --secondary-red-60: #fba8b2;
  --secondary-red-80: #f98b99;
  --secondary-red-120: #e45a6b;
}
:root {
  --hiresweet-main: Graphik, sans-serif;
  --hiresweet-accent: Gilroy, sans-serif;
  --slash-main: 'Lexend Exa', sans-serif;
}
.TextInput-module__container___JGu7bW {
  display: inline-flex;
  align-items: center;
  gap: 8px;

  &.TextInput-module__hiresweet___FBJeDE {
    height: 40px;
    padding: 0 12px;
    border: 1px solid var(--secondary-blue-20);
    background-color: white;
    border-radius: 8px;
  }

  &.TextInput-module__slash___et1I5E {
    padding: 0 19px;
    height: 52px;
    border: 1px solid #ebebeb;
    background-color: white;
    border-radius: 4px;
  }

  &:not(.TextInput-module__disabled___AE-jYs) {
    box-shadow: 0px 2px 4px 0px var(--primary-cobalt-op-06);
  }

  &.TextInput-module__disabled___AE-jYs {
    background-color: var(--secondary-blue-05);
  }

  &.TextInput-module__focused___HsCBS- {
    border-color: var(--secondary-sky-blue-60);
    box-shadow: inset 0px 0px 1px 1px var(--secondary-sky-blue-60);
  }

  &.TextInput-module__small___pUyAcT {
    height: 32px;

    .TextInput-module__input___hdJLMd {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &.TextInput-module__fluid___-ThOMV {
    width: 100%;
    box-sizing: border-box;
  }

  .TextInput-module__icon___-8kIEW {
    font-size: 16px;
    color: var(--primary-cobalt-40);
  }

  .TextInput-module__input___hdJLMd {
    flex: 1;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    outline: none;
    border: none;

    &.TextInput-module__hiresweet___FBJeDE {
      font-family: Graphik;
      color: var(--primary-cobalt);
      max-width: 100%;
      overflow: hidden;
    }

    &.TextInput-module__slash___et1I5E {
      font-family: var(--slash-main);
      color: #000000de;
    }

    ::placeholder {
      color: var(--primary-cobalt-40);
    }

    &:disabled {
      color: var(--primary-cobalt-40);

      ::placeholder {
        color: var(--primary-cobalt-20);
      }
    }
  }
}
.ProfileEditButton-module__button___ehPBEx {
  height: 32px;
  background: transparent;
  border-radius: 4px;
  border: 1px solid #757575;
  box-shadow: 0px 1px 6px 0px #00000024;
  color: #757575;
  font-size: 13px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  display: flex;
  gap: 4px;
  align-items: center;
  outline: none;
  text-transform: uppercase;
}

.ProfileEditButton-module__form___d33WTl {
  min-width: 400px;
  padding: 8px 0;
}
:root {
  --hiresweet-main: Graphik, sans-serif;
  --hiresweet-accent: Gilroy, sans-serif;
  --slash-main: 'Lexend Exa', sans-serif;
}

.HiresweetSlashProfileHeader-module__topPart___R1c12k {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.HiresweetSlashProfileHeader-module__nameAndAvatar___u60CA- {
  display: flex;
  align-items: center;

  &.HiresweetSlashProfileHeader-module__slash___duwpNL {
    gap: 12px;
  }

  &.HiresweetSlashProfileHeader-module__hiresweet___L1GNE5 {
    gap: 16px;
  }
}

.HiresweetSlashProfileHeader-module__avatar___o42qVo {
  &.HiresweetSlashProfileHeader-module__slash___duwpNL {
    width: 58px;
    height: 58px;
  }

  &.HiresweetSlashProfileHeader-module__hiresweet___L1GNE5 {
    width: 72px;
    height: 72px;
  }
}

.HiresweetSlashProfileHeader-module__doNotContactLabel___738Spl {
  padding: 2px 8px;
}

.HiresweetSlashProfileHeader-module__nameAndHeadline___41xBUi {
  &.HiresweetSlashProfileHeader-module__slash___duwpNL {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  &.HiresweetSlashProfileHeader-module__hiresweet___L1GNE5 {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .HiresweetSlashProfileHeader-module__pills___NCA76- {
    display: flex;
    gap: 8px;
  }

  .HiresweetSlashProfileHeader-module__name___XgXS8U {
    &.HiresweetSlashProfileHeader-module__slash___duwpNL {
      font-family: var(--slash-main);
      font-size: 25px;
      font-weight: 700;
      letter-spacing: 0.25px;
      color: var(--slash-black);
    }

    &.HiresweetSlashProfileHeader-module__hiresweet___L1GNE5 {
      font-family: var(--hiresweet-accent);
      font-size: 22px;
      font-weight: 700;
      color: var(--secondary-blue);
    }
  }

  .HiresweetSlashProfileHeader-module__headline___U4Yiiu {
    &.HiresweetSlashProfileHeader-module__slash___duwpNL {
      padding: 7px 10px;
      letter-spacing: 0.16px;
      max-width: 350px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 18px;
    }

    &.HiresweetSlashProfileHeader-module__hiresweet___L1GNE5 {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 12px;
      font-family: var(--hiresweet-main);
      font-weight: 500;
      font-style: normal;
      margin-bottom: 0px;
      color: var(--primary-cobalt);
    }
  }
}

.HiresweetSlashProfileHeader-module__additionalInfo___VlCQmU {
  margin-top: 18px;
  display: flex;
  gap: 26px;
  align-items: center;

  .HiresweetSlashProfileHeader-module__additionalInfoItem___uq9Akg {
    display: flex;
    gap: 8px;
    align-items: center;
    color: black;
    font-family: var(--slash-main);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    &.HiresweetSlashProfileHeader-module__agencies___qonyrw {
      margin-left: auto;
    }
  }
}

.HiresweetSlashProfileHeader-module__copy___cmHWa1 {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;

  .HiresweetSlashProfileHeader-module__copyText___hJn3Ux {
    text-decoration: underline;
  }
}
.icon-module__icon___oLscTA {
  height: 100%;
  width: 100%;
}
.ProfileSequencePreview-module__state___ZwZVk6 {
  position: absolute;
  right: -2px;
  top: -2px;
  width: 8px;
  height: 8px;
  z-index: 1;
  color: #fff;
  width: 14px;
  height: 14px;
  padding: 2px;
  border-radius: 50%;
  border: 1px solid #fff;

  &.ProfileSequencePreview-module__complete___MGfVOu {
    background-color: #14b62e;
  }

  &.ProfileSequencePreview-module__due___1Q96TH {
    background-color: #ffbd2e;
  }

  &.ProfileSequencePreview-module__future___DRHcUN {
    border: none;
    padding: 0;
    color: transparent;
  }
}
:root {
  --primary-cobalt-120: #0b1a63;
  --primary-cobalt-100: #1f2e77;
  --primary-cobalt-80: #4c5892;
  --primary-cobalt-60: #7982ad;
  --primary-cobalt-40: #a5abc9;
  --primary-cobalt-20: #d2d5e4;
  --primary-cobalt-10: #e9ebf2;
  --primary-cobalt-05: #f3f4f8;

  --primary-cobalt-op-06: #1f2e770f;

  --secondary-blue-120: #3450b5;
  --secondary-blue-100: #4864c9;
  --secondary-blue-80: #6d83d4;
  --secondary-blue-60: #91a2df;
  --secondary-blue-40: #b6c1e9;
  --secondary-blue-20: #dae0f4;
  --secondary-blue-10: #edf0fa;
  --secondary-blue-05: #f6f7fc;

  --secondary-sky-blue-120: #5c89eb;
  --secondary-sky-blue-100: #709dff;
  --secondary-sky-blue-80: #8db1ff;
  --secondary-sky-blue-60: #a9c4ff;
  --secondary-sky-blue-40: #c6d8ff;
  --secondary-sky-blue-20: #e2ebff;
  --secondary-sky-blue-10: #f1f6ff;
  --secondary-sky-blue-05: #f8faff;

  --secondary-dark-120: #000329;
  --secondary-dark-100: #10173d;
  --secondary-dark-80: #404564;
  --secondary-dark-60: #70748b;
  --secondary-dark-40: #9fa2b1;
  --secondary-dark-20: #cfd1d8;
  --secondary-dark-10: #e8e8ec;
  --secondary-dark-05: #f3f3f5;

  --primary-cobalt: var(--primary-cobalt-100);
  --secondary-blue: var(--secondary-blue-100);
  --secondary-sky-blue: var(--secondary-sky-blue-100);
  --secondary-dark: var(--secondary-dark-100);

  --slash-yellow: #f2cd1f;
  --slash-teal: #1a666e;
  --slash-chalk: #f5f5f5;
  --slash-light-grey: #e8e8ec;
  --slash-grey: #c4c4c4;
  --slash-dark-grey: #757575;
  --slash-black: #212121;
  --slash-shadow: #0000001f;
  --slash-orange: #ee734a;

  --secondary-red: #f66f81;
  --secondary-red-10: #fff1f3;
  --secondary-red-40: #fcc5cc;
  --secondary-red-60: #fba8b2;
  --secondary-red-80: #f98b99;
  --secondary-red-120: #e45a6b;
}
:root {
  --hiresweet-main: Graphik, sans-serif;
  --hiresweet-accent: Gilroy, sans-serif;
  --slash-main: 'Lexend Exa', sans-serif;
}
.DropdownMenuItem-module__item___ixJwus {
  display: block;
  width: 100%;
  padding: 6px 12px;
  line-height: 140%;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  text-align: left;
  font-weight: 400;
  border: none;
  outline: none;
  background: none;
  &:not(:disabled) {
    cursor: pointer;
  }
  &:first-of-type {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }
  &:last-of-type {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }

  &.DropdownMenuItem-module__hiresweet___0HYghn {
    font-family: var(--hiresweet-main);
    color: var(--primary-cobalt);
    &:not(:disabled):hover {
      &:hover {
        background-color: var(--secondary-blue-05);
      }
    }
    &:disabled {
      color: var(--primary-cobalt-60);
    }
  }
  &.DropdownMenuItem-module__slash___PuOEHt {
    font-family: var(--slash-main);
    color: var(--slash-black);
    &:not(:disabled):hover {
      &:hover {
        background-color: var(--slash-chalk);
      }
    }
    &:disabled {
      color: var(--slash-grey);
    }
  }
}
:root {
  --primary-cobalt-120: #0b1a63;
  --primary-cobalt-100: #1f2e77;
  --primary-cobalt-80: #4c5892;
  --primary-cobalt-60: #7982ad;
  --primary-cobalt-40: #a5abc9;
  --primary-cobalt-20: #d2d5e4;
  --primary-cobalt-10: #e9ebf2;
  --primary-cobalt-05: #f3f4f8;

  --primary-cobalt-op-06: #1f2e770f;

  --secondary-blue-120: #3450b5;
  --secondary-blue-100: #4864c9;
  --secondary-blue-80: #6d83d4;
  --secondary-blue-60: #91a2df;
  --secondary-blue-40: #b6c1e9;
  --secondary-blue-20: #dae0f4;
  --secondary-blue-10: #edf0fa;
  --secondary-blue-05: #f6f7fc;

  --secondary-sky-blue-120: #5c89eb;
  --secondary-sky-blue-100: #709dff;
  --secondary-sky-blue-80: #8db1ff;
  --secondary-sky-blue-60: #a9c4ff;
  --secondary-sky-blue-40: #c6d8ff;
  --secondary-sky-blue-20: #e2ebff;
  --secondary-sky-blue-10: #f1f6ff;
  --secondary-sky-blue-05: #f8faff;

  --secondary-dark-120: #000329;
  --secondary-dark-100: #10173d;
  --secondary-dark-80: #404564;
  --secondary-dark-60: #70748b;
  --secondary-dark-40: #9fa2b1;
  --secondary-dark-20: #cfd1d8;
  --secondary-dark-10: #e8e8ec;
  --secondary-dark-05: #f3f3f5;

  --primary-cobalt: var(--primary-cobalt-100);
  --secondary-blue: var(--secondary-blue-100);
  --secondary-sky-blue: var(--secondary-sky-blue-100);
  --secondary-dark: var(--secondary-dark-100);

  --slash-yellow: #f2cd1f;
  --slash-teal: #1a666e;
  --slash-chalk: #f5f5f5;
  --slash-light-grey: #e8e8ec;
  --slash-grey: #c4c4c4;
  --slash-dark-grey: #757575;
  --slash-black: #212121;
  --slash-shadow: #0000001f;
  --slash-orange: #ee734a;

  --secondary-red: #f66f81;
  --secondary-red-10: #fff1f3;
  --secondary-red-40: #fcc5cc;
  --secondary-red-60: #fba8b2;
  --secondary-red-80: #f98b99;
  --secondary-red-120: #e45a6b;
}
:root {
  --hiresweet-main: Graphik, sans-serif;
  --hiresweet-accent: Gilroy, sans-serif;
  --slash-main: 'Lexend Exa', sans-serif;
}
:root {
  --options-displayed: 8.7;
  --option-height: 32px;
  --padding-y: 8px;
}
.DropdownMenuPanel-module__menu___RUVhME {
  padding: var(--padding-y) 0;
  /* Leave a padding height at the end to indicate there are more elements than displayed */
  max-height: var(--options-displayed) * var(--option-height) + var(--padding-y);

  overflow-y: auto;
}
.ProfileTableStatus-module__container___Bn4Nh7 {
  display: flex;
  align-items: center;
  gap: 6px;
  font-family: var(--slash-main);
  line-height: 18px;
  letter-spacing: 0.16px;
  font-size: 13px;
}

.ProfileTableStatus-module__pill___q7B9lS {
  padding: 7px 10px;
  border-radius: 16px;
  border-width: 1px;
  border-style: solid;
  font-weight: 400;
  text-align: center;

  &.ProfileTableStatus-module__interim-draft___d5QTEc {
    border-color: rgba(2, 136, 209, 0.5);
    background-color: rgba(2, 136, 209, 0.12);
    color: rgba(2, 136, 209, 1);
  }
  &.ProfileTableStatus-module__waiting-for-review___QfhYdZ {
    border-color: rgba(156, 39, 176, 0.5);
    background-color: rgba(156, 39, 176, 0.12);
    color: rgba(156, 39, 176, 1);
  }
  &.ProfileTableStatus-module__interim-validated___Hc8oq9 {
    background-color: rgba(46, 125, 50, 0.12);
    border-color: rgba(46, 125, 50, 0.5);
    color: rgba(22, 146, 34, 1);
  }
  &.ProfileTableStatus-module__interim-refused___6WS4Xv {
    background-color: rgba(211, 47, 47, 0.12);
    border-color: rgba(211, 47, 47, 0.5);
    color: rgba(211, 47, 47, 1);
  }
  &.ProfileTableStatus-module__to-treat___XCW7Mh {
    background-color: rgba(237, 108, 2, 0.12);
    border-color: rgba(237, 108, 2, 0.5);
    color: rgba(242, 153, 74, 1);
  }
  &.ProfileTableStatus-module__candidate___B6MRcs {
    background-color: rgba(237, 108, 2, 0.12);
    border-color: rgba(237, 108, 2, 0.5);
    color: rgba(242, 153, 74, 1);
  }
  &.ProfileTableStatus-module__pending-invitation___wv6nIP {
    background-color: rgba(237, 108, 2, 0.12);
    border-color: rgba(237, 108, 2, 0.5);
    color: rgba(242, 153, 74, 1);
  }
  &.ProfileTableStatus-module__closed-invitation___hWn-iw {
    background-color: rgba(211, 47, 47, 0.12);
    border-color: rgba(211, 47, 47, 0.5);
    color: rgba(211, 47, 47, 1);
  }
}
.ProfileHeaderAgencies-module__moreAgenciesModalButton___vc6X6E {
  display: flex;
  gap: 10px;
  align-items: center;
  text-decoration: underline;
  font-family: var(--slash-main);
  font-size: 13px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.46px;
  text-align: left;
  color: var(--slash-black);
  cursor: pointer;
}

.ProfileHeaderAgencies-module__agencyModalItem___sf-8Ll {
  display: flex;
  gap: 8px;
  align-items: center;
  color: #515151;
}
.Tooltip-module__popupContainer___ac5NJ- {
  position: relative;
  display: inline-block;
}

.Tooltip-module__popup___vJnsGm {
  position: absolute;
  z-index: 1;
}
.ProfileAgencies-module__tooltipTrigger___D3o2iX {
  cursor: pointer;
  padding: 4px 6px;
  margin-left: 6px;

  .ProfileAgencies-module__moreAgencies___KmoGqn {
    color: var(--slash-dark-grey);
  }
}

.ProfileAgencies-module__moreAgenciesTooltip___ASTT4x {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px 12px;
  background-color: white;
  border-radius: 8px;
  min-width: fit-content;
  white-space: nowrap;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.CollapsibleEnrichedText-module__collapsibleToggle___tOwime {
  padding-left: 3px;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: var(--primary-cobalt);
  }
}

.CollapsibleEnrichedText-module__expandableContainer___wlXxCe {
  .CollapsibleEnrichedText-module__expandableContainerContent___6wVFsO {
    &.CollapsibleEnrichedText-module__hasShowMore___hWFIl-:not(.CollapsibleEnrichedText-module__showMore___Cg6aiq) {
      max-height: 190px;
      overflow: hidden;

      -webkit-mask-image: linear-gradient(
        to bottom,
        black 75%,
        transparent 100%
      );
      mask-image: linear-gradient(to bottom, black 75%, transparent 100%);
    }
  }

  .CollapsibleEnrichedText-module__showLessButton___qfiPw2,
  .CollapsibleEnrichedText-module__showMoreButton___GdaOMc {
    cursor: pointer;
    margin-top: 8px;
    font-family: Graphik, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: var(--primary-cobalt-20);

    text-align: center;

    &:hover {
      text-decoration: underline;
    }
  }
}
.ProfileExperienceSections-module__infoContainer___vJm3zv {
  display: flex;
  flex-direction: column;

  &.ProfileExperienceSections-module__slash___JfLrQT {
    margin-top: 25px;
    gap: 25px;
    font-family: var(--slash-main);

    .ProfileExperienceSections-module__experienceTitle___NU9RrX {
      color: black;
      font-weight: 700;
      line-height: 18.2px;
    }

    .ProfileExperienceSections-module__experienceLocationAndDate___PE7rBJ {
      color: var(--primary-cobalt-60);
    }

    .ProfileExperienceSections-module__experienceInfo___HtazJ3 {
      gap: 8px;
    }
  }

  &.ProfileExperienceSections-module__hiresweet___J-fbsE {
    font-family: Graphik, sans-serif;
    gap: 23px;

    .ProfileExperienceSections-module__experienceTitle___NU9RrX {
      color: var(--primary-cobalt);
      font-family: Graphik, sans-serif;
      font-weight: 600;
      line-height: 20px;
    }

    .ProfileExperienceSections-module__experienceLocationAndDate___PE7rBJ {
      color: var(--slash-dark-grey);
    }

    .ProfileExperienceSections-module__experienceInfo___HtazJ3 {
      gap: 4px;
    }

    .ProfileExperienceSections-module__companyName___Mn69iU {
      text-decoration: underline;
    }
  }

  .ProfileExperienceSections-module__experienceContainer___ZM--e7 {
    display: flex;
    gap: 16px;

    .ProfileExperienceSections-module__experiencePicture___PJwP3i {
      height: 36px;
      width: 36px;
      background: #f5f5f5;

      &:has(img) {
        img {
          width: 36px;
          height: 36px;
          border-radius: 4px;
        }
      }

      &:has(svg) {
        padding: 8px 10px;
      }

      &:has(.ProfileExperienceSections-module__defaultCompanyLogo___ouQQbH) {
        text-align: center;
        background: #f6f7fc;

        svg {
          margin-top: calc(50% - 8px);
        }
      }
    }

    .ProfileExperienceSections-module__experienceInfo___HtazJ3 {
      display: flex;
      flex-direction: column;

      .ProfileExperienceSections-module__experienceTitle___NU9RrX {
        font-size: 14px;
        text-align: left;
      }

      .ProfileExperienceSections-module__experienceLocationAndDate___PE7rBJ {
        font-size: 12px;
        font-weight: 400;
        line-height: 13px;
        text-align: left;
        color: var(--primary-cobalt-60);
      }

      .ProfileExperienceSections-module__experienceDescription___h5NOI7 {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        text-align: left;
        color: var(--primary-cobalt-80);
      }
    }
  }

  .ProfileExperienceSections-module__experienceCard___ixmF5t {
    padding: 12px;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid #ebebeb;
    box-shadow: 0px 2px 4px 0px var(--primary-cobalt-op-06);

    .ProfileExperienceSections-module__experienceTitle___NU9RrX {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: black;

      span {
        font-size: 13px;
        font-weight: 400;
        line-height: 18.5px;
        text-align: left;
        color: var(--slash-dark-grey);
      }
    }

    .ProfileExperienceSections-module__experienceLocationAndDate___PE7rBJ {
      font-size: 13px;
      font-weight: 400;
      line-height: 18.5px;
      text-align: left;
      color: black;
    }
  }
}
:root {
  --primary-cobalt-120: #0b1a63;
  --primary-cobalt-100: #1f2e77;
  --primary-cobalt-80: #4c5892;
  --primary-cobalt-60: #7982ad;
  --primary-cobalt-40: #a5abc9;
  --primary-cobalt-20: #d2d5e4;
  --primary-cobalt-10: #e9ebf2;
  --primary-cobalt-05: #f3f4f8;

  --primary-cobalt-op-06: #1f2e770f;

  --secondary-blue-120: #3450b5;
  --secondary-blue-100: #4864c9;
  --secondary-blue-80: #6d83d4;
  --secondary-blue-60: #91a2df;
  --secondary-blue-40: #b6c1e9;
  --secondary-blue-20: #dae0f4;
  --secondary-blue-10: #edf0fa;
  --secondary-blue-05: #f6f7fc;

  --secondary-sky-blue-120: #5c89eb;
  --secondary-sky-blue-100: #709dff;
  --secondary-sky-blue-80: #8db1ff;
  --secondary-sky-blue-60: #a9c4ff;
  --secondary-sky-blue-40: #c6d8ff;
  --secondary-sky-blue-20: #e2ebff;
  --secondary-sky-blue-10: #f1f6ff;
  --secondary-sky-blue-05: #f8faff;

  --secondary-dark-120: #000329;
  --secondary-dark-100: #10173d;
  --secondary-dark-80: #404564;
  --secondary-dark-60: #70748b;
  --secondary-dark-40: #9fa2b1;
  --secondary-dark-20: #cfd1d8;
  --secondary-dark-10: #e8e8ec;
  --secondary-dark-05: #f3f3f5;

  --primary-cobalt: var(--primary-cobalt-100);
  --secondary-blue: var(--secondary-blue-100);
  --secondary-sky-blue: var(--secondary-sky-blue-100);
  --secondary-dark: var(--secondary-dark-100);

  --slash-yellow: #f2cd1f;
  --slash-teal: #1a666e;
  --slash-chalk: #f5f5f5;
  --slash-light-grey: #e8e8ec;
  --slash-grey: #c4c4c4;
  --slash-dark-grey: #757575;
  --slash-black: #212121;
  --slash-shadow: #0000001f;
  --slash-orange: #ee734a;

  --secondary-red: #f66f81;
  --secondary-red-10: #fff1f3;
  --secondary-red-40: #fcc5cc;
  --secondary-red-60: #fba8b2;
  --secondary-red-80: #f98b99;
  --secondary-red-120: #e45a6b;
}

.Checkbox-module__checkbox___u-vF9Q {
  display: inline-flex;
  vertical-align: text-top;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 16px;
  height: 16px;
  font-size: 16px;
  flex-shrink: 0;
  border-width: 2px;
  border-style: solid;
  background-color: white;
  color: white;
  border-radius: 4px;
  user-select: none;

  &.Checkbox-module__slash___v7fV0j {
    border-color: var(--slash-dark-grey);
    &.Checkbox-module__big___01YlQY {
      width: 18px;
      height: 18px;
    }
    &.Checkbox-module__checked___XRIvY0 {
      border-color: var(--slash-yellow);
      background-color: var(--slash-yellow);
    }
  }

  &.Checkbox-module__hiresweet___C1vovY {
    border-color: var(--primary-cobalt-20);
    &.Checkbox-module__big___01YlQY {
      width: 24px;
      height: 24px;
    }
    &.Checkbox-module__checked___XRIvY0 {
      border-color: var(--secondary-blue);
      background-color: var(--secondary-blue);
    }
  }

  &:is(button) {
    cursor: pointer;
  }

  input {
    display: none;
  }
}
:root {
  --primary-cobalt-120: #0b1a63;
  --primary-cobalt-100: #1f2e77;
  --primary-cobalt-80: #4c5892;
  --primary-cobalt-60: #7982ad;
  --primary-cobalt-40: #a5abc9;
  --primary-cobalt-20: #d2d5e4;
  --primary-cobalt-10: #e9ebf2;
  --primary-cobalt-05: #f3f4f8;

  --primary-cobalt-op-06: #1f2e770f;

  --secondary-blue-120: #3450b5;
  --secondary-blue-100: #4864c9;
  --secondary-blue-80: #6d83d4;
  --secondary-blue-60: #91a2df;
  --secondary-blue-40: #b6c1e9;
  --secondary-blue-20: #dae0f4;
  --secondary-blue-10: #edf0fa;
  --secondary-blue-05: #f6f7fc;

  --secondary-sky-blue-120: #5c89eb;
  --secondary-sky-blue-100: #709dff;
  --secondary-sky-blue-80: #8db1ff;
  --secondary-sky-blue-60: #a9c4ff;
  --secondary-sky-blue-40: #c6d8ff;
  --secondary-sky-blue-20: #e2ebff;
  --secondary-sky-blue-10: #f1f6ff;
  --secondary-sky-blue-05: #f8faff;

  --secondary-dark-120: #000329;
  --secondary-dark-100: #10173d;
  --secondary-dark-80: #404564;
  --secondary-dark-60: #70748b;
  --secondary-dark-40: #9fa2b1;
  --secondary-dark-20: #cfd1d8;
  --secondary-dark-10: #e8e8ec;
  --secondary-dark-05: #f3f3f5;

  --primary-cobalt: var(--primary-cobalt-100);
  --secondary-blue: var(--secondary-blue-100);
  --secondary-sky-blue: var(--secondary-sky-blue-100);
  --secondary-dark: var(--secondary-dark-100);

  --slash-yellow: #f2cd1f;
  --slash-teal: #1a666e;
  --slash-chalk: #f5f5f5;
  --slash-light-grey: #e8e8ec;
  --slash-grey: #c4c4c4;
  --slash-dark-grey: #757575;
  --slash-black: #212121;
  --slash-shadow: #0000001f;
  --slash-orange: #ee734a;

  --secondary-red: #f66f81;
  --secondary-red-10: #fff1f3;
  --secondary-red-40: #fcc5cc;
  --secondary-red-60: #fba8b2;
  --secondary-red-80: #f98b99;
  --secondary-red-120: #e45a6b;
}

.LabeledCheckbox-module__container___InZXOB {
  display: inline-flex;
  gap: 8px;
  align-items: center;
  background: none;
  border: none;
  outline: none;

  &:not(:disabled) {
    cursor: pointer;

    &:hover {
      .LabeledCheckbox-module__label___iLP-q-.LabeledCheckbox-module__hiresweet___YzyhjC {
        color: var(--primary-cobalt);
      }
    }
  }
}

.LabeledCheckbox-module__label___iLP-q- {
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  &.LabeledCheckbox-module__hiresweet___YzyhjC {
    font-family: var(--hiresweet-main);
    color: var(--primary-cobalt-60);
  }
  &.LabeledCheckbox-module__slash___JMi-0z {
    font-family: var(--slash-main);
    color: var(--slash-black);
  }
}
.ProjectSharedViewManagementButton-module__dropdownContent___2-TYFx {
  background: white;
  padding: 12px;
  width: 400px;

  .ProjectSharedViewManagementButton-module__input___hu5-6N {
    display: flex;
    align-items: center;
    gap: 8px;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  .ProjectSharedViewManagementButton-module__urlContainer___az6cWs {
    display: flex;
    align-items: center;
    border: 1px solid var(--secondary-sky-blue-20);
    border-radius: 4px;
    gap: 8px;
    height: 32px;
    overflow: hidden;

    .ProjectSharedViewManagementButton-module__url___FZqDA8 {
      min-width: 0px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 4px 8px 4px 8px;
    }
  }

  &.ProjectSharedViewManagementButton-module__hiresweet___QNZ2GI {
    color: var(--primary-cobalt);
    font-family: var(--hiresweet-main);

    .ProjectSharedViewManagementButton-module__copyUrl___-hdOLf {
      background: var(--secondary-dark-05);
      border-left: 1px solid var(--secondary-sky-blue-20);
      color: var(--secondary-blue);
      height: 100%;
      width: 32px;
      min-width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  &.ProjectSharedViewManagementButton-module__slash___VtV--4 {
    color: var(--slash-black);
    font-family: var(--slash-main);

    .ProjectSharedViewManagementButton-module__copyUrl___-hdOLf {
      cursor: pointer;
      color: var(--slash-black);
    }
  }
}
:root {
  --primary-cobalt-120: #0b1a63;
  --primary-cobalt-100: #1f2e77;
  --primary-cobalt-80: #4c5892;
  --primary-cobalt-60: #7982ad;
  --primary-cobalt-40: #a5abc9;
  --primary-cobalt-20: #d2d5e4;
  --primary-cobalt-10: #e9ebf2;
  --primary-cobalt-05: #f3f4f8;

  --primary-cobalt-op-06: #1f2e770f;

  --secondary-blue-120: #3450b5;
  --secondary-blue-100: #4864c9;
  --secondary-blue-80: #6d83d4;
  --secondary-blue-60: #91a2df;
  --secondary-blue-40: #b6c1e9;
  --secondary-blue-20: #dae0f4;
  --secondary-blue-10: #edf0fa;
  --secondary-blue-05: #f6f7fc;

  --secondary-sky-blue-120: #5c89eb;
  --secondary-sky-blue-100: #709dff;
  --secondary-sky-blue-80: #8db1ff;
  --secondary-sky-blue-60: #a9c4ff;
  --secondary-sky-blue-40: #c6d8ff;
  --secondary-sky-blue-20: #e2ebff;
  --secondary-sky-blue-10: #f1f6ff;
  --secondary-sky-blue-05: #f8faff;

  --secondary-dark-120: #000329;
  --secondary-dark-100: #10173d;
  --secondary-dark-80: #404564;
  --secondary-dark-60: #70748b;
  --secondary-dark-40: #9fa2b1;
  --secondary-dark-20: #cfd1d8;
  --secondary-dark-10: #e8e8ec;
  --secondary-dark-05: #f3f3f5;

  --primary-cobalt: var(--primary-cobalt-100);
  --secondary-blue: var(--secondary-blue-100);
  --secondary-sky-blue: var(--secondary-sky-blue-100);
  --secondary-dark: var(--secondary-dark-100);

  --slash-yellow: #f2cd1f;
  --slash-teal: #1a666e;
  --slash-chalk: #f5f5f5;
  --slash-light-grey: #e8e8ec;
  --slash-grey: #c4c4c4;
  --slash-dark-grey: #757575;
  --slash-black: #212121;
  --slash-shadow: #0000001f;
  --slash-orange: #ee734a;

  --secondary-red: #f66f81;
  --secondary-red-10: #fff1f3;
  --secondary-red-40: #fcc5cc;
  --secondary-red-60: #fba8b2;
  --secondary-red-80: #f98b99;
  --secondary-red-120: #e45a6b;
}
:root {
  --hiresweet-main: Graphik, sans-serif;
  --hiresweet-accent: Gilroy, sans-serif;
  --slash-main: 'Lexend Exa', sans-serif;
}
.Table-module__table--hiresweet___wgZRo1 {
  border: 1px solid var(--secondary-blue-20);
  border-radius: 8px;
  background-color: white;
}
.Table-module__header___yPvh8h {
  display: flex;
  align-items: center;
}
.Table-module__header--hiresweet___nDenrF {
  padding: 0 16px;
  gap: 16px;
  height: 56px;
  background-color: var(--secondary-sky-blue-05);
  border-bottom: 1px solid var(--secondary-blue-20);
  &:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }
  &:not(:first-child) {
    border-top: 1px solid var(--secondary-blue-20);
  }
}
.Table-module__header--slash___2qBlfb {
  height: 54px;
  background-color: white;
  padding: 0px 0px 0px 16px;
  gap: 16px;
  border-bottom: 1px solid var(--slash-light-grey);
}
.Table-module__headerCell___soYYoV {
  flex: 1;
  line-height: 1em;
  &.Table-module__small___vm4Vcc {
    flex: 0;
  }
}
.Table-module__headerCell--hiresweet___j57ecy {
  font-weight: 500;
  font-size: 12px;
  font-family: var(--hiresweet-main);
  color: var(--primary-cobalt-60);
}
.Table-module__headerCell--slash___ATl0v1 {
  flex: 1 1;
  text-transform: uppercase;
  font-family: var(--slash-main);
  font-size: 13px;
  font-weight: 500;
  color: var(--slash-black);
}
.Table-module__row___AiAPh8 {
  display: flex;
  align-items: center;
  margin: 0;
  text-align: inherit;
}
.Table-module__row___AiAPh8.Table-module__clickable___u1eI99 {
  outline: none;
  cursor: pointer;
}
.Table-module__row--hiresweet___o-oUy7 {
  padding: 0 16px;
  gap: 16px;
  height: 72px;
  background-color: white;
  border: none;
  &:not(:last-child) {
    border-bottom: 1px solid var(--secondary-blue-20);
  }
  &:last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }
}
.Table-module__row--hiresweet___o-oUy7.Table-module__clickable___u1eI99:hover {
  background-color: var(--secondary-sky-blue-05);
}
.Table-module__row--slash___6GZjwT {
  height: 72px;
  background-color: white;
  padding: 0px 0px 0px 16px;
  gap: 16px;
  border: none;
  border-bottom: 1px solid var(--slash-light-grey);
}
.Table-module__row--slash___6GZjwT.Table-module__clickable___u1eI99:hover {
  background-color: var(--slash-chalk);
}
.Table-module__cell___EFKhnO {
  flex: 1;
  line-height: 1em;
  &.Table-module__small___vm4Vcc {
    flex: 0;
  }
  &.Table-module__ellipses___DlCwQp {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.Table-module__cell--hiresweet___7xrzGg {
  font-size: 14px;
  font-family: var(--hiresweet-main);
  color: var(--primary-cobalt);
}
.Table-module__cell--slash___qvl-Kk {
  font-family: var(--slash-main);
  min-width: 20px;
  flex-shrink: 1;
  font-size: 14px;
  font-weight: 400;
  color: var(--slash-black);
}
.Pagination-module__pagination___9DNorB {
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;

  .Pagination-module__arrow___3Jczz4 {
    background-color: transparent;
    padding: 9px;
    color: var(--slash-main);
    border: none;
    outline: none;

    &:not(:disabled) {
      cursor: pointer;
    }

    &:disabled {
      opacity: 0.38;
    }
  }

  .Pagination-module__pageNumber___LODg57 {
    cursor: pointer;
    border-radius: 50%;
    aspect-ratio: 1/1;
    font-family: Lexend Exa;
    font-weight: 400;
    text-align: center;
    background: transparent;
    border: none;
    outline: none;

    &.Pagination-module__medium___WDwTeS {
      width: 40px;
      font-size: 14px;
      line-height: 20px;
      padding: 10px 0px;
    }

    &.Pagination-module__small___KvIZnU {
      width: 30px;
      font-size: 12px;
      line-height: 15px;
      padding: 5px 0px;
    }

    &.Pagination-module__active___r2rUnD {
      &.Pagination-module__slash___NXwt-v {
        background: var(--slash-yellow);
      }

      &.Pagination-module__hiresweet___MLo8li {
        background: var(--secondary-blue);
        color: white;
      }
    }
  }
}
.SideNav-module__sideNavTabContainer___yLl5U2 {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &.SideNav-module__slash___F201o7 {
    gap: 9px;
  }

  &.SideNav-module__hiresweet___wAPx-t {
    gap: 5px;
    padding: 10px 20px;
  }
}

.SideNav-module__sideNavTab___GtMoQS {
  cursor: pointer;
  display: flex;
  align-items: center;

  &.SideNav-module__slash___F201o7 {
    gap: 12px;
    background: white;
    box-shadow: 0px 4px 30px 1px #75757533;
    padding: 24px;
    border-radius: 4px;

    .SideNav-module__label___Hv-jJX, .SideNav-module__icon___rEyI3C {
      color: var(--slash-dark-grey);
      font-family: var(--slash-main);
      font-size: 18px;
      font-weight: 400;
    }

    &.SideNav-module__active___rwXYq2 {
      .SideNav-module__label___Hv-jJX, .SideNav-module__icon___rEyI3C {
        color: var(--slash-black);
        font-weight: 800;
      }

      background: var(--slash-yellow);
    }
    &:hover {
      .SideNav-module__label___Hv-jJX, .SideNav-module__icon___rEyI3C {
        color: var(--slash-black);
      }
    }
  }

  &.SideNav-module__hiresweet___wAPx-t {
    height: 40px;
    background-color: white;
    border: 1px solid var(--secondary-blue-20);
    border-radius: 8px;
    text-align: left;
    padding: 12px 16px;
    gap: 8px;

    .SideNav-module__icon___rEyI3C {
      color: var(--primary-cobalt-80);
    }

    .SideNav-module__label___Hv-jJX {
      font-size: 1rem;
      line-height: 1em;
      font-family: var(--hiresweet-accent);
      font-weight: 700;
      color: var(--primary-cobalt-80);
    }

    &:hover {
      background-color: var(--secondary-sky-blue-05);

      .SideNav-module__label___Hv-jJX, .SideNav-module__icon___rEyI3C {
        color: var(--secondary-blue);
      }
    }

    &.SideNav-module__active___rwXYq2 {
      background-color: var(--secondary-blue);
      border: 1px solid var(--secondary-blue);

      .SideNav-module__label___Hv-jJX, .SideNav-module__icon___rEyI3C {
        color: white;
      }

      &:hover {
        background-color: var(--secondary-blue-120);
      }
    }
  }
}:root {
  --primary-cobalt-120: #0b1a63;
  --primary-cobalt-100: #1f2e77;
  --primary-cobalt-80: #4c5892;
  --primary-cobalt-60: #7982ad;
  --primary-cobalt-40: #a5abc9;
  --primary-cobalt-20: #d2d5e4;
  --primary-cobalt-10: #e9ebf2;
  --primary-cobalt-05: #f3f4f8;

  --primary-cobalt-op-06: #1f2e770f;

  --secondary-blue-120: #3450b5;
  --secondary-blue-100: #4864c9;
  --secondary-blue-80: #6d83d4;
  --secondary-blue-60: #91a2df;
  --secondary-blue-40: #b6c1e9;
  --secondary-blue-20: #dae0f4;
  --secondary-blue-10: #edf0fa;
  --secondary-blue-05: #f6f7fc;

  --secondary-sky-blue-120: #5c89eb;
  --secondary-sky-blue-100: #709dff;
  --secondary-sky-blue-80: #8db1ff;
  --secondary-sky-blue-60: #a9c4ff;
  --secondary-sky-blue-40: #c6d8ff;
  --secondary-sky-blue-20: #e2ebff;
  --secondary-sky-blue-10: #f1f6ff;
  --secondary-sky-blue-05: #f8faff;

  --secondary-dark-120: #000329;
  --secondary-dark-100: #10173d;
  --secondary-dark-80: #404564;
  --secondary-dark-60: #70748b;
  --secondary-dark-40: #9fa2b1;
  --secondary-dark-20: #cfd1d8;
  --secondary-dark-10: #e8e8ec;
  --secondary-dark-05: #f3f3f5;

  --primary-cobalt: var(--primary-cobalt-100);
  --secondary-blue: var(--secondary-blue-100);
  --secondary-sky-blue: var(--secondary-sky-blue-100);
  --secondary-dark: var(--secondary-dark-100);

  --slash-yellow: #f2cd1f;
  --slash-teal: #1a666e;
  --slash-chalk: #f5f5f5;
  --slash-light-grey: #e8e8ec;
  --slash-grey: #c4c4c4;
  --slash-dark-grey: #757575;
  --slash-black: #212121;
  --slash-shadow: #0000001f;
  --slash-orange: #ee734a;

  --secondary-red: #f66f81;
  --secondary-red-10: #fff1f3;
  --secondary-red-40: #fcc5cc;
  --secondary-red-60: #fba8b2;
  --secondary-red-80: #f98b99;
  --secondary-red-120: #e45a6b;
}

.SidePanel-module__viewport___s6KEkm {
  width: 100vw;
  height: 100svh;
  position: fixed;
  inset: 0;
  z-index: 1;
}

.SidePanel-module__backdrop___oPZpc- {
  position: absolute;
  inset: 0;
  animation: SidePanel-module__fade-in___GpDdJ9 200ms ease;
  opacity: 0.7;
  z-index: 0;
  &.SidePanel-module__slash___LwfguZ {
    background: var(--slash-black);
  }
  &.SidePanel-module__hiresweet___DtugUW {
    background: var(--primary-cobalt);
  }
}

.SidePanel-module__container___J5nT9v {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: stretch;
  z-index: 1;
}

.SidePanel-module__negativeSpace___IisIoI {
  flex: 1;
}

.SidePanel-module__panel___UWLoSb {
  width: 632px;
  overflow-y: auto;
  background: white;
  animation: SidePanel-module__slide-in___LgxcR9 250ms ease;
}

@keyframes SidePanel-module__slide-in___LgxcR9 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes SidePanel-module__fade-in___GpDdJ9 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.7;
  }
}
:root {
  --primary-cobalt-120: #0b1a63;
  --primary-cobalt-100: #1f2e77;
  --primary-cobalt-80: #4c5892;
  --primary-cobalt-60: #7982ad;
  --primary-cobalt-40: #a5abc9;
  --primary-cobalt-20: #d2d5e4;
  --primary-cobalt-10: #e9ebf2;
  --primary-cobalt-05: #f3f4f8;

  --primary-cobalt-op-06: #1f2e770f;

  --secondary-blue-120: #3450b5;
  --secondary-blue-100: #4864c9;
  --secondary-blue-80: #6d83d4;
  --secondary-blue-60: #91a2df;
  --secondary-blue-40: #b6c1e9;
  --secondary-blue-20: #dae0f4;
  --secondary-blue-10: #edf0fa;
  --secondary-blue-05: #f6f7fc;

  --secondary-sky-blue-120: #5c89eb;
  --secondary-sky-blue-100: #709dff;
  --secondary-sky-blue-80: #8db1ff;
  --secondary-sky-blue-60: #a9c4ff;
  --secondary-sky-blue-40: #c6d8ff;
  --secondary-sky-blue-20: #e2ebff;
  --secondary-sky-blue-10: #f1f6ff;
  --secondary-sky-blue-05: #f8faff;

  --secondary-dark-120: #000329;
  --secondary-dark-100: #10173d;
  --secondary-dark-80: #404564;
  --secondary-dark-60: #70748b;
  --secondary-dark-40: #9fa2b1;
  --secondary-dark-20: #cfd1d8;
  --secondary-dark-10: #e8e8ec;
  --secondary-dark-05: #f3f3f5;

  --primary-cobalt: var(--primary-cobalt-100);
  --secondary-blue: var(--secondary-blue-100);
  --secondary-sky-blue: var(--secondary-sky-blue-100);
  --secondary-dark: var(--secondary-dark-100);

  --slash-yellow: #f2cd1f;
  --slash-teal: #1a666e;
  --slash-chalk: #f5f5f5;
  --slash-light-grey: #e8e8ec;
  --slash-grey: #c4c4c4;
  --slash-dark-grey: #757575;
  --slash-black: #212121;
  --slash-shadow: #0000001f;
  --slash-orange: #ee734a;

  --secondary-red: #f66f81;
  --secondary-red-10: #fff1f3;
  --secondary-red-40: #fcc5cc;
  --secondary-red-60: #fba8b2;
  --secondary-red-80: #f98b99;
  --secondary-red-120: #e45a6b;
}

.PanelWithPagination-module__panel___WcqqR8 {
  width: calc(100vw - 200px);
}

.PanelWithPagination-module__buttonsContainer___Y0QvQt {
  padding: 20px;
  text-align: right;
}

.PanelWithPagination-module__buttons___D6gPyc {
  display: inline-flex;
  flex-direction: column;

  &.PanelWithPagination-module__hiresweet___56d9Q- {
    align-items: center;
    gap: 10px;
    background-color: var(--secondary-blue);
    padding: 4px;
    border-radius: 35px;
    padding: 5px;
  }
  &.PanelWithPagination-module__slash___rzKSqX {
    align-items: stretch;
    background-color: var(--slash-yellow);
    padding: 8px;
    gap: 4px;
  }
}

.PanelWithPagination-module__button___CLz5I0 {
  margin: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  &:not(:disabled) {
    cursor: pointer;
  }
  &.PanelWithPagination-module__hiresweet___56d9Q- {
    background-color: var(--secondary-blue-120);
    border-radius: 40px;
    color: white;
    font-size: 16px;
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    width: 40px;
    height: 40px;

    &:disabled {
      color: var(--secondary-blue-40);
    }
  }
  &.PanelWithPagination-module__slash___rzKSqX {
    background: transparent;
    font-size: 24px;
    color: var(--slash-black);
    &:disabled {
      color: var(--slash-dark-grey);
    }
  }
}

.PanelWithPagination-module__pages___JWzDYZ {
  text-align: center;

  &.PanelWithPagination-module__hiresweet___56d9Q- {
    color: white;
    font-family: var(--hiresweet-main);
    font-size: 16px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: center;
    font-weight: 600;

    .PanelWithPagination-module__pageNumber___3ZbJpY {
      border-top: 2px solid white;
      padding-top: 4px;
    }
  }
  &.PanelWithPagination-module__slash___rzKSqX {
    color: var(--slash-black);
    padding: 14px 0;
    border-top: 1px solid var(--slash-black);
    border-bottom: 1px solid var(--slash-black);
  }
}

.PanelWithPagination-module__closeContainer___1L6yCn {
  margin-bottom: 48px;
  text-align: -webkit-right;

  .PanelWithPagination-module__button___CLz5I0 {
    width: 50px;
    height: 50px;
    font-size: 24px;
  }
}
.svg-module__svgIcon___G5NvCz {
  width: 1em;
  height: 1em;
  vertical-align: text-top;
}
.svg-module__rotate180___ewOpz2 {
  rotate: 180deg;
}
.SalaryRange-module__container___oZgUzW {
  display: flex;
  gap: 12px;
  flex-wrap: nowrap;
  align-items: center;
}

.SalaryRange-module__currency___qw9Rao {
  min-width: 128px;
  width: auto;
}

.SalaryRange-module__period___xZQdAG {
  min-width: 128px;
  width: auto;
}

.SalaryRange-module__min___YQi4cq,
.SalaryRange-module__max___19NtOu {
  flex: 1;
}
.ProjectJobPostings-module__container___wLd1-P {
  padding-bottom: 200px;

  .ProjectJobPostings-module__section___W3QeDx {
    border-radius: 10px;
    box-shadow: 0px 3px 11px 0px #00000014;
    background-color: white;
    padding: 41px;
    margin-top: 12px;
    margin-bottom: 24px;

    &.ProjectJobPostings-module__headerSection___T1C5zY {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 40px;
      font-size: 14px;
      font-family: var(--slash-main);
      line-height: 20px;
      letter-spacing: 0.15px;
      text-transform: uppercase;
      color: #000000de;
    }

    h4 {
      font-family: var(--slash-main);
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.15px;
      color: var(--slash-black);
    }

    .ProjectJobPostings-module__sectionHeader___21NT2m {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;

      &.ProjectJobPostings-module__slash___KHfApK {
        font-family: var(--slash-main);
        font-size: 16px;
        font-weight: 800;
        line-height: 24px;
        letter-spacing: 0.15px;
        text-transform: uppercase;
        color: #000000de;
      }

      &.ProjectJobPostings-module__hiresweet___usQVFy {
        color: var(--primary-cobalt);
        font-family: var(--hiresweet-main);
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
      }
    }

    .ProjectJobPostings-module__carret___R2s4Tw {
      background: #f2cd1f;
      width: 51px;
      height: 5px;
    }

    .ProjectJobPostings-module__newSectionContainer___pe7hJs {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 48px;

      button {
        padding: 8px 16px;
        outline: none;
        border: none;
        font-size: 12px;
        font-weight: 500px;

        &:focus {
          outline: none;
        }

        &:hover {
          border: none;
        }
      }
    }
  }

  .ProjectJobPostings-module__addSectionTitle___OHxPm0 {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    margin: 0;
    margin-bottom: 12px;
  }

  .ProjectJobPostings-module__save___tfgc9- {
    background: #ee734a;
    color: black;
    outline: none;
  }
}

.ProjectJobPostings-module__inputElement___qmXVi8 {
  &.ProjectJobPostings-module__slash___KHfApK {
    font-family: var(--slash-main);
    font-size: 14px;
    line-height: 20.02px;

    .input {
      font-family: var(--slash-main);
      font-size: 14px;
      line-height: 20.02px;
      color: #000000de;
    }

    textarea {
      font-family: var(--slash-main);
      font-size: 14px;
      line-height: 20.02px;
      color: #000000de;
      border-radius: 4px;
      width: 100%;
      border: 1px solid #ebebeb;
      padding: 18px 23px;
      outline: none;
      resize: none;
      box-sizing: border-box;
    }

    &.ProjectJobPostings-module__richText___TLJlfD {
      white-space: pre-wrap;
    }
  }

  &.ProjectJobPostings-module__hiresweet___usQVFy {
    font-family: var(--hiresweet-main);

    textarea {
      border-radius: 4px;
      width: 100%;
      border: 1px solid #dddedf;
      padding: 8px 12px;
      color: black;
      outline: none;
      box-sizing: border-box;
    }
  }
}

.ProjectJobPostings-module__separator___kjqs3G {
  margin-top: 20px;
  height: 1px;
  background: var(--primary-cobalt-10);
}

.ProjectJobPostings-module__displayHeader___s6nKEG {
  display: flex;
  flex-direction: row;
}

.ProjectJobPostings-module__publishToggleContainer___0kgTxN {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;

  .ProjectJobPostings-module__publishToggle___3W8-9e {
    margin-bottom: 0px;
  }
}

.ProjectJobPostings-module__displayHeaderInfo___bnihXG {
  display: flex;
  flex-direction: column;
}

.ProjectJobPostings-module__header___7Kd2ql {
  margin-bottom: 0;
  color: silver;
  display: inline-block;
}

.ProjectJobPostings-module__sectionsContainer___Uja8TM {
  margin-top: 18px;
}

.ProjectJobPostings-module__linkIcon___h7IyyS {
  margin-left: 10px;
  font-size: 16px;
  position: relative;
  top: 2px;
}

.ProjectJobPostings-module__headerAndStatus___nWHs6h {
  display: flex;
  flex-direction: row;

  a:hover .ProjectJobPostings-module__header___7Kd2ql {
    text-decoration: underline;
  }
}

.ProjectJobPostings-module__hsFlexSpacer___qNZeHe {
  flex: 1;
  margin-top: 24px;
}

.ProjectJobPostings-module__publicationStatus___6AmIE5 {
  display: flex;
  align-items: center;
  margin-left: 16px;

  line-height: 20px;
  font-family: Graphik, sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 12px;

  &.ProjectJobPostings-module__published___s5y-CO {
    color: red;
  }
  &.ProjectJobPostings-module__unpublished___tVgCkk {
    color: red;
  }

  i {
    margin-right: 5px;
  }
}
.ProjectJobPostings-module__postingManagementInfo___v6oRaM {
  margin-bottom: 36px;
}

.ProjectJobPostings-module__folderingDisplay___ei2-n5 {
  margin-top: 8px;
  margin-bottom: 16px;
}

.ProjectJobPostings-module__locationsDisplay___EW3Yft {
  font-family: Gilroy, sans-serif;
  font-weight: 700;
  margin-top: 16px;
}

.ProjectJobPostings-module__jobDescriptionContainer___tQBEG9 {
  margin-top: 40px;
}

.ProjectJobPostings-module__inputContainer___HYFbP2:not(:first-of-type) {
  margin-top: 12px;
}

.ProjectJobPostings-module__inputInlineLabel___lLJ6GF {
  margin-left: 8px;
  display: inline-block;
}

.ProjectJobPostings-module__removeJobPostingIcon___Tkihl9 {
  margin-left: 8px;
  cursor: pointer;
}

.ProjectJobPostings-module__inputLabel___gc6eYG {
  display: inline-block;

  &.ProjectJobPostings-module__slash___KHfApK {
    font-family: var(--slash-main);
    font-size: 14px;
    font-weight: 700;
    line-height: 20.02px;
    text-align: left;
    color: #000000de;
  }

  &.ProjectJobPostings-module__hiresweet___usQVFy {
    font-family: var(--hiresweet-main);
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 8px;
    color: #4c5892;
  }
}

.ProjectJobPostings-module__simpleSectionHeader___hwnigB {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.ProjectJobPostings-module__slash___KHfApK {
    font-family: var(--slash-main);
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-transform: uppercase;
    color: #000000de;
  }

  &.ProjectJobPostings-module__hiresweet___usQVFy {
    color: var(--primary-cobalt);
    font-family: var(--hiresweet-main);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }

  h4 {
    margin-bottom: 0px;
  }
}

.ProjectJobPostings-module__footer___-S9xSI {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ProjectJobPostings-module__jobPostingDescriptionSection___MEWqhJ {
  white-space: pre-line;
  margin-bottom: 48px;

  .ProjectJobPostings-module__jobPostingDescriptionSectionHeader___0kHOX6 {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .ProjectJobPostings-module__inputLabel___gc6eYG {
      display: flex;
      i {
        margin-left: 4px;
        cursor: pointer;
      }
    }

    button {
      margin-bottom: 10px;
    }
  }
}

.ProjectJobPostings-module__addSectionContainer___XULA2C {
  margin-bottom: 24px;
  display: flex;
  gap: 8px;
}

.ProjectJobPostings-module__videoContainer___VvQFwJ,
.ProjectJobPostings-module__imageContainer___CwOWJ1 {
  margin-left: 48px;
  margin-right: 48px;
}

.ProjectJobPostings-module__inputElement___qmXVi8 .ProjectJobPostings-module__imageContainer___CwOWJ1 {
  margin-top: 24px;
}

.ProjectJobPostings-module__playerWrapper___kbJKRf {
  position: relative;
  padding-top: calc(720 / 1280 * 100%);

  .ProjectJobPostings-module__reactPlayer___XGM9xS {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.ProjectJobPostings-module__contentItem___4qJGf- {
  &.ProjectJobPostings-module__slash___KHfApK {
    .ProjectJobPostings-module__label___y2PWf7 {
      font-family: var(--slash-main);
      font-size: 14px;
      font-weight: 600;
      line-height: 20.02px;
      color: var(--slash-black);
    }

    .ProjectJobPostings-module__value___GavLcn {
      font-family: var(--slash-main);
      font-size: 14px;
      font-weight: 400;
      line-height: 20.02px;
      color: var(--slash-black);
    }
  }

  &.ProjectJobPostings-module__fluid___OEgGOM {
    flex-grow: 1;
  }
}

.ProjectJobPostings-module__row___G6Iw06 {
  display: flex;
  gap: 24px;
  margin: 24px 0;

  .ProjectJobPostings-module__column___qC-AUW {
    flex: 1 1;
  }
}

.ProjectJobPostings-module__modalContent___-8N-kB {
  display: flex;
  gap: 24px;

  > div {
    flex: 1 1;
  }

  .ProjectJobPostings-module__leftPanel___bO6W51 {
    padding: 0px 24px;
    border-right: 1px solid var(--slash-light-grey);
  }
}

.ProjectJobPostings-module__additionalInstructions___3ogdRA {
  margin-top: 12px;
  padding: 18px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 12px;
}

.ProjectJobPostings-module__requiredMark___vqR12X {
  color: red;
}
.ProjectApplicantsOverview-module__projectsOverviewContainer___CDqjlb {
  width: 100%;
  padding: 25px;
  gap: 30px;
  border-radius: 10px;
  display: flex;
  /* align-items: center; */
  box-shadow: 0px 3px 11px 0px #00000014;
  background-color: white;
  margin-bottom: 30px;
  box-sizing: border-box;
  align-items: flex-start;

  &.ProjectApplicantsOverview-module__slash___YYCipg {
    font-family: var(--slash-main);

    svg {
      flex-shrink: 0;
    }

    .ProjectApplicantsOverview-module__title___0-IZuK {
      font-family: var(--slash-main);
      font-weight: 500;
      color: var(--slash-teal);
    }

    .ProjectApplicantsOverview-module__detailElem___0b-VoP {
      background: #f3f5f7;
      border: 1px solid var(--slash-teal);
    }

    .ProjectApplicantsOverview-module__detailText___SUT6k5 {
      color: var(--slash-teal);
    }

    .ProjectApplicantsOverview-module__detailNumber___1u7JPW {
      background: var(--slash-teal);
    }
  }

  &.ProjectApplicantsOverview-module__hiresweet___3-waCm {
    font-family: var(--hiresweet-main);

    .ProjectApplicantsOverview-module__title___0-IZuK {
      font-weight: 600;
      color: var(--secondary-blue);
    }

    .ProjectApplicantsOverview-module__detailElem___0b-VoP {
      background: #f0f0f6;
      border: 1px solid var(--secondary-blue);
    }

    .ProjectApplicantsOverview-module__detailText___SUT6k5 {
      color: var(--secondary-blue);
    }

    .ProjectApplicantsOverview-module__detailNumber___1u7JPW {
      background: var(--secondary-blue);
    }
  }
}

.ProjectApplicantsOverview-module__mainInfo___TzJ3nR {
  flex-grow: 1;
  .ProjectApplicantsOverview-module__title___0-IZuK {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-align: left;
    margin: 0;
  }

  .ProjectApplicantsOverview-module__description___i7SiqY {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.1599999964237213px;
    text-align: left;
    color: #00000099;
    margin: 0;
  }
}

.ProjectApplicantsOverview-module__details___YNLFQS {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .ProjectApplicantsOverview-module__detailRow___Y3m4KX {
    display: flex;
    gap: 10px;
    align-items: center;

    .ProjectApplicantsOverview-module__detailElem___0b-VoP {
      padding: 4px 12px 4px 12px;
      gap: 8px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      gap: 8px;
      font-family: inherit;

      &:not(.ProjectApplicantsOverview-module__seeMore___hvjgM-) {
        justify-content: space-between;
        width: 200px;
      }

      &:not(:disabled) {
        cursor: pointer;
      }

      .ProjectApplicantsOverview-module__detailText___SUT6k5 {
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.1599999964237213px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .ProjectApplicantsOverview-module__detailNumber___1u7JPW {
        padding: 0px 6.5px 0px 6.5px;
        gap: 0px;
        border-radius: 50%;
        opacity: 0px;
        color: white;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.14000000059604645px;
        text-align: left;
      }
    }
  }
}

.ProjectApplicantsOverview-module__tooltipContent___enxXns {
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 3px 11px 0px #00000014;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: 400;
  line-height: 23.24px;
  letter-spacing: 0.4px;
  text-align: left;
  color: var(--slash-black);
  white-space: nowrap;
}
.ProjectClickHandler-module__button___YDGPyx {
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  border: none;
  background: transparent;
  color: inherit;
  font: inherit;
  text-align: inherit;
  cursor: pointer;
}
:root {
  --primary-cobalt-120: #0b1a63;
  --primary-cobalt-100: #1f2e77;
  --primary-cobalt-80: #4c5892;
  --primary-cobalt-60: #7982ad;
  --primary-cobalt-40: #a5abc9;
  --primary-cobalt-20: #d2d5e4;
  --primary-cobalt-10: #e9ebf2;
  --primary-cobalt-05: #f3f4f8;

  --primary-cobalt-op-06: #1f2e770f;

  --secondary-blue-120: #3450b5;
  --secondary-blue-100: #4864c9;
  --secondary-blue-80: #6d83d4;
  --secondary-blue-60: #91a2df;
  --secondary-blue-40: #b6c1e9;
  --secondary-blue-20: #dae0f4;
  --secondary-blue-10: #edf0fa;
  --secondary-blue-05: #f6f7fc;

  --secondary-sky-blue-120: #5c89eb;
  --secondary-sky-blue-100: #709dff;
  --secondary-sky-blue-80: #8db1ff;
  --secondary-sky-blue-60: #a9c4ff;
  --secondary-sky-blue-40: #c6d8ff;
  --secondary-sky-blue-20: #e2ebff;
  --secondary-sky-blue-10: #f1f6ff;
  --secondary-sky-blue-05: #f8faff;

  --secondary-dark-120: #000329;
  --secondary-dark-100: #10173d;
  --secondary-dark-80: #404564;
  --secondary-dark-60: #70748b;
  --secondary-dark-40: #9fa2b1;
  --secondary-dark-20: #cfd1d8;
  --secondary-dark-10: #e8e8ec;
  --secondary-dark-05: #f3f3f5;

  --primary-cobalt: var(--primary-cobalt-100);
  --secondary-blue: var(--secondary-blue-100);
  --secondary-sky-blue: var(--secondary-sky-blue-100);
  --secondary-dark: var(--secondary-dark-100);

  --slash-yellow: #f2cd1f;
  --slash-teal: #1a666e;
  --slash-chalk: #f5f5f5;
  --slash-light-grey: #e8e8ec;
  --slash-grey: #c4c4c4;
  --slash-dark-grey: #757575;
  --slash-black: #212121;
  --slash-shadow: #0000001f;
  --slash-orange: #ee734a;

  --secondary-red: #f66f81;
  --secondary-red-10: #fff1f3;
  --secondary-red-40: #fcc5cc;
  --secondary-red-60: #fba8b2;
  --secondary-red-80: #f98b99;
  --secondary-red-120: #e45a6b;
}

.ProfilesTable-module__selected___c90Pv1 {
  border-left: 4px solid var(--secondary-blue);
  padding-left: 12px;
  background-color: var(--secondary-sky-blue-05);
}
.EmptyState-module__container___YEr7QS {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 14px;

  .EmptyState-module__text___Q13zBe.EmptyState-module__slash___WXoG6n {
    font-family: var(--slash-main);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #232930;
  }

  .EmptyState-module__slashIcon___NNWO6a {
    width: 3em;
    height: 3em;
  }
}
:root {
  --primary-cobalt-120: #0b1a63;
  --primary-cobalt-100: #1f2e77;
  --primary-cobalt-80: #4c5892;
  --primary-cobalt-60: #7982ad;
  --primary-cobalt-40: #a5abc9;
  --primary-cobalt-20: #d2d5e4;
  --primary-cobalt-10: #e9ebf2;
  --primary-cobalt-05: #f3f4f8;

  --primary-cobalt-op-06: #1f2e770f;

  --secondary-blue-120: #3450b5;
  --secondary-blue-100: #4864c9;
  --secondary-blue-80: #6d83d4;
  --secondary-blue-60: #91a2df;
  --secondary-blue-40: #b6c1e9;
  --secondary-blue-20: #dae0f4;
  --secondary-blue-10: #edf0fa;
  --secondary-blue-05: #f6f7fc;

  --secondary-sky-blue-120: #5c89eb;
  --secondary-sky-blue-100: #709dff;
  --secondary-sky-blue-80: #8db1ff;
  --secondary-sky-blue-60: #a9c4ff;
  --secondary-sky-blue-40: #c6d8ff;
  --secondary-sky-blue-20: #e2ebff;
  --secondary-sky-blue-10: #f1f6ff;
  --secondary-sky-blue-05: #f8faff;

  --secondary-dark-120: #000329;
  --secondary-dark-100: #10173d;
  --secondary-dark-80: #404564;
  --secondary-dark-60: #70748b;
  --secondary-dark-40: #9fa2b1;
  --secondary-dark-20: #cfd1d8;
  --secondary-dark-10: #e8e8ec;
  --secondary-dark-05: #f3f3f5;

  --primary-cobalt: var(--primary-cobalt-100);
  --secondary-blue: var(--secondary-blue-100);
  --secondary-sky-blue: var(--secondary-sky-blue-100);
  --secondary-dark: var(--secondary-dark-100);

  --slash-yellow: #f2cd1f;
  --slash-teal: #1a666e;
  --slash-chalk: #f5f5f5;
  --slash-light-grey: #e8e8ec;
  --slash-grey: #c4c4c4;
  --slash-dark-grey: #757575;
  --slash-black: #212121;
  --slash-shadow: #0000001f;
  --slash-orange: #ee734a;

  --secondary-red: #f66f81;
  --secondary-red-10: #fff1f3;
  --secondary-red-40: #fcc5cc;
  --secondary-red-60: #fba8b2;
  --secondary-red-80: #f98b99;
  --secondary-red-120: #e45a6b;
}
:root {
  --hiresweet-main: Graphik, sans-serif;
  --hiresweet-accent: Gilroy, sans-serif;
  --slash-main: 'Lexend Exa', sans-serif;
}
.ProfileContractsHistory-module__header___UIxk8x {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.ProfileContractsHistory-module__slash___GOPKKW {
    font-family: var(--slash-main);
  }
}
.ProfileContractsHistory-module__content___LiecvN {
  display: flex;
  flex-direction: column;
  gap: 26px;
  margin-top: 20px;
  position: relative;
  flex-grow: 1;
}
.ProfileContractsHistory-module__count___MBNhMD {
  height: 20px;
  padding: 0px 6px;
  font-size: 12px;
  line-height: 20px;
  border-radius: 20px;
  font-weight: 500;
  margin-left: 8px;

  &.ProfileContractsHistory-module__slash___GOPKKW {
    font-family: var(--slash-main);
    background: var(--slash-yellow);
    color: var(--slash-black);
  }
  &.ProfileContractsHistory-module__hiresweet___qoDnnc {
    font-family: var(--hiresweet-main);
    background: var(--secondary-sky-blue-20);
    color: var(--secondary-blue);
  }
}
.ProfileContractsHistory-module__title___8sxkZL {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .ProfileContractsHistory-module__left___gWhFVa {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
.ProfileContractsHistory-module__seeMore___YtrEtq {
  text-decoration: underline;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  text-transform: uppercase;
}
.ProfileContractsHistory-module__container___tcyx44 {
  display: flex;
  flex-direction: column;
  height: 100%;

  .ProfileContractsHistory-module__sectionHeader___sNnBxP {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: black;
    margin-bottom: 20px;
  }

  .ProfileContractsHistory-module__headerTitleContainer___F-daq3 {
    display: flex;
    justify-content: space-between;

    .ProfileContractsHistory-module__headerTitle___z2rmT7 {
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.15px;
      text-align: left;
      color: black;
    }
  }
}
.ProfileContractsHistory-module__emptyState___9---PB {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ProfileContractsHistory-module__experienceContainer___8VkITx {
  margin: 12px 0;
  background-color: white;
  padding: 12px;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #ebebeb;
  box-shadow: 0px 2px 4px 0px var(--primary-cobalt-op-06);

  .ProfileContractsHistory-module__experienceTitle___lp8s5c {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: black;

    span {
      font-size: 13px;
      font-weight: 400;
      line-height: 18.5px;
      text-align: left;
      color: #717171;
    }
  }
  .ProfileContractsHistory-module__experienceLocationAndDate___tq1vHN {
    font-size: 13px;
    font-weight: 400;
    line-height: 18.5px;
    text-align: left;
    color: black;
  }
}
.ProfileContractsHistory-module__panel___UnMhB5 {
  padding: 32px;
}
.ProfileClickHandler-module__button___UEdCkY {
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  border: none;
  background: transparent;
  color: inherit;
  font: inherit;
  text-align: inherit;
  cursor: pointer;
}
.ProfileTimelinePreview-module__timelinePreviewContainer___VTE7EG {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 3px 11px 0px #00000014;
  padding: 24px;
  height: 100%;

  .ProfileTimelinePreview-module__timelinePreviewHeader___fWMV1U {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: var(--slash-main);

    .ProfileTimelinePreview-module__headerTitleContainer___bvE77t {
      .ProfileTimelinePreview-module__headerTitle___JXpsol {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.15px;
        text-align: left;
        color: black;
      }

      .ProfileTimelinePreview-module__counter___PbQpDk {
        background: #f2cd1f;
        border-radius: 100vmax;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: black;
        padding: 0px 6px;
        height: 20px;
        display: inline-block;
        margin-left: 8px;
      }
    }

    .ProfileTimelinePreview-module__seeMore___utzsxN {
      text-decoration: underline;
      color: black;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      text-align: left;
      cursor: pointer;
      text-transform: uppercase;
    }
  }

  .ProfileTimelinePreview-module__timelinePreviewContent___FFtHMY {
    display: flex;
    flex-direction: column;
    gap: 26px;
    margin-top: 20px;
    position: relative;

    &:has(.ProfileTimelinePreview-module__timelinePreviewItem___MRvO5b:nth-of-type(2)) {
      .ProfileTimelinePreview-module__timelinePreviewItem___MRvO5b:nth-of-type(1) {
        > div:first-of-type {
          height: calc(100% + 26px) !important;
        }
      }

      .ProfileTimelinePreview-module__timelinePreviewItem___MRvO5b:last-of-type {
        > div:first-of-type {
          opacity: 38% !important;
          height: calc(100% - 10px) !important;
        }
      }
    }

    &:not(:has(.ProfileTimelinePreview-module__timelinePreviewItem___MRvO5b:nth-of-type(2))) {
      .ProfileTimelinePreview-module__timelinePreviewItem___MRvO5b {
        > div:first-of-type {
          height: 100% !important;
        }
      }
    }

    .ProfileTimelinePreview-module__timelinePreviewItem___MRvO5b {
      display: flex;
      gap: 17px;
      position: relative;
    }

    .ProfileTimelinePreview-module__itemActions___iBb5UV {
      margin-left: 16px;
      height: 36px;
    }
  }
}

.ProfileTimelinePreview-module__timelineContainer___pNtzFF {
  padding: 32px;
}
.ProfileTimelineItem-module__timelineItemBar___HZi59o {
  position: absolute;
  left: 5px;
  width: 2px;
  height: calc(100% + 26px);
  top: 10px;
  background: #f2cd1f;
}

.ProfileTimelineItem-module__itemContent___GeOZOI {
  flex-grow: 1;
  min-width: 0;
  position: relative;
}

.ProfileTimelineItem-module__itemCircleContainer___gRTEjl {
  position: relative;
  flex-shrink: 0;
  width: 12px;
  height: 100%;

  .ProfileTimelineItem-module__itemCircle___bQgAz1 {
    top: 5px;
    width: inherit;
    height: 12px;
    border-radius: 100vmax;
    background: #f2cd1f;
    position: absolute;
  }
}

.ProfileTimelineItem-module__itemTitleContainer___janueR {
  display: flex;
  gap: 8px;
  align-items: center;
}

.ProfileTimelineItem-module__seeMore___W2zaAD {
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: black;
  margin-bottom: 4px;
  font-family: var(--slash-main);
  min-width: max-content;
}

.ProfileTimelineItem-module__seeMoreContent___kolGj- {
  padding: 16px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  font-family: var(--slash-main);
  font-size: 14px;
  font-weight: 500;
  color: var(--slash-black);
  width: max-content;
  max-width: 350px;
}

.ProfileTimelineItem-module__itemTitle___pMl0Rk {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 4px;
  font-family: var(--slash-main);
  position: relative;
}

.ProfileTimelineItem-module__itemAuthor___efqMow {
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  color: #aeaeae;
  font-family: var(--slash-main);
}

.ProfileTimelineItem-module__itemComment___hO8XR3,
.ProfileTimelineItem-module__itemEmailContent___LlFDOK {
  margin-top: 8px;
  background: #f5f5f5;
  padding: 17px;
  gap: 17px;
  display: flex;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: left;
  font-family: var(--slash-main);
}

.ProfileTimelineItem-module__itemEmailContent___LlFDOK {
  flex-direction: column;
  gap: 12px;
}
.Popup-module__popupContainer___JgpNRG {
  position: relative;
  display: inline-block;
}

.Popup-module__popup___o86DGR {
  position: absolute;
  z-index: 1;
}
.ProfileTimeline-module__timelinePreviewHeader___nIcOr0 {
  display: flex;
  justify-content: space-between;
  font-family: var(--slash-main);

  .ProfileTimeline-module__headerTitle___FFn3X1 {
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
    color: black;
  }

  .ProfileTimeline-module__counter___gMVgZ3 {
    background: #f2cd1f;
    border-radius: 100vmax;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: black;
    padding: 0px 6px;
    height: 20px;
    min-width: 20px;
    display: inline-block;
    margin-left: 8px;
  }

  .ProfileTimeline-module__carret___-1GVv8 {
    background: #f2cd1f;
    width: 51px;
    height: 5px;
  }
}

.ProfileTimeline-module__timelineContent___xSGq73 {
  display: flex;
  flex-direction: column;
  gap: 26px;
  margin-top: 20px;
  position: relative;
  padding-top: 20px;

  .ProfileTimeline-module__firstTimelineBar___tCay45 {
    position: absolute;
    top: 0px;
    margin-left: 5px;
    width: 2px;
    height: 35px;
    background: #f2cd1f;
    opacity: 38%;
  }

  &:has(.ProfileTimeline-module__timelineItem___rx6xZo:nth-of-type(2)) {
    .ProfileTimeline-module__timelineItem___rx6xZo {
      > div:first-of-type {
        height: calc(100% + 26px);
      }
    }

    .ProfileTimeline-module__timelineItem___rx6xZo:last-of-type {
      > div:first-of-type {
        height: 0px;
      }
    }
  }

  .ProfileTimeline-module__timelineItem___rx6xZo {
    display: flex;
    gap: 17px;
    position: relative;
  }
}
.ProfileShareLink-module__container___EE0SJe {
  display: flex;
  padding: 20px;
  align-items: center;
  gap: 30px;
  background: rgb(229, 243, 250);
  border-radius: 10px;

  > div {
    flex: 1 1;
  }

  .ProfileShareLink-module__titleAndDescription___xf0HLW {
    width: 43%;
    .ProfileShareLink-module__title___kwl9mJ {
      font-family: var(--slash-main);
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.15px;
      text-align: left;
      color: #00000099;
      margin-bottom: 4px;
    }

    .ProfileShareLink-module__description___d8Di0M {
      font-family: var(--slash-main);
      font-size: 14px;
      font-weight: 400;
      line-height: 20.02px;
      text-align: left;
      color: #00000099;
    }
  }

  .ProfileShareLink-module__linkContainer___LgUolD {
    min-width: 0px;
    display: flex;
    padding: 8px 12px 8px 12px;
    gap: 16px;
    border-radius: 8px;
    border: 1px solid #0000001f;
    background: white;
    align-items: center;
    gap: 16px;

    .ProfileShareLink-module__value___jAqlN8 {
      font-family: var(--slash-main);
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.17px;
      text-align: left;
      color: #00000099;
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .ProfileShareLink-module__button___Vw1Bqx {
      flex-shrink: 0;
    }
  }
}
.ProfileMatchingJobCompanies-module__listContainer___XH-g0A {
  margin-top: 10px;

  .ProfileMatchingJobCompanies-module__listHeader___um8rxF {
    border: 1px solid transparent;

    .ProfileMatchingJobCompanies-module__mainCell___m6qm4x {
      font-family: var(--slash-main);
      font-size: 14px;
      font-weight: 400;
      line-height: 23.24px;
      letter-spacing: 0.4px;
      text-align: left;
      color: #000000de;
    }
  }

  .ProfileMatchingJobCompanies-module__list___gpyXPi {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .ProfileMatchingJobCompanies-module__listHeader___um8rxF,
  .ProfileMatchingJobCompanies-module__listRow___HGSeY6 {
    display: flex;
    gap: 25px;
    align-items: center;
    padding: 10px 16px 10px 16px;
  }

  .ProfileMatchingJobCompanies-module__listRow___HGSeY6 {
    border-radius: 4px;
    border: 1px solid #0000001f;
  }

  .ProfileMatchingJobCompanies-module__rowActions___vbY0BR {
    display: flex;
    align-items: center;
    gap: 11px;
  }
}

.ProfileMatchingJobCompanies-module__mainCell___m6qm4x {
  flex-grow: 1;

  .ProfileMatchingJobCompanies-module__clientJob___3bA5pp {
    font-family: var(--slash-main);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: black;
    margin-right: 8px;
  }

  .ProfileMatchingJobCompanies-module__clientName___xLBGbH {
    font-family: var(--slash-main);
    font-size: 14px;
    font-weight: 400;
    line-height: 20.02px;
    text-align: left;
    color: #00000099;
  }
}
.ProfileMatchingJonRequisition-module__listContainer___WBAgmD {
  margin-top: 10px;

  .ProfileMatchingJonRequisition-module__listHeader___E8C43t {
    border: 1px solid transparent;

    .ProfileMatchingJonRequisition-module__mainCell___V2QG-R {
      font-family: var(--slash-main);
      font-size: 14px;
      font-weight: 400;
      line-height: 23.24px;
      letter-spacing: 0.4px;
      text-align: left;
      color: #000000de;
    }
  }

  .ProfileMatchingJonRequisition-module__list___SnFV4f {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .ProfileMatchingJonRequisition-module__listHeader___E8C43t,
  .ProfileMatchingJonRequisition-module__listRow___TzpYnG {
    display: flex;
    gap: 25px;
    align-items: center;
    padding: 10px 16px 10px 16px;
  }

  .ProfileMatchingJonRequisition-module__listRow___TzpYnG {
    border-radius: 4px;
    border: 1px solid #0000001f;
  }

  .ProfileMatchingJonRequisition-module__rowActions___gYO-xc {
    display: flex;
    align-items: center;
    gap: 11px;
  }
}

.ProfileMatchingJonRequisition-module__mainCell___V2QG-R {
  flex-grow: 1;

  .ProfileMatchingJonRequisition-module__clientJob___-U5MZ- {
    font-family: var(--slash-main);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: black;
    margin-right: 8px;
  }

  .ProfileMatchingJonRequisition-module__clientName___8kdMCs {
    font-family: var(--slash-main);
    font-size: 14px;
    font-weight: 400;
    line-height: 20.02px;
    text-align: left;
    color: #00000099;
  }
}
.ProfileFooter-module__footer___yeBEDk {
  display: flex;
  gap: 20px;
  margin-top: 20px;

  img {
    width: 100%;
  }

  > div {
    min-width: 0px;
    flex: 1 1;
  }
}
:root {
  --primary-cobalt-120: #0b1a63;
  --primary-cobalt-100: #1f2e77;
  --primary-cobalt-80: #4c5892;
  --primary-cobalt-60: #7982ad;
  --primary-cobalt-40: #a5abc9;
  --primary-cobalt-20: #d2d5e4;
  --primary-cobalt-10: #e9ebf2;
  --primary-cobalt-05: #f3f4f8;

  --primary-cobalt-op-06: #1f2e770f;

  --secondary-blue-120: #3450b5;
  --secondary-blue-100: #4864c9;
  --secondary-blue-80: #6d83d4;
  --secondary-blue-60: #91a2df;
  --secondary-blue-40: #b6c1e9;
  --secondary-blue-20: #dae0f4;
  --secondary-blue-10: #edf0fa;
  --secondary-blue-05: #f6f7fc;

  --secondary-sky-blue-120: #5c89eb;
  --secondary-sky-blue-100: #709dff;
  --secondary-sky-blue-80: #8db1ff;
  --secondary-sky-blue-60: #a9c4ff;
  --secondary-sky-blue-40: #c6d8ff;
  --secondary-sky-blue-20: #e2ebff;
  --secondary-sky-blue-10: #f1f6ff;
  --secondary-sky-blue-05: #f8faff;

  --secondary-dark-120: #000329;
  --secondary-dark-100: #10173d;
  --secondary-dark-80: #404564;
  --secondary-dark-60: #70748b;
  --secondary-dark-40: #9fa2b1;
  --secondary-dark-20: #cfd1d8;
  --secondary-dark-10: #e8e8ec;
  --secondary-dark-05: #f3f3f5;

  --primary-cobalt: var(--primary-cobalt-100);
  --secondary-blue: var(--secondary-blue-100);
  --secondary-sky-blue: var(--secondary-sky-blue-100);
  --secondary-dark: var(--secondary-dark-100);

  --slash-yellow: #f2cd1f;
  --slash-teal: #1a666e;
  --slash-chalk: #f5f5f5;
  --slash-light-grey: #e8e8ec;
  --slash-grey: #c4c4c4;
  --slash-dark-grey: #757575;
  --slash-black: #212121;
  --slash-shadow: #0000001f;
  --slash-orange: #ee734a;

  --secondary-red: #f66f81;
  --secondary-red-10: #fff1f3;
  --secondary-red-40: #fcc5cc;
  --secondary-red-60: #fba8b2;
  --secondary-red-80: #f98b99;
  --secondary-red-120: #e45a6b;
}
:root {
  --hiresweet-main: Graphik, sans-serif;
  --hiresweet-accent: Gilroy, sans-serif;
  --slash-main: 'Lexend Exa', sans-serif;
}
.ProfileComments-module__container___V2xdi9 {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.ProfileComments-module__comments___bTd3wa {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: normal;
}
.ProfileComments-module__count___XzVyv9 {
  height: 20px;
  padding: 0px 6px;
  font-size: 12px;
  line-height: 20px;
  border-radius: 20px;
  font-weight: 500;

  &.ProfileComments-module__slash___vUD28a {
    font-family: var(--slash-main);
    background: var(--slash-yellow);
    color: var(--slash-black);
  }
  &.ProfileComments-module__hiresweet___mKSzl6 {
    font-family: var(--hiresweet-main);
    background: var(--secondary-sky-blue-20);
    color: var(--secondary-blue);
  }
}
.ProfileComments-module__title___JnxHpz {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  &.ProfileComments-module__slash___vUD28a {
    font-family: var(--slash-main);
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
    text-align: left;
    text-transform: uppercase;
  }

  &.ProfileComments-module__hiresweet___mKSzl6 {
    font-size: 16px;
    font-weight: 700;
    color: var(--primary-cobalt);
    font-family: var(--hiresweet-main);
  }

  .ProfileComments-module__left___j1aEFo {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
.ProfileComments-module__separator___VwIbAk {
  width: 100%;
  height: 1px;
  margin: 8px 0px 10px;
  background: #0000001f;
}
.ProfileComments-module__seeMore___7U-Y0C {
  text-decoration: underline;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  cursor: pointer;
}
.ProfileComments-module__commentElement___Wv1K3n {
  background-color: white;
  padding: 6px 0;
  gap: 8px;
  display: flex;
  align-items: center;

  .ProfileComments-module__commentContent___dG2tIT {
    flex-grow: 1;

    &.ProfileComments-module__slash___vUD28a {
      font-family: var(--slash-main);
      color: var(--slash-black);

      .ProfileComments-module__commentAuthorAndDate___-kobLf {
        color: #aeaeae;
      }
    }
    &.ProfileComments-module__hiresweet___mKSzl6 {
      font-family: var(--hiresweet-main);
      color: var(--primary-cobalt);

      .ProfileComments-module__commentAuthorAndDate___-kobLf {
        color: var(--primary-cobalt-60);
      }
    }

    .ProfileComments-module__commentText___9B5gK3 {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: black;
      overflow: hidden;

      &.ProfileComments-module__smallComment___7EqCl2 {
        height: 22px;
      }
    }
  }
  .ProfileComments-module__commentAuthorAndDate___-kobLf {
    font-size: 13px;
    font-weight: 400;
    line-height: 18.5px;
    text-align: left;
  }
}
.ProfileComments-module__sidePanel___eDfBs9 {
  padding: 32px;
}
.ProfileComments-module__commentActions___tCcQMw {
  cursor: pointer;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  text-align: center;
  padding: 8px 8px 8px 9px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: var(--slash-light-grey);
  }
}
.ProfileComments-module__popupContent___nI3KKG {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  .ProfileComments-module__popupItem___jOZxwI {
    padding: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: black;
    cursor: pointer;

    &:first-of-type {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    &:last-of-type {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &.ProfileComments-module__slash___vUD28a {
      font-family: var(--slash-main);

      &:hover {
        background: var(--slash-yellow);
      }
    }
  }
}
.ProfileComments-module__modalContent___EoA8Lo {
  .ProfileComments-module__textarea___uxGOFR {
    width: 100%;
    padding: 8px;
    border: 1px solid #0000001f;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: black;
    resize: none;

    &.ProfileComments-module__slash___vUD28a {
      font-family: var(--slash-main);
    }
  }
}
.BreadCrumb-module__container___ulnEXB {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  .BreadCrumb-module__item___7iJ1kc {
    margin-right: 8px;
    cursor: pointer;

    &.BreadCrumb-module__slash___-oXB97 {
      font-family: var(--slash-main);
      color: var(--slash-dark-grey);
    }

    &.BreadCrumb-module__hiresweet___FJYt91 {
      font-family: var(--hiresweet-main);
      color: var(--primary-cobalt-60);
    }

    &:not(.BreadCrumb-module__active___LLUo20) {
      &:hover {
        &.BreadCrumb-module__slash___-oXB97 {
          color: var(--slash-black);
        }

        &.BreadCrumb-module__hiresweet___FJYt91 {
          color: var(--primary-cobalt);
        }
      }
    }

    &.BreadCrumb-module__active___LLUo20 {
      cursor: default;
      &.BreadCrumb-module__slash___-oXB97 {
        color: var(--slash-black);
      }

      &.BreadCrumb-module__hiresweet___FJYt91 {
        color: var(--primary-cobalt);
      }
    }

    &:not(:last-child) {
      &::after {
        content: '>';
        margin-left: 8px;

        &.BreadCrumb-module__slash___-oXB97 {
          color: var(--slash-black);
        }

        &.BreadCrumb-module__hiresweet___FJYt91 {
          color: var(--primary-cobalt);
        }
      }
    }
  }
}
.InlineFieldEditor-module__sharedFieldInlineContainer___fhTkYX {
  display: flex;
  align-items: center;
  gap: 8px;

  .InlineFieldEditor-module__editButton___0nWBbA {
    opacity: 0;
    transition: opacity 0.25s;
    color: var(--slash-dark-grey);
  }

  &:hover .InlineFieldEditor-module__editButton___0nWBbA {
    opacity: 1;
  }
}
.SharedSpaceProfilePanel-module__profileContainer___n4mpD1 {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: stretch;
}

.SharedSpaceProfilePanel-module__profileHeader___eBYNy5 {
  border-bottom: 1px solid var(--slash-light-grey);
  width: 100%;
  padding: 12px;

  .SharedSpaceProfilePanel-module__profileName___CzSx-z {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    color: var(--secondary-blue);
    font-family: var(--hiresweet-accent);
    font-size: 22px;
    line-height: 27px;
    font-weight: 700;
  }

  .SharedSpaceProfilePanel-module__profileAvatar___xP5vq9 {
    width: 60px;
    height: 60px;
  }

  .SharedSpaceProfilePanel-module__profileHeadline___hCysuW {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 12px;
    font-family: var(--hiresweet-main);
    font-weight: 500;
    margin-bottom: 0px;
    color: var(--primary-cobalt);
  }
}

.SharedSpaceProfilePanel-module__profileBody___1JaJa5 {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  height: -webkit-fill-available;
  min-height: 0;
}

.SharedSpaceProfilePanel-module__leftPane___deTE3C {
  flex: 1 1;
  padding: 24px;
  overflow-y: auto;
}

.SharedSpaceProfilePanel-module__rightPane___X3y1GQ {
  flex: 1 1;
  padding: 24px;
  background-color: var(--secondary-blue-05);
  border-left: 1px solid var(--secondary-sky-blue-20);
  overflow-y: auto;
}

.SharedSpaceProfilePanel-module__missionComment___p37uyQ {
  margin-bottom: 24px;
}

.SharedSpaceProfilePanel-module__sharedFieldsContainer___uAWxnq {
  padding: 16px;
  background: white;
  border-radius: 8px;
  border: 1px solid var(--secondary-sky-blue-20);

  .SharedSpaceProfilePanel-module__sharedFieldName___kyuome {
    margin-top: 18px;
    margin-bottom: 8px;
    font-family: var(--hiresweet-accent);
    color: var(--primary-cobalt);
    font-size: 16px;
    font-weight: 700;
  }
}

.SharedSpaceProfilePanel-module__resumeContainer___uJ-tFN {
  color: var(--primary-cobalt);
  font-family: var(--hiresweet-main);
  border-bottom: 1px solid var(--secondary-sky-blue-20);
  margin-bottom: 24px;
  padding-bottom: 24px;

  h4 {
    font-size: 16px;
    font-weight: 700;
    color: var(--primary-cobalt);
    font-family: var(--hiresweet-accent);
  }

  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--primary-cobalt-80);
  }
}
.ProfileExperiences-module__experienceContainer___3HxQu2 {
  border-bottom: 1px solid var(--secondary-sky-blue-20);
  margin-bottom: 24px;
  padding-bottom: 24px;
  color: var(--primary-cobalt);
  font-family: var(--hiresweet-main);

  h4 {
    font-size: 16px;
    font-weight: 700;
    color: var(--primary-cobalt);
    font-family: var(--hiresweet-accent);
  }
}
.ProfileEducationSections-module__infoContainer___87aBfp {
  display: flex;
  flex-direction: column;

  .ProfileEducationSections-module__educationItem___fJKEzH {
    display: flex;
    gap: 16px;
  }

  &.ProfileEducationSections-module__slash___MGfYP1 {
    margin-top: 25px;
    gap: 25px;
    font-family: var(--slash-main);

    .ProfileEducationSections-module__educationTitle___-hd3Kq {
      color: black;
      font-weight: 700;
      line-height: 18.2px;
    }

    .ProfileEducationSections-module__educationLocationAndDate___xTM4EG {
      color: var(--primary-cobalt-60);
    }

    .ProfileEducationSections-module__educationInfo___rxO2PE {
      gap: 8px;
    }
  }

  &.ProfileEducationSections-module__hiresweet___cfSpmS {
    font-family: Graphik, sans-serif;
    gap: 23px;

    .ProfileEducationSections-module__educationTitle___-hd3Kq {
      color: var(--primary-cobalt);
      font-family: Graphik, sans-serif;
      font-weight: 600;
      line-height: 20px;
    }

    .ProfileEducationSections-module__educationLocationAndDate___xTM4EG {
      color: var(--slash-dark-grey);
    }

    .ProfileEducationSections-module__educationInfo___rxO2PE {
      gap: 4px;
    }

    .ProfileEducationSections-module__companyName___Nd4Bjd {
      text-decoration: underline;
    }
  }

  .ProfileEducationSections-module__educationContainer___RG7qTF {
    display: flex;
    gap: 16px;

    .ProfileEducationSections-module__educationPicture___Z0DLts {
      height: 36px;
      width: 36px;
      background: #f5f5f5;
      flex-shrink: 0;

      &:has(img) {
        img {
          width: 36px;
          height: 36px;
          border-radius: 4px;
        }
      }

      &:has(svg) {
        padding: 8px 10px;
      }

      &:has(.ProfileEducationSections-module__defaultCompanyLogo___JWdpde) {
        text-align: center;
        background: #f6f7fc;

        svg {
          margin-top: calc(50% - 8px);
        }
      }
    }

    .ProfileEducationSections-module__educationInfo___rxO2PE {
      display: flex;
      flex-direction: column;

      .ProfileEducationSections-module__educationTitle___-hd3Kq {
        font-size: 14px;
        text-align: left;
      }

      .ProfileEducationSections-module__educationLocationAndDate___xTM4EG {
        font-size: 12px;
        font-weight: 400;
        line-height: 13px;
        text-align: left;
        color: var(--primary-cobalt-60);
      }

      .ProfileEducationSections-module__educationDescription___IEMCJn {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        text-align: left;
        color: var(--primary-cobalt-80);
      }
    }
  }

  .ProfileEducationSections-module__educationCard___v3FQDD {
    padding: 12px;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid #ebebeb;
    box-shadow: 0px 2px 4px 0px var(--primary-cobalt-op-06);

    .ProfileEducationSections-module__educationTitle___-hd3Kq {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: black;

      span {
        font-size: 13px;
        font-weight: 400;
        line-height: 18.5px;
        text-align: left;
        color: var(--slash-dark-grey);
      }
    }

    .ProfileEducationSections-module__educationLocationAndDate___xTM4EG {
      font-size: 13px;
      font-weight: 400;
      line-height: 18.5px;
      text-align: left;
      color: black;
    }
  }
}
.ProfileEducation-module__educationContainer___5Vjrir {
  border-bottom: 1px solid var(--secondary-sky-blue-20);
  margin-bottom: 24px;
  padding-bottom: 24px;
  color: var(--primary-cobalt);
  font-family: var(--hiresweet-main);

  h4 {
    font-size: 16px;
    font-weight: 700;
    color: var(--primary-cobalt);
    font-family: var(--hiresweet-accent);
  }
}
.MultiProjectSharedView-module__banner___ksmWsn {
  position: relative;
  display: flex;
  height: 64px;
  justify-content: center;
  align-items: center;
  padding: 12px;

  &.MultiProjectSharedView-module__slash___CpbZJl {
    font-family: var(--slash-main);
    color: var(--slash-black);
  }

  &.MultiProjectSharedView-module__hiresweet___9FRdpe {
    font-family: var(--hiresweet-main);
    color: white;
    background-color: #02142e;
  }

  .MultiProjectSharedView-module__companyLogo___AG--oF {
    position: absolute;
    height: 24px;
    top: 50%;
    left: 24px;
    transform: translateY(-50%);
  }

  .MultiProjectSharedView-module__title___luI-zx {
    font-size: 24px;
    font-weight: 600;
  }
}

.MultiProjectSharedView-module__content___ZlDLB5 {
  padding: 24px;
  border: 1px solid var(--slash-light-grey);
}

.MultiProjectSharedView-module__anchor___zCckM3 {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &.MultiProjectSharedView-module__hiresweet___9FRdpe {
    font-family: var(--hiresweet-main);
    color: var(--primary-cobalt-60);

    &:hover {
      color: var(--secondary-blue);
    }
  }

  &.MultiProjectSharedView-module__slash___CpbZJl {
    font-family: var(--slash-main);
    color: var(--slash-dark-grey);

    &:hover {
      color: var(--slash-teal);
    }
  }
}

.MultiProjectSharedView-module__profileContainer___zaIUmh {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.MultiProjectSharedView-module__profileHeader___PJ9tmB {
  border-bottom: 1px solid var(--slash-light-grey);
  width: 100%;
  padding: 12px;

  .MultiProjectSharedView-module__profileName___DnSu8- {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
  }

  .MultiProjectSharedView-module__profileHeadline___v-H0I7 {
    margin-top: 8px;
  }
}

.MultiProjectSharedView-module__profileBody___OFdBs6 {
  display: flex;
  flex-direction: row;
  gap: 24px;
  flex-grow: 1;
}

.MultiProjectSharedView-module__leftPane___AaTrZe {
  flex: 1 1;
  padding: 12px;
}

.MultiProjectSharedView-module__rightPane___N8Ubgn {
  flex: 1 1;
  padding: 12px;
  background-color: rgb(240, 240, 240);
}

.MultiProjectSharedView-module__icon___9bS83e {
  svg {
    width: 18px;
    height: 18px;
  }
}

.MultiProjectSharedView-module__fullname___okbxYr {
  font-weight: 500;
  color: var(--secondary-blue);
}

.MultiProjectSharedView-module__avatarColumnHeader___WpnT-2,
.MultiProjectSharedView-module__avatarColumn___0Eb7lW {
  min-width: 32px;
  flex-grow: 0;
}
:root {
  --primary-cobalt-120: #0b1a63;
  --primary-cobalt-100: #1f2e77;
  --primary-cobalt-80: #4c5892;
  --primary-cobalt-60: #7982ad;
  --primary-cobalt-40: #a5abc9;
  --primary-cobalt-20: #d2d5e4;
  --primary-cobalt-10: #e9ebf2;
  --primary-cobalt-05: #f3f4f8;

  --primary-cobalt-op-06: #1f2e770f;

  --secondary-blue-120: #3450b5;
  --secondary-blue-100: #4864c9;
  --secondary-blue-80: #6d83d4;
  --secondary-blue-60: #91a2df;
  --secondary-blue-40: #b6c1e9;
  --secondary-blue-20: #dae0f4;
  --secondary-blue-10: #edf0fa;
  --secondary-blue-05: #f6f7fc;

  --secondary-sky-blue-120: #5c89eb;
  --secondary-sky-blue-100: #709dff;
  --secondary-sky-blue-80: #8db1ff;
  --secondary-sky-blue-60: #a9c4ff;
  --secondary-sky-blue-40: #c6d8ff;
  --secondary-sky-blue-20: #e2ebff;
  --secondary-sky-blue-10: #f1f6ff;
  --secondary-sky-blue-05: #f8faff;

  --secondary-dark-120: #000329;
  --secondary-dark-100: #10173d;
  --secondary-dark-80: #404564;
  --secondary-dark-60: #70748b;
  --secondary-dark-40: #9fa2b1;
  --secondary-dark-20: #cfd1d8;
  --secondary-dark-10: #e8e8ec;
  --secondary-dark-05: #f3f3f5;

  --primary-cobalt: var(--primary-cobalt-100);
  --secondary-blue: var(--secondary-blue-100);
  --secondary-sky-blue: var(--secondary-sky-blue-100);
  --secondary-dark: var(--secondary-dark-100);

  --slash-yellow: #f2cd1f;
  --slash-teal: #1a666e;
  --slash-chalk: #f5f5f5;
  --slash-light-grey: #e8e8ec;
  --slash-grey: #c4c4c4;
  --slash-dark-grey: #757575;
  --slash-black: #212121;
  --slash-shadow: #0000001f;
  --slash-orange: #ee734a;

  --secondary-red: #f66f81;
  --secondary-red-10: #fff1f3;
  --secondary-red-40: #fcc5cc;
  --secondary-red-60: #fba8b2;
  --secondary-red-80: #f98b99;
  --secondary-red-120: #e45a6b;
}

.SnoozeTaskForm-module__snoozeModal___WX4Twy {
  width: 100%;
  background: white;
}

.SnoozeTaskForm-module__title___b1JULx {
  display: block;
  font-size: 14px;
  &.SnoozeTaskForm-module__hiresweet___oWiNYS {
    font-family: var(--hiresweet-main);
    color: var(--primary-cobalt);
    font-weight: 500;
  }
  &.SnoozeTaskForm-module__slash___yW-zR6 {
    font-family: var(--slash-main);
    color: var(--slash-black);
    font-weight: 700;
  }
}

.SnoozeTaskForm-module__buttons___iDzl-G {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  &:not(last-of-type) {
    margin-bottom: 12px;
  }
}

.SnoozeTaskForm-module__button___vhbZIf {
  flex: 1;
}

.SnoozeTaskForm-module__comment___KENx7C {
  margin: 10px 0 12px 0;
  width: 100%;
}

.SnoozeTaskForm-module__datePickerWrapper___JZofOQ {
  display: flex;
  justify-content: center;
}

.SnoozeTaskForm-module__confirm___bnI-yY {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;
  gap: 10px;
}

.SnoozeTaskForm-module__prediction___OLCAEl {
  font-size: 12px;
  &.SnoozeTaskForm-module__hiresweet___oWiNYS {
    font-family: var(--hiresweet-main);
    color: var(--primary-cobalt-60);
  }
  &.SnoozeTaskForm-module__slash___yW-zR6 {
    font-family: var(--slash-main);
    color: var(--slash-dark-grey);
  }
}
.SnoozeTaskButton-module__button___AaklME {
  width: 100%;
}

.SnoozeTaskButton-module__panel___-SFjQM {
  width: 100%;
  padding: 12px;
}

.SnoozeTaskButton-module__dropdownContent___JOcTrH {
  left: 0;
  right: 0;
}
:root {
  --primary-cobalt-120: #0b1a63;
  --primary-cobalt-100: #1f2e77;
  --primary-cobalt-80: #4c5892;
  --primary-cobalt-60: #7982ad;
  --primary-cobalt-40: #a5abc9;
  --primary-cobalt-20: #d2d5e4;
  --primary-cobalt-10: #e9ebf2;
  --primary-cobalt-05: #f3f4f8;

  --primary-cobalt-op-06: #1f2e770f;

  --secondary-blue-120: #3450b5;
  --secondary-blue-100: #4864c9;
  --secondary-blue-80: #6d83d4;
  --secondary-blue-60: #91a2df;
  --secondary-blue-40: #b6c1e9;
  --secondary-blue-20: #dae0f4;
  --secondary-blue-10: #edf0fa;
  --secondary-blue-05: #f6f7fc;

  --secondary-sky-blue-120: #5c89eb;
  --secondary-sky-blue-100: #709dff;
  --secondary-sky-blue-80: #8db1ff;
  --secondary-sky-blue-60: #a9c4ff;
  --secondary-sky-blue-40: #c6d8ff;
  --secondary-sky-blue-20: #e2ebff;
  --secondary-sky-blue-10: #f1f6ff;
  --secondary-sky-blue-05: #f8faff;

  --secondary-dark-120: #000329;
  --secondary-dark-100: #10173d;
  --secondary-dark-80: #404564;
  --secondary-dark-60: #70748b;
  --secondary-dark-40: #9fa2b1;
  --secondary-dark-20: #cfd1d8;
  --secondary-dark-10: #e8e8ec;
  --secondary-dark-05: #f3f3f5;

  --primary-cobalt: var(--primary-cobalt-100);
  --secondary-blue: var(--secondary-blue-100);
  --secondary-sky-blue: var(--secondary-sky-blue-100);
  --secondary-dark: var(--secondary-dark-100);

  --slash-yellow: #f2cd1f;
  --slash-teal: #1a666e;
  --slash-chalk: #f5f5f5;
  --slash-light-grey: #e8e8ec;
  --slash-grey: #c4c4c4;
  --slash-dark-grey: #757575;
  --slash-black: #212121;
  --slash-shadow: #0000001f;
  --slash-orange: #ee734a;

  --secondary-red: #f66f81;
  --secondary-red-10: #fff1f3;
  --secondary-red-40: #fcc5cc;
  --secondary-red-60: #fba8b2;
  --secondary-red-80: #f98b99;
  --secondary-red-120: #e45a6b;
}

.ExplicitManualTaskExecutionForm-module__title___np--pG {
  &.ExplicitManualTaskExecutionForm-module__slash___6ZWszA {
    font-family: Lexend Exa;
    font-size: 12px;
    font-weight: 800;
    text-transform: uppercase;
    color: black;
  }
  &.ExplicitManualTaskExecutionForm-module__hiresweet___B3ObkI {
    font-family: Graphik;
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-cobalt);
  }
}

.ExplicitManualTaskExecutionForm-module__buttons___UcfYNA {
  margin: 8px 0;
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr;
}

.ExplicitManualTaskExecutionForm-module__twoColumns___ZMO0S6 {
  grid-column: 1 / span 2;
}

.ExplicitManualTaskExecutionForm-module__fullSizeButton___svso8Q {
  width: 100%;
}

.ExplicitManualTaskExecutionForm-module__description___i6fThn {
  margin: 8px 0;
  &.ExplicitManualTaskExecutionForm-module__slash___6ZWszA {
    font-family: Lexend Exa;
  }
}
.Card-module__card___t-Edb7 {
  background-color: white;
  &.Card-module__slash___-9lN-a {
    padding: 20px;
    border-radius: 4px;
  }
  &.Card-module__hiresweet___6TPUQ9 {
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 16px var(--primary-cobalt-op-06);
  }
}
.ProfileTasksForms-module__card___IXIPag:not(:last-child) {
  margin-bottom: 16px;
}
.ProfilePillsList-module__icon___SHhxhY {
  width: 24px;
  height: 24px;
  opacity: 0.7;
}

.ProfilePillsList-module__qualificationContainer___s-TALW {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
}

.ProfilePillsList-module__qualificationValue___Q7D6pS {
  margin: 0px 6px;
}
.ProfileDocuments-module__container___Ek8Oh1 {
  .ProfileDocuments-module__title___OUoDoF {
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  .ProfileDocuments-module__caret___WeO0Px {
    width: 51px;
    height: 5px;
    background: #f2cd1f;
  }

  .ProfileDocuments-module__infoContainer___8fVgng {
    display: flex;
    gap: 16px;
    flex-direction: column;

    .ProfileDocuments-module__info___OrfGH1 {
      display: flex;
      align-items: center;

      .ProfileDocuments-module__infoTitle___MNvoLC {
        font-size: 14px;
        font-weight: 400;
        line-height: 20.02px;
        text-align: left;
        color: #757575;
        width: 200px;
      }

      .ProfileDocuments-module__infoValue___4JhmUw {
        font-size: 14px;
        font-weight: 500;
        line-height: 20.02px;
        text-align: left;
        color: black;
      }
    }
  }

  .ProfileDocuments-module__documentsContainer___dLZO7q {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 25px;

    .ProfileDocuments-module__documentRow___ludvV3 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 68px;
      padding: 16px 22px;
      border: 1px solid #0000001f;
      background: white;

      &.ProfileDocuments-module__slash___xcsLIM {
        border-radius: 4px;
      }

      &.ProfileDocuments-module__hiresweet___TKCnEH {
        border-radius: 8px;
      }

      .ProfileDocuments-module__documentInfos___EyehUo {
        display: flex;
        align-items: center;
        gap: 8px;

        .ProfileDocuments-module__documentIcon___pmlZaC {
          margin-right: 13px;
        }

        .ProfileDocuments-module__documentTitle___--IXVy {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;

          &.ProfileDocuments-module__slash___xcsLIM {
            font-family: var(--slash-main);
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
            color: black;
          }

          &.ProfileDocuments-module__hiresweet___TKCnEH {
            font-family: var(--hiresweet-main);
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
            color: var(--primary-cobalt);
          }
        }

        .ProfileDocuments-module__documentDate___dqaQZo {
          font-size: 14px;
          font-weight: 400;
          line-height: 20.02px;
          text-align: left;

          &.ProfileDocuments-module__slash___xcsLIM {
            font-family: var(--slash-main);
            color: black;
          }

          &.ProfileDocuments-module__hiresweet___TKCnEH {
            font-family: var(--hiresweet-main);
            color: var(--primary-cobalt);
          }
        }
      }

      .ProfileDocuments-module__documentActions___uKA-oH {
        display: flex;
        align-items: center;
        gap: 28px;

        .ProfileDocuments-module__actionsRight___WfPjee {
          display: flex;
          align-items: center;
          gap: 21px;
        }

        .ProfileDocuments-module__documentDownload___t8z5t4 {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 7px 12px;
          font-size: 13px;
          font-weight: 500;
          line-height: 22px;
          text-align: left;
          cursor: pointer;

          &.ProfileDocuments-module__slash___xcsLIM {
            border-radius: 4px;
            border: 1px solid #00000099;
          }

          &.ProfileDocuments-module__hiresweet___TKCnEH {
            border-radius: 8px;
            color: var(--secondary-blue);
            border: 1px solid var(--secondary-blue);
          }
        }
      }
    }
  }
}

.ProfileDocuments-module__documentShow___3VBoBp,
.ProfileDocuments-module__moreActions___ZEhrsq {
  cursor: pointer;
}

.ProfileDocuments-module__popupContent___tN41Ex {
  &.ProfileDocuments-module__slash___xcsLIM {
    display: flex;
    flex-direction: column;
    gap: 6px;
    overflow: hidden;
    background: white;
    border-radius: 8px;
    box-shadow: 0px 3px 11px 0px #00000014;
  }

  &.ProfileDocuments-module__hiresweet___TKCnEH {
    display: flex;
    flex-direction: column;
    gap: 6px;
    overflow: hidden;
    border: 2px solid #c6d8ff;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0px 2px 16px 0px #1f2e7726;
  }

  .ProfileDocuments-module__popupItem___SmOS99 {
    cursor: pointer;
    &.ProfileDocuments-module__slash___xcsLIM {
      padding: 12px 16px;
      font-family: var(--slash-main);
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      text-align: left;
      color: black;
    }

    &.ProfileDocuments-module__hiresweet___TKCnEH {
      display: block;
      width: 100%;
      padding: 6px 12px;
      line-height: 140%;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
      font-family: Graphik;
      font-size: 14px;
      text-align: left;
      color: #1f2e77;
      font-weight: 400;

      &:first-of-type {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }

      &:hover {
        background-color: #f6f7fc;
      }
    }

    .ProfileDocuments-module__menuIcon___BNkOkL {
      margin-right: 8px;
      width: 1em;
      height: 1em;
      vertical-align: text-top;
      color: #7982ad;
    }

    &:hover {
      background-color: var(--slash-light-grey);
    }
  }
}

.ProfileDocuments-module__pageSelector___3-1R71 {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 16px;

  .ProfileDocuments-module__pageNumber___msD2CP {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: var(--slash-dark-grey);
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    text-align: center;
    color: white;
  }

  .ProfileDocuments-module__currentPage___w-soLa {
    background: var(--slash-yellow);
  }
}

.ProfileDocuments-module__addResume___EXEWx0 {
  display: flex;
  align-items: center;
  padding: 18px 20px 18px 20px;
  gap: 30px;
  border-radius: 10px;
  background: rgb(229, 243, 250);

  .ProfileDocuments-module__addResumeTitle___-ZdZJi {
    padding: 8px 0px;
    flex-grow: 1;
    font-family: var(--slash-main);
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.15px;
  }
}

.ProfileDocuments-module__fileInputModalContent___UgPWQ- {
  padding: 12px 0px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.PDFEditor-module__modal___8rNyHt {
  z-index: 10;
}

.PDFEditor-module__modalContent___L37rwa {
  &.PDFEditor-module__hiresweet___xDky-8 {
    padding: 0 !important;
  }
}

.PDFEditor-module__modalFooter___dT4jum {
  &.PDFEditor-module__hiresweet___xDky-8 {
    border-top: 1px solid var(--secondary-blue-20);
  }
}

.PDFEditor-module__container___mzFAoM {
  /* position: relative;
  margin: auto;
  width: fit-content; */
  display: flex;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
  height: calc(90svh - 156px);

  .PDFEditor-module__editor___7xOwSH {
    position: relative;
    overflow-y: auto;
    height: inherit;
    display: flex;
    box-sizing: border-box;
    background: var(--slash-chalk);
    padding: 12px;

    .PDFEditor-module__pdfRender___ewEKTR {
      /* position: relative; */
      z-index: 1;
      pointer-events: none;
      user-select: none;
      width: fit-content;
      height: fit-content;
    }

    .PDFEditor-module__canvas___mUoNuZ {
      position: absolute;
      left: 12px;
      top: 12px;
      z-index: 2;
      background: transparent;
    }
  }

  .PDFEditor-module__deleteButton___JschCr {
    position: absolute;
    z-index: 3;
    width: auto;
    padding: 8px;
    aspect-ratio: 1/1;
    text-align: center;
    border-radius: 50%;
    padding: 0;

    .PDFEditor-module__svg___UabCag {
      margin: auto;
    }
  }
}

.PDFEditor-module__controls___NW0wPX {
  display: flex;
  align-items: flex-end;
  gap: 8px;
  flex-grow: 1;
  justify-content: center;

  .PDFEditor-module__zoomButton___e9dI0Q {
    border-radius: 100vmax;
    width: 24px;
    height: 24px;
    user-select: none;
  }

  .PDFEditor-module__sliderContainer___b05-EA {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    justify-content: center;

    .PDFEditor-module__sizeValue___ljlB5s {
      font-size: 14px;

      &.PDFEditor-module__slash___TsboCF {
        color: var(--slash-dark-grey);
        font-family: var(--slash-main);
      }

      &.PDFEditor-module__hiresweet___xDky-8 {
        color: var(--slash-dark-grey);
        font-family: var(--hiresweet-main);
      }
    }

    .PDFEditor-module__slider___Po8gbb {
      -webkit-appearance: none;
      background: transparent;
    }

    .PDFEditor-module__slider___Po8gbb::-webkit-slider-runnable-track {
      border: none;
      width: 100%;
      cursor: pointer;
      height: 8px;
      background: var(--slash-light-grey);
      border-radius: 100vmax;
    }

    .PDFEditor-module__slider___Po8gbb::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 16px;
      height: 16px;
      border-radius: 100vmax;
      cursor: pointer;
      margin-top: -4px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    }

    .PDFEditor-module__slider___Po8gbb.PDFEditor-module__slash___TsboCF::-webkit-slider-thumb {
      background: var(--slash-yellow);
    }
    .PDFEditor-module__slider___Po8gbb.PDFEditor-module__hiresweet___xDky-8::-webkit-slider-thumb {
      background: var(--secondary-blue);
    }

    .PDFEditor-module__slider___Po8gbb:focus {
      &.PDFEditor-module__slash___TsboCF {
        outline: 1px solid var(--slash-yellow);
      }
      &.PDFEditor-module__hiresweet___xDky-8 {
        outline: 1px solid var(--secondary-blue);
      }
    }

    .PDFEditor-module__slider___Po8gbb::-ms-track {
      width: 100%;
      cursor: pointer;
      /* Hides the slider so custom styles can be added */
      background: transparent;
      border-color: transparent;
      color: transparent;
    }

    .PDFEditor-module__slider___Po8gbb.PDFEditor-module__slash___TsboCF::-ms-fill-lower {
      background: var(--slash-yellow);
    }
    .PDFEditor-module__slider___Po8gbb.PDFEditor-module__hiresweet___xDky-8::-ms-fill-lower {
      &.PDFEditor-module__hiresweet___xDky-8 {
        background: var(--secondary-blue);
      }
    }
  }
}

.PDFEditor-module__footer___YbPlmJ {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
}

.PDFEditor-module__sidebarContainer___6oypl- {
  min-width: 250px;
  position: relative;
  border-left: 1px solid var(--slash-light-grey);
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  height: inherit;
  box-sizing: content-box;

  &.PDFEditor-module__slash___TsboCF {
    padding: 0px 12px;
  }

  &.PDFEditor-module__hiresweet___xDky-8 {
    padding: 12px;
  }

  * {
    box-sizing: border-box;
  }

  .PDFEditor-module__sidebar___hCKrsp {
    min-width: inherit;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 12px;

    h3 {
      margin: 0;
    }

    p {
      margin: 0;
    }

    .PDFEditor-module__rectangleList___pCyaWZ {
      height: calc(24px * 6);
      overflow-y: auto;
      width: inherit;
      border-radius: 8px;
      overflow: hidden;

      &:not(:empty) {
        border: 1px solid var(--slash-light-grey);
      }

      .PDFEditor-module__rectangleListItem___7ydM0d {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 4px 8px;
        cursor: pointer;
        border: 1px solid transparent;
        background: var(--slash-chalk);

        &:first-of-type {
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        }

        &.PDFEditor-module__itemHovered___ReRc17,
        &:hover {
          &.PDFEditor-module__slash___TsboCF {
            border: 1px solid var(--slash-yellow);
          }
          &.PDFEditor-module__hiresweet___xDky-8 {
            border: 1px solid var(--secondary-blue);
          }
        }

        &.PDFEditor-module__itemSelected___ck8gKh {
          &.PDFEditor-module__slash___TsboCF {
            border: 1px solid var(--slash-yellow);
            background: var(--slash-yellow);
          }
          &.PDFEditor-module__hiresweet___xDky-8 {
            border: 1px solid var(--secondary-blue);
            color: white;
            background: var(--secondary-blue);
          }
        }
      }
    }

    .PDFEditor-module__rectangleSettings___F50d1a {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .PDFEditor-module__rectangleSetting___9orW7K {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      .PDFEditor-module__colorList___ZHMRN8 {
        display: flex;
        gap: 4px;
        flex-wrap: nowrap;
        overflow-x: auto;

        .PDFEditor-module__color___NmJJwa {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          border: 1px solid var(--slash-light-grey);
          cursor: pointer;

          &.PDFEditor-module__selected___Pwj8M8 {
            &.PDFEditor-module__slash___TsboCF {
              border: 2px solid var(--slash-yellow);
            }
            &.PDFEditor-module__hiresweet___xDky-8 {
              border: 2px solid var(--secondary-blue);
            }
          }
        }
      }

      .PDFEditor-module__nameInput___OvEiUv {
        width: 100%;
        padding: 8px;
        height: auto;
      }

      .PDFEditor-module__textarea___4wndzZ {
        border: 1px solid var(--slash-light-grey);
        border-radius: 8px;
        padding: 8px;
        resize: vertical;
        font-size: 12px;
        width: 100%;

        &.PDFEditor-module__slash___TsboCF {
          font-family: var(--slash-main);
        }

        &.PDFEditor-module__hiresweet___xDky-8 {
          font-family: var(--hiresweet-main);
        }
      }
    }
  }
}

.PDFEditor-module__tooltip___Kkh-Mc {
  width: 14px;
  height: 14px;
  text-align: center;
  padding: 0px 0px;
  line-height: 12px;
  font-size: 12px;
  border-radius: 50%;
  border: 1px solid var(--slash-dark-grey);
  margin: 1px 4px;
  color: var(--slash-dark-grey);
  cursor: pointer;
}

.PDFEditor-module__tooltipContent___FvJyIg {
  padding: 12px;
  width: 250px;
  transform: translateX(70px);
  border-radius: 8px;
  border: 1px solid var(--slash-light-grey);
  box-shadow: 0 2px 4px var(--slash-shadow);
  pointer-events: none;
  background: white;
}

.PDFEditor-module__rectangleTextTitle___qDe4W4 {
  display: flex;
  align-items: flex-end;
}

.PDFEditor-module__loader___rAyGc0 {
  position: absolute;
  background: rgba(255, 255, 255, 0.7);
  z-index: 10;

  &.PDFEditor-module__slash___TsboCF {
    height: calc(100% - 152px);
    width: calc(100% - 72px);
  }

  &.PDFEditor-module__hiresweet___xDky-8 {
    height: calc(100% - 135px);
    width: calc(100%);
  }

  center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.FileInput-module__fileInput___bpaMZm {
  text-align: center;
  width: 100%;
  border: 1px dashed #0000003b;
  padding: 32px;
  border-radius: 12px;
  box-sizing: border-box;
  color: var(--slash-dark-grey);

  .FileInput-module__fileInputButton___uF6w8L {
    text-transform: uppercase;
  }
}
:root {
  --primary-cobalt-120: #0b1a63;
  --primary-cobalt-100: #1f2e77;
  --primary-cobalt-80: #4c5892;
  --primary-cobalt-60: #7982ad;
  --primary-cobalt-40: #a5abc9;
  --primary-cobalt-20: #d2d5e4;
  --primary-cobalt-10: #e9ebf2;
  --primary-cobalt-05: #f3f4f8;

  --primary-cobalt-op-06: #1f2e770f;

  --secondary-blue-120: #3450b5;
  --secondary-blue-100: #4864c9;
  --secondary-blue-80: #6d83d4;
  --secondary-blue-60: #91a2df;
  --secondary-blue-40: #b6c1e9;
  --secondary-blue-20: #dae0f4;
  --secondary-blue-10: #edf0fa;
  --secondary-blue-05: #f6f7fc;

  --secondary-sky-blue-120: #5c89eb;
  --secondary-sky-blue-100: #709dff;
  --secondary-sky-blue-80: #8db1ff;
  --secondary-sky-blue-60: #a9c4ff;
  --secondary-sky-blue-40: #c6d8ff;
  --secondary-sky-blue-20: #e2ebff;
  --secondary-sky-blue-10: #f1f6ff;
  --secondary-sky-blue-05: #f8faff;

  --secondary-dark-120: #000329;
  --secondary-dark-100: #10173d;
  --secondary-dark-80: #404564;
  --secondary-dark-60: #70748b;
  --secondary-dark-40: #9fa2b1;
  --secondary-dark-20: #cfd1d8;
  --secondary-dark-10: #e8e8ec;
  --secondary-dark-05: #f3f3f5;

  --primary-cobalt: var(--primary-cobalt-100);
  --secondary-blue: var(--secondary-blue-100);
  --secondary-sky-blue: var(--secondary-sky-blue-100);
  --secondary-dark: var(--secondary-dark-100);

  --slash-yellow: #f2cd1f;
  --slash-teal: #1a666e;
  --slash-chalk: #f5f5f5;
  --slash-light-grey: #e8e8ec;
  --slash-grey: #c4c4c4;
  --slash-dark-grey: #757575;
  --slash-black: #212121;
  --slash-shadow: #0000001f;
  --slash-orange: #ee734a;

  --secondary-red: #f66f81;
  --secondary-red-10: #fff1f3;
  --secondary-red-40: #fcc5cc;
  --secondary-red-60: #fba8b2;
  --secondary-red-80: #f98b99;
  --secondary-red-120: #e45a6b;
}

.HiresweetTasks-module__container___Nl9Xc9 {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 3fr 2fr;
  min-height: 100%;
}

.HiresweetTasks-module__header___zVFT6p {
  grid-row: 1;
  grid-column: 1 / span 2;
  padding: 20px;
  &.HiresweetTasks-module__slash___Zxsq-E {
    border-bottom: 1px solid var(--slash-light-grey);
  }
  &.HiresweetTasks-module__hiresweet___Tt3tDr {
    border-bottom: 1px solid var(--secondary-blue-20);
  }
}

.HiresweetTasks-module__cell___BSnLcM {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 8px;
}

.HiresweetTasks-module__left___vZZhC9 {
  grid-row: 2;
  grid-column: 1;
  padding: 20px;
}

.HiresweetTasks-module__right___dnZ2fS {
  grid-row: 2;
  grid-column: 2;
  padding: 20px;
  min-width: 600px;
  &.HiresweetTasks-module__slash___Zxsq-E {
    background-color: var(--slash-chalk);
    border-left: 1px solid var(--slash-light-grey);
  }
  &.HiresweetTasks-module__hiresweet___Tt3tDr {
    background-color: var(--secondary-blue-05);
    border-left: 1px solid var(--secondary-blue-20);
  }
}
.ProfileQualificationListCompact-module__qualificationContainer___GfRGpW {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.ProfileQualificationListCompact-module__qualificationValue___YQxSk- {
  margin: 0px;
}

.ProfileQualificationListCompact-module__qualificationsTooltip___mWYO-1 {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px 12px;
  background-color: white;
  border-radius: 8px;
  min-width: fit-content;
  white-space: nowrap;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.ProfileQualificationListCompact-module__tooltipTrigger___Ynz-Ia {
  cursor: pointer;
  padding: 4px 6px;

  .ProfileQualificationListCompact-module__qualificationMore___6r2KAE {
    color: var(--slash-dark-grey);
  }
}
.BulkSendMessageButton-module__container___H5DkaV {
  padding: 20px;
}

.BulkSendMessageButton-module__select___QXZQHj {
  display: block;
  width: 100%;
  margin-bottom: 8px;
}

.BulkSendMessageButton-module__textEditor___g9Dzh7 {
  border: 1px solid grey;
  width: 100%;
  height: 100px;
}.TableLocation-module__tableLocation___twfq1J {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &.TableLocation-module__slash___CpEPx8 .TableLocation-module__city___Q9vngQ {
    font-family: var(--slash-main);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.4px;
    text-align: left;
    color: #000000de;
    text-transform: uppercase;
  }

  &.TableLocation-module__slash___CpEPx8 .TableLocation-module__postalCode___DB2MDq {
    font-family: var(--slash-main);
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.4px;
    text-align: left;
    color: #aaaaaaff;
  }
}
.FrenchLocationSelectorWithRadius-module__frenchLocationSelectorWithRadiusContainer___iyfsTF {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
}

.FrenchLocationSelectorWithRadius-module__confirmButton___YGXYTQ {
  align-self: flex-end;
}
.HiresweetProfiles-module__hat___SDFDC- {
  display: flex;
  align-items: center;
  &.HiresweetProfiles-module__hiresweet___enDPP9 {
    padding: 16px;
    gap: 8px;
  }
  &.HiresweetProfiles-module__slash___i4yHHh {
    justify-content: space-between;
    padding: 12px;
    gap: 12px;
    background: #e8e8e8;

    .HiresweetProfiles-module__left___OOsabh,
    .HiresweetProfiles-module__right___n1NyJ5 {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .HiresweetProfiles-module__qualificationsSelect___I6DY3W {
      max-width: 300px;
    }
    .HiresweetProfiles-module__selectContainer___zJf5eB {
      width: auto;
    }

    .HiresweetProfiles-module__agencySelect___l0aEAN {
      min-width: 200px;
    }
  }
}

.HiresweetProfiles-module__collar___ozJ-LG {
  display: flex;
  align-items: center;
  &.HiresweetProfiles-module__hiresweet___enDPP9 {
    padding: 16px;
    gap: 8px;
  }
  &.HiresweetProfiles-module__slash___i4yHHh {
    padding: 12px;
    gap: 12px;
  }
}

.HiresweetProfiles-module__container___dGUoEG.HiresweetProfiles-module__slash___i4yHHh {
  border-radius: 10px;
  padding: 22px;
  box-shadow: 0px 3px 11px 0px #00000014;
  background-color: white;
}

.HiresweetProfiles-module__selectedCandidates___QvW6kQ.HiresweetProfiles-module__slash___i4yHHh {
  font-family: var(--slash-main);
  font-size: 14px;
  font-weight: 400;
  line-height: 23.24px;
  letter-spacing: 0.4px;
  text-align: left;
  color: #000000de;
}

.HiresweetProfiles-module__popupFilter___TKSKBL {
  &.HiresweetProfiles-module__slash___i4yHHh {
    height: 56px;
    border-radius: 30px;
    padding: 0px 20px;
    border: 1px solid #0000003b;
    display: flex;
    align-items: center;
    min-width: 200px;
    background-color: white;
    font-family: var(--slash-main);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-align: left;
    white-space: nowrap;

    .HiresweetProfiles-module__popupFilterTriggerValue___v-5qXU {
      color: var(--slash-black);
    }

    .HiresweetProfiles-module__popupFilterTriggerPlaceholder___9vd68J {
      color: var(--slash-grey);
    }
  }
}

.HiresweetProfiles-module__popupFilterContent___sYbpGn {
  &.HiresweetProfiles-module__slash___i4yHHh {
    min-width: 300px;
    padding: 12px;
    border-radius: 10px;
    box-shadow: 0px 3px 11px 0px #00000014;
    background-color: white;
    font-family: var(--slash-main);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-align: left;
    color: var(--slash-grey);
  }
}

.HiresweetProfiles-module__nameAndNumberContainer___m1Xcfd {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .HiresweetProfiles-module__phoneNumber___QOOLL1 {
    color: var(--slash-grey);
    font-size: 12px;
  }
}
.FormInputStars-module__container___KIeCkU {
  display: flex;
  flex-direction: column;
  gap: 8px;
}.Stars-module__container___bxPZ1Z {
  display: flex;
  align-items: center;
  gap: 5px;

  .Stars-module__star___UN7sGk {
    color: var(--slash-dark-grey);
    &.Stars-module__active___ULXTnb {
      color: var(--slash-yellow);
    }

    &.Stars-module__clickable___Mg6OQL {
      cursor: pointer;
    }
  }
}
.ProjectCreationModal-module__form___7tI4Z5 {
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  .ProjectCreationModal-module__dualField___xBmNtL {
    display: flex;
    gap: 30px;
  }
}
.ProjectSettingsGeneral-module__container___hIqeTH {
  position: relative;

  .ProjectSettingsGeneral-module__content___mhuyJH {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 25px;

    .ProjectSettingsGeneral-module__contentItem___HUVpeS {
      &.ProjectSettingsGeneral-module__slash___7qBEvk {
        .ProjectSettingsGeneral-module__label___lIMRup {
          font-family: var(--slash-main);
          font-size: 14px;
          font-weight: 600;
          line-height: 20.02px;
          color: #000000de;
        }

        .ProjectSettingsGeneral-module__value___yTbije {
          font-family: var(--slash-main);
          font-size: 14px;
          font-weight: 400;
          line-height: 20.02px;
          color: #000000de;
        }
      }
    }

    .ProjectSettingsGeneral-module__dualContentItem___Lmij2u {
      display: flex;
      gap: 24px;

      > div {
        flex: 1 1;
      }
    }
  }
}
.HiresweetCreateJobPostingButton-module__button___3UIY0c {
  text-transform: uppercase;
  font-family: var(--slack-main);
  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.46000000834465027px;
  text-align: left;
}

.HiresweetCreateJobPostingButton-module__buttonIcon___umNpFA {
  margin-right: 8px;
}
:root {
  --primary-cobalt-120: #0b1a63;
  --primary-cobalt-100: #1f2e77;
  --primary-cobalt-80: #4c5892;
  --primary-cobalt-60: #7982ad;
  --primary-cobalt-40: #a5abc9;
  --primary-cobalt-20: #d2d5e4;
  --primary-cobalt-10: #e9ebf2;
  --primary-cobalt-05: #f3f4f8;

  --primary-cobalt-op-06: #1f2e770f;

  --secondary-blue-120: #3450b5;
  --secondary-blue-100: #4864c9;
  --secondary-blue-80: #6d83d4;
  --secondary-blue-60: #91a2df;
  --secondary-blue-40: #b6c1e9;
  --secondary-blue-20: #dae0f4;
  --secondary-blue-10: #edf0fa;
  --secondary-blue-05: #f6f7fc;

  --secondary-sky-blue-120: #5c89eb;
  --secondary-sky-blue-100: #709dff;
  --secondary-sky-blue-80: #8db1ff;
  --secondary-sky-blue-60: #a9c4ff;
  --secondary-sky-blue-40: #c6d8ff;
  --secondary-sky-blue-20: #e2ebff;
  --secondary-sky-blue-10: #f1f6ff;
  --secondary-sky-blue-05: #f8faff;

  --secondary-dark-120: #000329;
  --secondary-dark-100: #10173d;
  --secondary-dark-80: #404564;
  --secondary-dark-60: #70748b;
  --secondary-dark-40: #9fa2b1;
  --secondary-dark-20: #cfd1d8;
  --secondary-dark-10: #e8e8ec;
  --secondary-dark-05: #f3f3f5;

  --primary-cobalt: var(--primary-cobalt-100);
  --secondary-blue: var(--secondary-blue-100);
  --secondary-sky-blue: var(--secondary-sky-blue-100);
  --secondary-dark: var(--secondary-dark-100);

  --slash-yellow: #f2cd1f;
  --slash-teal: #1a666e;
  --slash-chalk: #f5f5f5;
  --slash-light-grey: #e8e8ec;
  --slash-grey: #c4c4c4;
  --slash-dark-grey: #757575;
  --slash-black: #212121;
  --slash-shadow: #0000001f;
  --slash-orange: #ee734a;

  --secondary-red: #f66f81;
  --secondary-red-10: #fff1f3;
  --secondary-red-40: #fcc5cc;
  --secondary-red-60: #fba8b2;
  --secondary-red-80: #f98b99;
  --secondary-red-120: #e45a6b;
}

.ApplicantsCell-module__slash___r5Gdq7 {
  display: inline-flex;
  align-items: center;
  gap: 2px;
  color: var(--slash-dark-grey);
  &.ApplicantsCell-module__active___hzRGBq {
    color: var(--slash-teal);
  }
}
:root {
  --primary-cobalt-120: #0b1a63;
  --primary-cobalt-100: #1f2e77;
  --primary-cobalt-80: #4c5892;
  --primary-cobalt-60: #7982ad;
  --primary-cobalt-40: #a5abc9;
  --primary-cobalt-20: #d2d5e4;
  --primary-cobalt-10: #e9ebf2;
  --primary-cobalt-05: #f3f4f8;

  --primary-cobalt-op-06: #1f2e770f;

  --secondary-blue-120: #3450b5;
  --secondary-blue-100: #4864c9;
  --secondary-blue-80: #6d83d4;
  --secondary-blue-60: #91a2df;
  --secondary-blue-40: #b6c1e9;
  --secondary-blue-20: #dae0f4;
  --secondary-blue-10: #edf0fa;
  --secondary-blue-05: #f6f7fc;

  --secondary-sky-blue-120: #5c89eb;
  --secondary-sky-blue-100: #709dff;
  --secondary-sky-blue-80: #8db1ff;
  --secondary-sky-blue-60: #a9c4ff;
  --secondary-sky-blue-40: #c6d8ff;
  --secondary-sky-blue-20: #e2ebff;
  --secondary-sky-blue-10: #f1f6ff;
  --secondary-sky-blue-05: #f8faff;

  --secondary-dark-120: #000329;
  --secondary-dark-100: #10173d;
  --secondary-dark-80: #404564;
  --secondary-dark-60: #70748b;
  --secondary-dark-40: #9fa2b1;
  --secondary-dark-20: #cfd1d8;
  --secondary-dark-10: #e8e8ec;
  --secondary-dark-05: #f3f3f5;

  --primary-cobalt: var(--primary-cobalt-100);
  --secondary-blue: var(--secondary-blue-100);
  --secondary-sky-blue: var(--secondary-sky-blue-100);
  --secondary-dark: var(--secondary-dark-100);

  --slash-yellow: #f2cd1f;
  --slash-teal: #1a666e;
  --slash-chalk: #f5f5f5;
  --slash-light-grey: #e8e8ec;
  --slash-grey: #c4c4c4;
  --slash-dark-grey: #757575;
  --slash-black: #212121;
  --slash-shadow: #0000001f;
  --slash-orange: #ee734a;

  --secondary-red: #f66f81;
  --secondary-red-10: #fff1f3;
  --secondary-red-40: #fcc5cc;
  --secondary-red-60: #fba8b2;
  --secondary-red-80: #f98b99;
  --secondary-red-120: #e45a6b;
}
:root {
  --hiresweet-main: Graphik, sans-serif;
  --hiresweet-accent: Gilroy, sans-serif;
  --slash-main: 'Lexend Exa', sans-serif;
}
.HiresweetJobPostingProjects-module__hat___FNo7RC {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .HiresweetJobPostingProjects-module__actions___FMuash {
    display: flex;
    align-items: center;

    &.HiresweetJobPostingProjects-module__hiresweet___6iUBuy {
      padding: 16px;
      gap: 8px;
    }
    &.HiresweetJobPostingProjects-module__slash___J6sby5 {
      padding: 12px;
      gap: 12px;
    }
  }
}
.HiresweetJobPostingProjects-module__filters___sFfU2A {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  &.HiresweetJobPostingProjects-module__slash___J6sby5 {
    gap: 8px;

    > div,
    > span {
      flex: 1 1;
    }
  }
}
.HiresweetJobPostingProjects-module__displayArchivedProjects___a4F78A {
  display: flex;
  align-items: center;
  gap: 4px;
  flex-grow: 0 !important;
  min-width: fit-content;
  flex-shrink: 1 !important;

  &.HiresweetJobPostingProjects-module__slash___J6sby5 {
    font-family: var(--slash-main);
    font-size: 14px;
    font-weight: 400;
    line-height: 23.24px;
    letter-spacing: 0.4px;
    text-align: left;
    color: var(--slash-black);
  }
}
.HiresweetJobPostingProjects-module__header___GlRC-q {
  .HiresweetJobPostingProjects-module__titleAndNewProjectButton___XZ2-8l {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
    margin-bottom: 30px;
  }
}
.HiresweetJobPostingProjects-module__overview___XPry8M {
  flex: 1;
  margin: 0;
  margin-bottom: 30px;
}
.HiresweetJobPostingProjects-module__centeredCell___Uwmr66 {
  text-align: center;
}
.HiresweetJobPostingProjects-module__container___XMbNRL.HiresweetJobPostingProjects-module__slash___J6sby5 {
  border-radius: 10px;
  padding: 22px;
  box-shadow: 0px 3px 11px 0px #00000014;
  background-color: white;
}
.HiresweetJobPostingProjects-module__selectedProjects___gTGwdh.HiresweetJobPostingProjects-module__slash___J6sby5 {
  font-family: var(--slash-main);
  font-size: 14px;
  font-weight: 400;
  line-height: 23.24px;
  letter-spacing: 0.4px;
  text-align: left;
  color: var(--slash-black);
}
.HiresweetJobPostingProjects-module__unselect___PzmKA7.HiresweetJobPostingProjects-module__slash___J6sby5 {
  font-family: var(--slash-main);
  color: var(--slash-dark-grey);
  font-size: 12px;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
  background: none;
  border: none;

  &:hover {
    color: var(--slash-teal);
  }
}
.BallChain-module__container___-rUDlx {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  width: fit-content;
  z-index: 0;

  .BallChain-module__node___rpYO5I {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-direction: column;
    position: relative;
    z-index: 1;

    .BallChain-module__nodeValue___SfWQdR {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: var(--slash-yellow);
      color: white;
      font-family: var(--slash-main);
      font-size: 12px;
      font-weight: 400;
      line-height: 19.92px;
      letter-spacing: 0.4px;
      text-align: center;
      padding-top: 2px;

      &.BallChain-module__muted___tVaksP {
        background-color: #9e9e9e;
      }
    }

    .BallChain-module__nodeLabel___JG-xwN {
      font-family: var(--slash-main);
      font-size: 8px;
      font-weight: 400;
      line-height: 12.8px;
      letter-spacing: 0.28px;
      text-wrap: nowrap;
    }
  }

  .BallChain-module__backgroundLine___ukwOTK {
    height: 2px;
    background-color: var(--slash-yellow);
    position: absolute;
    top: 12px;
    z-index: 0;
  }
}
.HiresweetJobRequisitionProjects-module__jobRequisitionHeader___84YMLV {
  display: flex;
  margin-bottom: 24px;
  justify-content: space-between;
}

.HiresweetJobRequisitionProjects-module__headerFollowupCell___BFCDMM {
  min-width: 270px;
}

.HiresweetJobRequisitionProjects-module__followupCell___ivnwub {
  min-width: 270px;
}
.HiresweetProjectHeader-module__title___I4XAMn {
  margin-bottom: 24px;

  &.HiresweetProjectHeader-module__slash___BD3kMi {
    font-family: var(--slash-main);
    color: var(--slash-black);
  }

  &.HiresweetProjectHeader-module__hiresweet___t2BJDj {
    font-family: var(--hiresweet-accent);
    color: var(--primary-cobalt);
  }
}
:root {
  --primary-cobalt-120: #0b1a63;
  --primary-cobalt-100: #1f2e77;
  --primary-cobalt-80: #4c5892;
  --primary-cobalt-60: #7982ad;
  --primary-cobalt-40: #a5abc9;
  --primary-cobalt-20: #d2d5e4;
  --primary-cobalt-10: #e9ebf2;
  --primary-cobalt-05: #f3f4f8;

  --primary-cobalt-op-06: #1f2e770f;

  --secondary-blue-120: #3450b5;
  --secondary-blue-100: #4864c9;
  --secondary-blue-80: #6d83d4;
  --secondary-blue-60: #91a2df;
  --secondary-blue-40: #b6c1e9;
  --secondary-blue-20: #dae0f4;
  --secondary-blue-10: #edf0fa;
  --secondary-blue-05: #f6f7fc;

  --secondary-sky-blue-120: #5c89eb;
  --secondary-sky-blue-100: #709dff;
  --secondary-sky-blue-80: #8db1ff;
  --secondary-sky-blue-60: #a9c4ff;
  --secondary-sky-blue-40: #c6d8ff;
  --secondary-sky-blue-20: #e2ebff;
  --secondary-sky-blue-10: #f1f6ff;
  --secondary-sky-blue-05: #f8faff;

  --secondary-dark-120: #000329;
  --secondary-dark-100: #10173d;
  --secondary-dark-80: #404564;
  --secondary-dark-60: #70748b;
  --secondary-dark-40: #9fa2b1;
  --secondary-dark-20: #cfd1d8;
  --secondary-dark-10: #e8e8ec;
  --secondary-dark-05: #f3f3f5;

  --primary-cobalt: var(--primary-cobalt-100);
  --secondary-blue: var(--secondary-blue-100);
  --secondary-sky-blue: var(--secondary-sky-blue-100);
  --secondary-dark: var(--secondary-dark-100);

  --slash-yellow: #f2cd1f;
  --slash-teal: #1a666e;
  --slash-chalk: #f5f5f5;
  --slash-light-grey: #e8e8ec;
  --slash-grey: #c4c4c4;
  --slash-dark-grey: #757575;
  --slash-black: #212121;
  --slash-shadow: #0000001f;
  --slash-orange: #ee734a;

  --secondary-red: #f66f81;
  --secondary-red-10: #fff1f3;
  --secondary-red-40: #fcc5cc;
  --secondary-red-60: #fba8b2;
  --secondary-red-80: #f98b99;
  --secondary-red-120: #e45a6b;
}

.SkipApplicationModal-module__column___SrWCrm {
  display: flex;
  flex-direction: column;
  gap: 9px;
  margin: 28px 0px;

  .SkipApplicationModal-module__reason___urOwhS {
    &.SkipApplicationModal-module__slash___VKSVyY {
      text-align: left;
      height: auto;
      padding: 17px 20px;
      border: 1px solid #0000001f;
      font-family: var(--slash-main);
      font-size: 13px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.46px;
      text-transform: uppercase;
      color: var(--slash-dark-grey);
    }

    &.SkipApplicationModal-module__selected___vFoJYA {
      &.SkipApplicationModal-module__slash___VKSVyY {
        background: #f2cd1f1a !important;
        border: 1px solid var(--slash-yellow) !important;
        color: #000000de;
      }
    }
  }
}
:root {
  --primary-cobalt-120: #0b1a63;
  --primary-cobalt-100: #1f2e77;
  --primary-cobalt-80: #4c5892;
  --primary-cobalt-60: #7982ad;
  --primary-cobalt-40: #a5abc9;
  --primary-cobalt-20: #d2d5e4;
  --primary-cobalt-10: #e9ebf2;
  --primary-cobalt-05: #f3f4f8;

  --primary-cobalt-op-06: #1f2e770f;

  --secondary-blue-120: #3450b5;
  --secondary-blue-100: #4864c9;
  --secondary-blue-80: #6d83d4;
  --secondary-blue-60: #91a2df;
  --secondary-blue-40: #b6c1e9;
  --secondary-blue-20: #dae0f4;
  --secondary-blue-10: #edf0fa;
  --secondary-blue-05: #f6f7fc;

  --secondary-sky-blue-120: #5c89eb;
  --secondary-sky-blue-100: #709dff;
  --secondary-sky-blue-80: #8db1ff;
  --secondary-sky-blue-60: #a9c4ff;
  --secondary-sky-blue-40: #c6d8ff;
  --secondary-sky-blue-20: #e2ebff;
  --secondary-sky-blue-10: #f1f6ff;
  --secondary-sky-blue-05: #f8faff;

  --secondary-dark-120: #000329;
  --secondary-dark-100: #10173d;
  --secondary-dark-80: #404564;
  --secondary-dark-60: #70748b;
  --secondary-dark-40: #9fa2b1;
  --secondary-dark-20: #cfd1d8;
  --secondary-dark-10: #e8e8ec;
  --secondary-dark-05: #f3f3f5;

  --primary-cobalt: var(--primary-cobalt-100);
  --secondary-blue: var(--secondary-blue-100);
  --secondary-sky-blue: var(--secondary-sky-blue-100);
  --secondary-dark: var(--secondary-dark-100);

  --slash-yellow: #f2cd1f;
  --slash-teal: #1a666e;
  --slash-chalk: #f5f5f5;
  --slash-light-grey: #e8e8ec;
  --slash-grey: #c4c4c4;
  --slash-dark-grey: #757575;
  --slash-black: #212121;
  --slash-shadow: #0000001f;
  --slash-orange: #ee734a;

  --secondary-red: #f66f81;
  --secondary-red-10: #fff1f3;
  --secondary-red-40: #fcc5cc;
  --secondary-red-60: #fba8b2;
  --secondary-red-80: #f98b99;
  --secondary-red-120: #e45a6b;
}

.RefusalMailModal-module__description___SnsFOq {
  font-family: var(--slash-main);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left;
}
.ApplicantsActions-module__actions___di1-8i {
  display: inline-flex;
  align-items: center;
  gap: 12px;

  button {
    box-shadow: 0px 2px 6px 0px #00000024;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.46000000834465027px;
    text-align: left;
  }

  .ApplicantsActions-module__reject___Ovfe46 {
    &.ApplicantsActions-module__slash___0WBm8F {
      background-color: var(--slash-orange) !important;
      color: #323232 !important;
      font-family: var(--slash-main);
      font-size: 13px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.46px;
      text-align: left;
      text-transform: uppercase;
    }
  }

  .ApplicantsActions-module__accept___Z-bBHn {
    &.ApplicantsActions-module__slash___0WBm8F {
      background-color: var(--slash-green);
      color: #323232 !important;
      font-family: var(--slash-main);
      font-size: 13px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.46px;
      text-align: left;
      text-transform: uppercase;
    }
  }
}

.ApplicantsActions-module__column___-j1ZXq {
  display: flex;
  flex-direction: column;
  gap: 9px;
  margin: 28px 0px;

  .ApplicantsActions-module__reason___MiIG1Y {
    &.ApplicantsActions-module__slash___0WBm8F {
      text-align: left;
      height: auto;
      padding: 17px 20px;
      border: 1px solid #0000001f;
      font-family: var(--slash-main);
      font-size: 13px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.46px;
      text-transform: uppercase;
      color: var(--slash-dark-grey);
    }

    &.ApplicantsActions-module__selected___JBfOAa {
      &.ApplicantsActions-module__slash___0WBm8F {
        background: #f2cd1f1a !important;
        border: 1px solid var(--slash-yellow) !important;
        color: #000000de;
      }
    }
  }
}

.ApplicantsActions-module__skipDescription___B-kSTp {
  font-family: var(--slash-main);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left;
}
:root {
  --primary-cobalt-120: #0b1a63;
  --primary-cobalt-100: #1f2e77;
  --primary-cobalt-80: #4c5892;
  --primary-cobalt-60: #7982ad;
  --primary-cobalt-40: #a5abc9;
  --primary-cobalt-20: #d2d5e4;
  --primary-cobalt-10: #e9ebf2;
  --primary-cobalt-05: #f3f4f8;

  --primary-cobalt-op-06: #1f2e770f;

  --secondary-blue-120: #3450b5;
  --secondary-blue-100: #4864c9;
  --secondary-blue-80: #6d83d4;
  --secondary-blue-60: #91a2df;
  --secondary-blue-40: #b6c1e9;
  --secondary-blue-20: #dae0f4;
  --secondary-blue-10: #edf0fa;
  --secondary-blue-05: #f6f7fc;

  --secondary-sky-blue-120: #5c89eb;
  --secondary-sky-blue-100: #709dff;
  --secondary-sky-blue-80: #8db1ff;
  --secondary-sky-blue-60: #a9c4ff;
  --secondary-sky-blue-40: #c6d8ff;
  --secondary-sky-blue-20: #e2ebff;
  --secondary-sky-blue-10: #f1f6ff;
  --secondary-sky-blue-05: #f8faff;

  --secondary-dark-120: #000329;
  --secondary-dark-100: #10173d;
  --secondary-dark-80: #404564;
  --secondary-dark-60: #70748b;
  --secondary-dark-40: #9fa2b1;
  --secondary-dark-20: #cfd1d8;
  --secondary-dark-10: #e8e8ec;
  --secondary-dark-05: #f3f3f5;

  --primary-cobalt: var(--primary-cobalt-100);
  --secondary-blue: var(--secondary-blue-100);
  --secondary-sky-blue: var(--secondary-sky-blue-100);
  --secondary-dark: var(--secondary-dark-100);

  --slash-yellow: #f2cd1f;
  --slash-teal: #1a666e;
  --slash-chalk: #f5f5f5;
  --slash-light-grey: #e8e8ec;
  --slash-grey: #c4c4c4;
  --slash-dark-grey: #757575;
  --slash-black: #212121;
  --slash-shadow: #0000001f;
  --slash-orange: #ee734a;

  --secondary-red: #f66f81;
  --secondary-red-10: #fff1f3;
  --secondary-red-40: #fcc5cc;
  --secondary-red-60: #fba8b2;
  --secondary-red-80: #f98b99;
  --secondary-red-120: #e45a6b;
}

.ProfileResumePreview-module__viewPDFButton___1-pE4q {
  display: flex;
  gap: 2px;
  align-items: center;
  font-size: 14px;
  cursor: pointer;

  &.ProfileResumePreview-module__slash___iIJ4t9 {
    font-family: var(--slash-main);
    color: var(--slash-black);
    font-weight: 400;
  }
  &.ProfileResumePreview-module__hiresweet___Lpg8GH {
    font-weight: 500;
    font-family: var(--hiresweet-main);
    color: var(--secondary-blue);
  }
}

.ProfileResumePreview-module__pdfHoverPreview___jJKj5d {
  width: auto;
  padding: 8px;
  transform: scale(0.6) translateX(15%);
}
.ProfileResumeDisplay-module__profileResumeDisplay___3nmBi1 {
  > div {
    width: fit-content;
    border: 1px solid var(--slash-grey);
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 16px;
  }
}
.ProfileCoverLetterDisplay-module__profileCoverLetterDisplay___DbnVvu {
  > div {
    width: fit-content;
    border: 1px solid var(--slash-grey);
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 16px;
  }
}
:root {
  --primary-cobalt-120: #0b1a63;
  --primary-cobalt-100: #1f2e77;
  --primary-cobalt-80: #4c5892;
  --primary-cobalt-60: #7982ad;
  --primary-cobalt-40: #a5abc9;
  --primary-cobalt-20: #d2d5e4;
  --primary-cobalt-10: #e9ebf2;
  --primary-cobalt-05: #f3f4f8;

  --primary-cobalt-op-06: #1f2e770f;

  --secondary-blue-120: #3450b5;
  --secondary-blue-100: #4864c9;
  --secondary-blue-80: #6d83d4;
  --secondary-blue-60: #91a2df;
  --secondary-blue-40: #b6c1e9;
  --secondary-blue-20: #dae0f4;
  --secondary-blue-10: #edf0fa;
  --secondary-blue-05: #f6f7fc;

  --secondary-sky-blue-120: #5c89eb;
  --secondary-sky-blue-100: #709dff;
  --secondary-sky-blue-80: #8db1ff;
  --secondary-sky-blue-60: #a9c4ff;
  --secondary-sky-blue-40: #c6d8ff;
  --secondary-sky-blue-20: #e2ebff;
  --secondary-sky-blue-10: #f1f6ff;
  --secondary-sky-blue-05: #f8faff;

  --secondary-dark-120: #000329;
  --secondary-dark-100: #10173d;
  --secondary-dark-80: #404564;
  --secondary-dark-60: #70748b;
  --secondary-dark-40: #9fa2b1;
  --secondary-dark-20: #cfd1d8;
  --secondary-dark-10: #e8e8ec;
  --secondary-dark-05: #f3f3f5;

  --primary-cobalt: var(--primary-cobalt-100);
  --secondary-blue: var(--secondary-blue-100);
  --secondary-sky-blue: var(--secondary-sky-blue-100);
  --secondary-dark: var(--secondary-dark-100);

  --slash-yellow: #f2cd1f;
  --slash-teal: #1a666e;
  --slash-chalk: #f5f5f5;
  --slash-light-grey: #e8e8ec;
  --slash-grey: #c4c4c4;
  --slash-dark-grey: #757575;
  --slash-black: #212121;
  --slash-shadow: #0000001f;
  --slash-orange: #ee734a;

  --secondary-red: #f66f81;
  --secondary-red-10: #fff1f3;
  --secondary-red-40: #fcc5cc;
  --secondary-red-60: #fba8b2;
  --secondary-red-80: #f98b99;
  --secondary-red-120: #e45a6b;
}

.HiresweetApplicantProfile-module__container___cxLR5r {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 3fr 2fr;
  min-height: 100%;
}

.HiresweetApplicantProfile-module__header___Et-Y7P {
  grid-row: 1;
  grid-column: 1 / span 2;
  padding: 20px;
  &.HiresweetApplicantProfile-module__slash___1p0to9 {
    border-bottom: 1px solid var(--slash-light-grey);
  }
  &.HiresweetApplicantProfile-module__hiresweet___g8UnmF {
    border-bottom: 1px solid var(--secondary-blue-20);
  }
}

.HiresweetApplicantProfile-module__actionsContainer___VjMNVM {
  background: white;
  border: 1px solid var(--slash-light-grey);
  border-radius: 4px;
  padding: 24px;

  p {
    color: var(--slash-black);
    font-family: var(--slash-main);
    font-size: 14px;
    font-weight: 500;
  }

  .HiresweetApplicantProfile-module__actionsButtons___iZqJXi {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.HiresweetApplicantProfile-module__formContainer___NErs29 {
  margin: 16px 0;
  background: white;
  border: 1px solid var(--slash-light-grey);
  border-radius: 4px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.HiresweetApplicantProfile-module__formButton___CKCkbs {
  align-self: flex-end;
}

.HiresweetApplicantProfile-module__resumeContainer___J5-M9z,
.HiresweetApplicantProfile-module__coverLetterContainer___CnLski {
  width: 80%;
  min-width: fit-content;
  text-align: center;
  margin: 0 auto;

  > div {
    width: 100%;
  }
}

.HiresweetApplicantProfile-module__left___p88rWK {
  grid-row: 2;
  grid-column: 1;
  padding: 20px;
}

.HiresweetApplicantProfile-module__right___yDhq7p {
  grid-row: 2;
  grid-column: 2;
  padding: 20px;
  min-width: 600px;
  &.HiresweetApplicantProfile-module__slash___1p0to9 {
    background-color: var(--slash-chalk);
    border-left: 1px solid var(--slash-light-grey);
  }
  &.HiresweetApplicantProfile-module__hiresweet___g8UnmF {
    background-color: var(--secondary-blue-05);
    border-left: 1px solid var(--secondary-blue-20);
  }
}
:root {
  --hiresweet-main: Graphik, sans-serif;
  --hiresweet-accent: Gilroy, sans-serif;
  --slash-main: 'Lexend Exa', sans-serif;
}

.HiresweetProjectApplicantsTable-module__hat___Nf-tsS {
  display: flex;
  align-items: center;
  &.HiresweetProjectApplicantsTable-module__hiresweet___0LLv59 {
    padding: 16px;
    gap: 8px;
  }
  &.HiresweetProjectApplicantsTable-module__slash___-a7hn- {
    padding: 12px;
    gap: 12px;
  }
}

.HiresweetProjectApplicantsTable-module__container___lBwYOx.HiresweetProjectApplicantsTable-module__slash___-a7hn- {
  border-radius: 10px;
  padding: 22px;
  box-shadow: 0px 3px 11px 0px #00000014;
  background-color: white;
}

.HiresweetProjectApplicantsTable-module__selectedCandidates___ZGVdkf.HiresweetProjectApplicantsTable-module__slash___-a7hn- {
  font-family: var(--slash-main);
  font-size: 14px;
  font-weight: 400;
  line-height: 23.24px;
  letter-spacing: 0.4px;
  text-align: left;
  color: #000000de;
}

.HiresweetProjectApplicantsTable-module__profileIdentityContainer___O73dyt {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: flex-start;

  .HiresweetProjectApplicantsTable-module__profileQualification___scn9qU {
    font-family: var(--slash-main);
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    text-align: left;
    color: #00000099;
  }
}

.HiresweetProjectApplicantsTable-module__cvCell___kr-6dx {
  min-width: 75px;
  flex-shrink: 1;
  flex-grow: 0;
}

.HiresweetProjectApplicantsTable-module__phoneCell___P5BM1U {
  min-width: 140px;
  flex-shrink: 1;
  flex-grow: 0;
}

.HiresweetProjectApplicantsTable-module__nameCell___dABTFB {
  min-width: 150px;
  flex-shrink: 1;
  flex-grow: 0;
}

.HiresweetProjectApplicantsTable-module__ownerCell___piKxID {
  min-width: 150px;
  flex-shrink: 1;
  flex-grow: 0;
}
.HiresweetProjectApplicantsTab-module__filters___DW0voo {
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 8px 0 32px;
}

.HiresweetProjectApplicantsTab-module__filter___-uvGCe {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 4px 10px;
  border-radius: 15px;
  cursor: pointer;

  &.HiresweetProjectApplicantsTab-module__slash___Vfb7jj {
    font-family: var(--slash-main);
    background-color: var(--slash-chalk);
    color: var(--slash-dark-grey);
    border: 1px solid var(--slash-dark-grey);

    &:hover {
      color: var(--slash-yellow);
      border-color: var(--slash-yellow);
    }

    &.HiresweetProjectApplicantsTab-module__selected___UcoWFR {
      color: var(--slash-teal);
      border-color: var(--slash-teal);
    }
  }

  &.HiresweetProjectApplicantsTab-module__hiresweet___ShpUxs {
    background-color: var(--slash-chalk);
    color: var(--slash-grey);
  }

  .HiresweetProjectApplicantsTab-module__counter___YQKL-- {
    background-color: white;
    border-radius: 100%;
    padding: 2px 8px;
  }
}
.AddSelectedProfilesToSequenceButton-module__container___Hu0exe {
  padding: 20px;
}

.AddSelectedProfilesToSequenceButton-module__select___6qedZ4 {
  display: block;
  width: 100%;
  margin-bottom: 8px;
}
:root {
  --hiresweet-main: Graphik, sans-serif;
  --hiresweet-accent: Gilroy, sans-serif;
  --slash-main: 'Lexend Exa', sans-serif;
}

.HiresweetProjectFollowup-module__hat___rRBDaU {
  display: flex;
  align-items: center;
  &.HiresweetProjectFollowup-module__hiresweet___ztfyTz {
    padding: 16px;
    gap: 8px;
  }
  &.HiresweetProjectFollowup-module__slash___0ZIwy3 {
    padding: 12px;
    gap: 12px;
  }
}

.HiresweetProjectFollowup-module__container___cNZSDa.HiresweetProjectFollowup-module__slash___0ZIwy3 {
  border-radius: 10px;
  padding: 22px;
  box-shadow: 0px 3px 11px 0px #00000014;
  background-color: white;
}

.HiresweetProjectFollowup-module__selectedProfiles___qBBqrh.HiresweetProjectFollowup-module__slash___0ZIwy3 {
  font-family: var(--slash-main);
  font-size: 14px;
  font-weight: 400;
  line-height: 23.24px;
  letter-spacing: 0.4px;
  text-align: left;
  color: #000000de;
}

.HiresweetProjectFollowup-module__filters___sEXfG3 {
  display: flex;
  gap: 8px;
  align-items: center;
}

.HiresweetProjectFollowup-module__filter___hw8Uqu {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 4px 10px;
  border-radius: 15px;
  cursor: pointer;

  &.HiresweetProjectFollowup-module__slash___0ZIwy3 {
    font-family: var(--slash-main);
    background-color: var(--slash-chalk);
    color: var(--slash-dark-grey);
    border: 1px solid var(--slash-dark-grey);

    &:hover {
      color: var(--slash-yellow);
      border-color: var(--slash-yellow);
    }

    &.HiresweetProjectFollowup-module__selected___5TJDRd {
      color: var(--slash-teal);
      border-color: var(--slash-teal);
    }
  }

  &.HiresweetProjectFollowup-module__hiresweet___ztfyTz {
    background-color: var(--slash-chalk);
    color: var(--slash-grey);
  }

  .HiresweetProjectFollowup-module__counter___G-hev6 {
    background-color: white;
    border-radius: 100%;
    padding: 2px 8px;
  }
}
.ProjectSettings-module__container___lYNCvc {
  display: flex;
  gap: 36px;
  margin-top: 10px;
}

.ProjectSettings-module__content___i1T7s- {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 1px 3px 0px #00000012;
  box-shadow: 0px 2px 12px -1px #0000001F;
  flex-grow: 1;
  padding: 34px;
}.HiresweetProjectSearch-module__hat___pPJcJ7 {
  display: flex;
  align-items: center;
  &.HiresweetProjectSearch-module__hiresweet___-czQ-q {
    padding: 16px;
    gap: 8px;
  }
  &.HiresweetProjectSearch-module__slash___sOUop8 {
    padding: 12px;
    gap: 12px;
  }
}

.HiresweetProjectSearch-module__container___5t8mLS.HiresweetProjectSearch-module__slash___sOUop8 {
  border-radius: 10px;
  padding: 22px;
  box-shadow: 0px 3px 11px 0px #00000014;
  background-color: white;
}
.HiresweetProjects-module__centeredCell___becSBA {
  text-align: center;
}

.HiresweetProjects-module__container___DxFF4s.HiresweetProjects-module__slash___77T3dw {
  border-radius: 10px;
  padding: 22px;
  box-shadow: 0px 3px 11px 0px #00000014;
  background-color: white;
}
.ProfileActivePlacements-module__listHeaderActionsContainer___dIDnsZ {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.ProfileActivePlacements-module__list___0qtYwp {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.ProfileActivePlacements-module__listHeader___ewuMYt,
.ProfileActivePlacements-module__listRow___Gk7PRW {
  display: flex;
  gap: 25px;
  align-items: center;
  padding: 10px 16px 10px 16px;
  cursor: pointer;
}

.ProfileActivePlacements-module__listHeader___ewuMYt.ProfileActivePlacements-module__hiresweet___rBxHZX {
  padding: 10px 0px 10px 16px;
}

.ProfileActivePlacements-module__listHeader___ewuMYt {
  .ProfileActivePlacements-module__mainCell___DRPrQH {
    &.ProfileActivePlacements-module__slash___RB-Ulm {
      font-family: var(--slash-main);
      font-size: 14px;
      font-weight: 400;
      line-height: 23.24px;
      letter-spacing: 0.4px;
      text-align: left;
      color: #000000de;
    }

    &.ProfileActivePlacements-module__hiresweet___rBxHZX {
      font-family: var(--hiresweet-main);
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      color: var(--primary-cobalt);
    }
  }
}

.ProfileActivePlacements-module__listRow___Gk7PRW {
  border-radius: 4px;

  &.ProfileActivePlacements-module__slash___RB-Ulm {
    border: 1px solid #0000001f;
  }

  &.ProfileActivePlacements-module__hiresweet___rBxHZX {
    border: 1px solid var(--secondary-blue-20);
  }
}

.ProfileActivePlacements-module__mainCell___DRPrQH {
  flex-grow: 1;

  .ProfileActivePlacements-module__label___Nz2g68 {
    &.ProfileActivePlacements-module__slash___RB-Ulm {
      font-family: var(--slash-main);
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: black;
      margin-right: 8px;
    }

    &.ProfileActivePlacements-module__hiresweet___rBxHZX {
      font-family: var(--hiresweet-main);
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: var(--primary-cobalt);
      margin-right: 8px;
    }
  }

  .ProfileActivePlacements-module__description___GxJG5M {
    &.ProfileActivePlacements-module__slash___RB-Ulm {
      font-family: var(--slash-main);
      font-size: 14px;
      font-weight: 400;
      line-height: 20.02px;
      text-align: left;
      color: #00000099;
    }

    &.ProfileActivePlacements-module__hiresweet___rBxHZX {
      font-family: var(--hiresweet-main);
      font-size: 13px;
      font-weight: 400;
      line-height: 20.02px;
      text-align: left;
      color: var(--primary-cobalt-60);
    }
  }
}

.ProfileActivePlacements-module__selectedProfilesContainer___KheLA- {
  margin-top: 24px;
  min-height: 300px;

  .ProfileActivePlacements-module__selectedProfiles___M3-Qsr {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .ProfileActivePlacements-module__title___TqWMZP {
    display: block;
    margin-bottom: 12px;
    &.ProfileActivePlacements-module__hiresweet___rBxHZX {
      font-family: var(--hiresweet-main);
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: var(--primary-cobalt);
    }

    &.ProfileActivePlacements-module__slash___RB-Ulm {
      font-family: var(--slash-main);
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: var(--slash-black);
    }
  }

  .ProfileActivePlacements-module__selectedProfile___ev-vmJ {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }
}

.ProfileActivePlacements-module__removeButton___ioeXrP {
  border-radius: 100% !important;
  color: white !important;
  padding: 4px;
  width: 24px;
  height: 24px;
  aspect-ratio: 1;
  font-size: 16px;
}

.ProfileActivePlacements-module__table___-CpHXm.ProfileActivePlacements-module__hiresweet___rBxHZX {
  border: none;
}

.ProfileActivePlacements-module__modal___-czDRv {
  z-index: 10;
}
.ProfileSendActivePlacementModal-module__modal___1pOopD {
  z-index: 10;
}

.ProfileSendActivePlacementModal-module__activePlacementModalContainer___zJmLpJ {
  display: grid;
  gap: 32px;
  grid-template-columns: max(calc(50% - 16px)) auto;

  .ProfileSendActivePlacementModal-module__documentSelectionTitle___Zs3bwK {
    &.ProfileSendActivePlacementModal-module__hiresweet___vB9wCe {
      font-family: var(--hiresweet-main);
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: var(--primary-cobalt);
    }
  }

  .ProfileSendActivePlacementModal-module__documentSelection___XaDD5G {
    max-height: 500px;
    overflow-y: auto;
  }

  .ProfileSendActivePlacementModal-module__documentSelectionContainer___Y9SQ1x {
    display: flex;
    flex-direction: column;
    flex: 1 1;
  }

  .ProfileSendActivePlacementModal-module__placementContentContainer___2ydoJj {
    display: flex;
    flex-direction: column;
    flex: 1 1;

    .ProfileSendActivePlacementModal-module__placementContentTitle___mjneta {
      h4 {
        margin-top: 0px;
      }
    }

    .ProfileSendActivePlacementModal-module__placementContent___H-BBxA {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .ProfileSendActivePlacementModal-module__subjectInput___kB-F-7 {
        box-sizing: border-box;
      }

      .ProfileSendActivePlacementModal-module__messageInput___57sgRu {
        width: 100%;
        resize: vertical;
        box-sizing: border-box;
        padding: 12px;

        &.ProfileSendActivePlacementModal-module__hiresweet___vB9wCe {
          border: 1px solid var(--secondary-blue-20);
          border-radius: 8px;
          box-shadow: 0px 2px 4px 0px var(--primary-cobalt-op-06);
        }
      }

      .ProfileSendActivePlacementModal-module__label___SOB55K {
        &.ProfileSendActivePlacementModal-module__slash___5mEjKX {
          display: block;
          font-family: var(--slash-main);
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          color: black;
          margin-bottom: 8px;
        }

        &.ProfileSendActivePlacementModal-module__hiresweet___vB9wCe {
          display: block;
          font-family: var(--hiresweet-main);
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          color: var(--primary-cobalt);
          margin-bottom: 8px;
        }
      }
    }
  }
}

.ProfileSendActivePlacementModal-module__tooltipContent___T8CCpm {
  background: white;
  box-shadow: 0 2px 4px var(--slash-shadow);
  padding: 12px;
  font-size: 12px;
  border-radius: 4px;
  width: 250px;
}

.ProfileSendActivePlacementModal-module__disabledStyle___vTUM-O {
  filter: grayscale(1) opacity(0.2);
  cursor: auto !important;
}
.CheckboxList-module__listContainer___qvbKeA {
  margin-top: 10px;

  .CheckboxList-module__listHeader___5HBUI4 {
    border: 1px solid transparent;

    .CheckboxList-module__mainCell___sJifao {
      &.CheckboxList-module__slash___RrBzUu {
        font-family: var(--slash-main);
        font-size: 14px;
        font-weight: 400;
        line-height: 23.24px;
        letter-spacing: 0.4px;
        text-align: left;
        color: #000000de;
      }

      &.CheckboxList-module__hiresweet___KlSy9w {
        font-family: var(--hiresweet-main);
        font-size: 14px;
        font-weight: 500;
        line-height: 23.24px;
        letter-spacing: 0.4px;
        text-align: left;
        color: var(--primary-cobalt);
      }
    }
  }

  .CheckboxList-module__list___s0Dx43 {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .CheckboxList-module__listHeader___5HBUI4,
  .CheckboxList-module__listRow___tcN8ks {
    display: flex;
    gap: 25px;
    align-items: center;
    padding: 10px 16px 10px 16px;
  }

  .CheckboxList-module__togglableRow___jC5Qu0 {
    cursor: pointer;
  }

  .CheckboxList-module__listRow___tcN8ks {
    border-radius: 4px;
    border: 1px solid #0000001f;
  }

  .CheckboxList-module__rowActions___emR73P {
    display: flex;
    align-items: center;
    gap: 11px;
  }
}

.CheckboxList-module__mainCell___sJifao {
  flex-grow: 1;
  white-space: nowrap;
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;

  .CheckboxList-module__label___hJMwHu {
    &.CheckboxList-module__slash___RrBzUu {
      font-family: var(--slash-main);
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: black;
      margin-right: 8px;
    }

    &.CheckboxList-module__hiresweet___KlSy9w {
      font-family: var(--hiresweet-main);
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: var(--primary-cobalt);
      margin-right: 8px;
    }
  }

  .CheckboxList-module__description___TS-uYJ {
    &.CheckboxList-module__slash___RrBzUu {
      font-family: var(--slash-main);
      font-size: 14px;
      font-weight: 400;
      line-height: 20.02px;
      text-align: left;
      color: #00000099;
    }

    &.CheckboxList-module__hiresweet___KlSy9w {
      font-family: var(--hiresweet-main);
      font-size: 14px;
      font-weight: 400;
      line-height: 20.02px;
      text-align: left;
      color: var(--primary-cobalt-op-06);
    }
  }
}
.TargetsForm-module__label___vnGdQm {
  &.TargetsForm-module__hiresweet___xGZple {
    font-family: var(--hiresweet-main);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--primary-cobalt);
    margin-bottom: 8px;
  }

  &.TargetsForm-module__slash___GvcHsG {
    font-family: var(--slash-main);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--slash-black);
    margin-bottom: 8px;
  }
}
.ProfileTasksAndCommentsHeader-module__container___bDqpYH {
  display: flex;
  align-items: center;
  gap: 20px;

  > div {
    min-width: 0px;
    flex: 1 1;
  }
}
.ProfileTaskElement-module__taskElement___ERCY13 {
  background-color: white;
  padding: 6px 0;
  gap: 8px;
  display: flex;
  align-items: center;

  .ProfileTaskElement-module__taskContent___AbltUM {
    flex-grow: 1;

    &.ProfileTaskElement-module__slash___gg23KZ {
      font-family: var(--slash-main);
      color: var(--slash-black);

      .ProfileTaskElement-module__taskAuthorAndDate___wC84Bf {
        color: #aeaeae;
      }
    }
    &.ProfileTaskElement-module__hiresweet___MXo9hr {
      font-family: var(--hiresweet-main);
      color: var(--primary-cobalt);

      .ProfileTaskElement-module__taskAuthorAndDate___wC84Bf {
        color: var(--primary-cobalt-60);
      }
    }

    .ProfileTaskElement-module__taskText___UZGyLN {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: black;
    }
  }
  .ProfileTaskElement-module__taskAuthorAndDate___wC84Bf {
    font-size: 13px;
    font-weight: 400;
    line-height: 18.5px;
    text-align: left;
  }

  .ProfileTaskElement-module__taskIcon___8EHeBD {
    width: 20px;
    height: 20px;
  }
}
:root {
  --primary-cobalt-120: #0b1a63;
  --primary-cobalt-100: #1f2e77;
  --primary-cobalt-80: #4c5892;
  --primary-cobalt-60: #7982ad;
  --primary-cobalt-40: #a5abc9;
  --primary-cobalt-20: #d2d5e4;
  --primary-cobalt-10: #e9ebf2;
  --primary-cobalt-05: #f3f4f8;

  --primary-cobalt-op-06: #1f2e770f;

  --secondary-blue-120: #3450b5;
  --secondary-blue-100: #4864c9;
  --secondary-blue-80: #6d83d4;
  --secondary-blue-60: #91a2df;
  --secondary-blue-40: #b6c1e9;
  --secondary-blue-20: #dae0f4;
  --secondary-blue-10: #edf0fa;
  --secondary-blue-05: #f6f7fc;

  --secondary-sky-blue-120: #5c89eb;
  --secondary-sky-blue-100: #709dff;
  --secondary-sky-blue-80: #8db1ff;
  --secondary-sky-blue-60: #a9c4ff;
  --secondary-sky-blue-40: #c6d8ff;
  --secondary-sky-blue-20: #e2ebff;
  --secondary-sky-blue-10: #f1f6ff;
  --secondary-sky-blue-05: #f8faff;

  --secondary-dark-120: #000329;
  --secondary-dark-100: #10173d;
  --secondary-dark-80: #404564;
  --secondary-dark-60: #70748b;
  --secondary-dark-40: #9fa2b1;
  --secondary-dark-20: #cfd1d8;
  --secondary-dark-10: #e8e8ec;
  --secondary-dark-05: #f3f3f5;

  --primary-cobalt: var(--primary-cobalt-100);
  --secondary-blue: var(--secondary-blue-100);
  --secondary-sky-blue: var(--secondary-sky-blue-100);
  --secondary-dark: var(--secondary-dark-100);

  --slash-yellow: #f2cd1f;
  --slash-teal: #1a666e;
  --slash-chalk: #f5f5f5;
  --slash-light-grey: #e8e8ec;
  --slash-grey: #c4c4c4;
  --slash-dark-grey: #757575;
  --slash-black: #212121;
  --slash-shadow: #0000001f;
  --slash-orange: #ee734a;

  --secondary-red: #f66f81;
  --secondary-red-10: #fff1f3;
  --secondary-red-40: #fcc5cc;
  --secondary-red-60: #fba8b2;
  --secondary-red-80: #f98b99;
  --secondary-red-120: #e45a6b;
}
:root {
  --hiresweet-main: Graphik, sans-serif;
  --hiresweet-accent: Gilroy, sans-serif;
  --slash-main: 'Lexend Exa', sans-serif;
}
.ProfileTasksSummary-module__count___XyLx2U {
  height: 20px;
  padding: 0px 6px;
  font-size: 12px;
  line-height: 20px;
  border-radius: 20px;
  font-weight: 500;

  &.ProfileTasksSummary-module__slash___0TER5A {
    font-family: var(--slash-main);
    background: var(--slash-yellow);
    color: var(--slash-black);
  }
  &.ProfileTasksSummary-module__hiresweet___M2CGvG {
    font-family: var(--hiresweet-main);
    background: var(--secondary-sky-blue-20);
    color: var(--secondary-blue);
  }
}
.ProfileTasksSummary-module__title___yZFCkp {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  &.ProfileTasksSummary-module__slash___0TER5A {
    font-family: var(--slash-main);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    text-transform: uppercase;
  }

  &.ProfileTasksSummary-module__hiresweet___M2CGvG {
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-cobalt);
    font-family: var(--hiresweet-main);
  }

  .ProfileTasksSummary-module__left___s5T1WQ {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
.ProfileTasksSummary-module__sidePanel___ORRX-w {
  padding: 32px;
}
.ProfileTasksSummary-module__separator___HPuPeJ {
  width: 100%;
  height: 1px;
  margin: 8px 0px 10px;
  background: #0000001f;
}
.ProfileTasksSummary-module__seeMore___ciIiA9 {
  text-decoration: underline;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  &.ProfileTasksSummary-module__slash___0TER5A {
    font-family: var(--slash-main);
    color: var(--slash-black);
    text-transform: uppercase;
  }
  &.ProfileTasksSummary-module__hiresweet___M2CGvG {
    font-family: var(--hiresweet-main);
    color: var(--primary-cobalt);
  }
}
.ProfileTasksSummary-module__currentSequenceContainer___HmCm15 {
  background: #f5fafd;
  padding: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  gap: 18px;

  &:has(.ProfileTasksSummary-module__sequenceName___lQGTzr:empty) {
    display: none;
  }

  .ProfileTasksSummary-module__sequenceName___lQGTzr {
    font-family: var(--slash-main);
    font-size: 12px;
    font-weight: 500;
    line-height: 17.16px;
    text-align: left;
    color: #757575;
    flex-shrink: 0;
  }

  .ProfileTasksSummary-module__sequencePreview___n-iG0E {
    overflow-x: auto;
    padding-top: 3px;
    z-index: 0;
    padding-right: 2px;
  }
}
.ProfileExternalUploadModal-module__modal___eZvI9j {
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.ProfileCreationModal-module__form___bA8GP5 {
  display: flex;
  flex-direction: column;
  gap: 30px;

  .ProfileCreationModal-module__dualField___C-BC1J {
    display: flex;
    gap: 30px;
  }
}

.ProfileCreationModal-module__loader___ihjQ7N {
  position: absolute;
  height: calc(100% - 152px);
  width: calc(100% - 72px);
  background: rgba(255, 255, 255, 0.7);
  z-index: 10;

  center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.ProfileFullHeader-module__container___spjMn4 {
  display: flex;
  align-items: center;
  gap: 20px;

  > div {
    flex: 1 1;
  }
}
.ProjectDocuments-module__container___k6msM1 {
  .ProjectDocuments-module__title___wq4e2z {
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  .ProjectDocuments-module__caret___xqRd4p {
    width: 51px;
    height: 5px;
    background: #f2cd1f;
  }

  .ProjectDocuments-module__documentsContainer___WyUyU1 {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 25px;

    .ProjectDocuments-module__documentRow___2LqQUD {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 68px;
      padding: 16px 22px;
      border: 1px solid #0000001f;
      background: white;

      &.ProjectDocuments-module__slash___mBnND5 {
        border-radius: 4px;
      }

      &.ProjectDocuments-module__hiresweet___NQLsfc {
        border-radius: 8px;
      }

      .ProjectDocuments-module__documentInfos___4fknu- {
        display: flex;
        align-items: center;
        gap: 8px;

        .ProjectDocuments-module__documentIcon___fU9TxT {
          margin-right: 13px;
        }

        .ProjectDocuments-module__documentTitle___uOlLvI {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;

          &.ProjectDocuments-module__slash___mBnND5 {
            font-family: var(--slash-main);
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
            color: black;
          }

          &.ProjectDocuments-module__hiresweet___NQLsfc {
            font-family: var(--hiresweet-main);
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
            color: var(--primary-cobalt);
          }
        }

        .ProjectDocuments-module__documentDate___LHPaGY {
          font-size: 14px;
          font-weight: 400;
          line-height: 20.02px;
          text-align: left;

          &.ProjectDocuments-module__slash___mBnND5 {
            font-family: var(--slash-main);
            color: black;
          }

          &.ProjectDocuments-module__hiresweet___NQLsfc {
            font-family: var(--hiresweet-main);
            color: var(--primary-cobalt);
          }
        }
      }

      .ProjectDocuments-module__documentActions___NuYHjz {
        display: flex;
        align-items: center;
        gap: 28px;

        .ProjectDocuments-module__actionsRight___NukorK {
          display: flex;
          align-items: center;
          gap: 21px;
        }

        .ProjectDocuments-module__documentDownload___ZBFcGe {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 7px 12px;
          font-size: 13px;
          font-weight: 500;
          line-height: 22px;
          text-align: left;
          cursor: pointer;

          &.ProjectDocuments-module__slash___mBnND5 {
            border-radius: 4px;
            border: 1px solid #00000099;
          }

          &.ProjectDocuments-module__hiresweet___NQLsfc {
            border-radius: 8px;
            color: var(--secondary-blue);
            border: 1px solid var(--secondary-blue);
          }
        }
      }
    }
  }
}

.ProjectDocuments-module__documentShow___Z9Jc73,
.ProjectDocuments-module__moreActions___HQ0JG0 {
  cursor: pointer;
}

.ProjectDocuments-module__popupContent___t6X4Xw {
  display: flex;
  flex-direction: column;
  gap: 6px;
  overflow: hidden;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 3px 11px 0px #00000014;

  .ProjectDocuments-module__popupItem___Z8QGPP {
    padding: 12px 16px;
    cursor: pointer;
    font-family: var(--slash-main);
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    color: black;

    &:hover {
      background-color: var(--slash-light-grey);
    }
  }
}

.ProjectDocuments-module__pageSelector___Sy9cc3 {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 16px;

  .ProjectDocuments-module__pageNumber___bWKkUU {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: var(--slash-dark-grey);
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    text-align: center;
    color: white;
  }

  .ProjectDocuments-module__currentPage___ulOQS1 {
    background: var(--slash-yellow);
  }
}

.ProjectDocuments-module__addResume___ocVCVy {
  display: flex;
  align-items: center;
  padding: 18px 20px 18px 20px;
  gap: 30px;
  border-radius: 10px;
  background: rgb(229, 243, 250);

  .ProjectDocuments-module__addResumeTitle___fG07-O {
    padding: 8px 0px;
    flex-grow: 1;
    font-family: var(--slash-main);
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.15px;
  }
}

.ProjectDocuments-module__fileInputModalContent___0GiRId {
  padding: 12px 0px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
